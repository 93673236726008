import { useState, useEffect } from 'react'
import axios from 'axios'
import jsPdf from 'jspdf'
// styles
import Button from 'react-bootstrap/Button'
import SAArmyLogo from '../assets/images/SA_Army.png'
import ShieldLogo from '../assets/images/shield_life.png';
import maxiforce from '../assets/images/Maxi Forces Correct.png';

const Document1 = ({
  agentCode,
  forceNumber,
  armsOfService,
  rankTitle,
  fullNames,
  surname,
  IDNumber,
  mainID,
  cellNumber,
  altCellNumber,
  landlineNumber,
  emailAddress,
  streetNumber,
  streetName,
  complexName,
  unitNumber,
  suburb,
  town,
  postalCode,
  unitName,
  unitSwitchBoardNumber,
  maritalStatus,
  monthlyPremium,
  spouseTitle,
  spouseFullNames,
  spouseSurname,
  spouseID,
  spouseIdFile,
  childrenAmount,
  childTitle,
  childFullNames,
  childSurname,
  childID,
  childTitle2,
  childFullNames2,
  childSurname2,
  childID2,
  childTitle3,
  childFullNames3,
  childSurname3,
  childID3,
  childTitle4,
  childFullNames4,
  childSurname4,
  childID4,
  childTitle5,
  childFullNames5,
  childSurname5,
  childID5,
  childTitle6,
  childFullNames6,
  childSurname6,
  childID6,
  childrenIds,
  pbTitle,
  pbFullNames,
  pbSurname,
  pbID,
  pbCellNumber,
  pbIdData,
  setPbIdData,
  nlTitle,
  nlFullNames,
  nlSurname,
  nlIDNumber,
  nlCellNumber,
  nlIdData,
  setNlIdData,
  paymentMethod,
  signature,
  base64Data,
  setBase64Data,
  bankingDetailsDO,
  debitOrderDate,
  idParams,
  dpip,
  dpipDetails,
  fppo,
  fppoDetails,
  pip,
  pipDetails,
  closeDpipFppo,
  closeDpipFppoDetails,
  pbLandline,
  pbRelationship,
  nlLandline,
  nlRelationship,
  sourceIncome,
  sourceIncomeDetails,
  mainAge,
  spouseAge,
  childAge,
  childAge2,
  childAge3,
  childAge4,
  childAge5,
  childAge6,
  pbAge,
  nlAge,
  spouseCell,
  agsb,
  agsbwide,
  rhcb,
  rhcbwide,
  cgsb,
  cgsbwide,
  asb,
  asbwide,
  other,
  otherwide,
  otherDetails,
  otherDetailswide,
  prevCancelation,
  prevWaiting,
  marketingConsent,
  memberRelation,
  relationship,
  name,
  relationSurname,
  title,
  dateOfBirth,
  age,
  benefit,
  premium,
  memberRelation2,
  relationship2,
  name2,
  relationSurname2,
  title2,
  dateOfBirth2,
  age2,
  benefit2,
  premium2,
  memberRelation3,
  relationship3,
  name3,
  relationSurname3,
  title3,
  dateOfBirth3,
  age3,
  benefit3,
  premium3,
  memberRelation4,
  relationship4,
  name4,
  relationSurname4,
  title4,
  dateOfBirth4,
  age4,
  benefit4,
  premium4,
  memberRelation5,
  relationship5,
  name5,
  relationSurname5,
  title5,
  dateOfBirth5,
  age5,
  benefit5,
  premium5,
  memberRelation6,
  relationship6,
  name6,
  relationSurname6,
  title6,
  dateOfBirth6,
  age6,
  benefit6,
  premium6,
  memberRelation7,
  relationship7,
  name7,
  relationSurname7,
  title7,
  dateOfBirth7,
  age7,
  benefit7,
  premium7,
  memberRelation8,
  relationship8,
  name8,
  relationSurname8,
  title8,
  dateOfBirth8,
  age8,
  benefit8,
  premium8,
  extendedMonthlyPremium,
  extendedPaymentMethod,
  otherExtendedText,
  extendedBankingDetailsDO,
  confirmsignYes
}) => {
  const date = Date.now()
  const d = new Date(parseInt(date, 10))
  const timeStamp = d.toString('MM/dd/yy HH:mm:ss')
  const [memberType, setMemberType] = useState('Single')
  const [approved, setApproved] = useState(false)
  const [data, setData] = useState([])

  const bankName = paymentMethod === 'DebitOrder' ? bankingDetailsDO[0].Bank : ''
  const accountType = paymentMethod === 'DebitOrder' ? bankingDetailsDO[1]['Account Type'] : ''
  const accountNo = paymentMethod === 'DebitOrder' ? bankingDetailsDO[2]['Account Number'] : ''
  const branchCode = paymentMethod === 'DebitOrder' ? bankingDetailsDO[3]['Branch Code'] : ''

  const bankNameWider = extendedPaymentMethod === 'DebitOrder' ? extendedBankingDetailsDO[0].Bank : ''
  const accountTypeWider = extendedPaymentMethod === 'DebitOrder' ? extendedBankingDetailsDO[1]['Account Type'] : ''
  const accountNoWider = extendedPaymentMethod === 'DebitOrder' ? extendedBankingDetailsDO[2]['Account Number'] : ''
  const branchCodeWider = extendedPaymentMethod === 'DebitOrder' ? extendedBankingDetailsDO[3]['Branch Code'] : ''
  const debitOrderDates = extendedPaymentMethod === 'DebitOrder' ? extendedBankingDetailsDO[4]['Debit Order Date'] : ''

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const now = new Date();
  let monthNo = now.getMonth() + 1;
  let yearNow = now.getFullYear();
  let day = now.getDate();

  const extendedmemberArray = [
    name && { "memberRelation": memberRelation, "relationship": relationship, "dateOfBirth": dateOfBirth, "age": age, "benefit": benefit, "premium": premium, "title": title, "name": name, "relationSurname": relationSurname },
    name2 && { "memberRelation": memberRelation2, "relationship": relationship2, "dateOfBirth": dateOfBirth2, "age": age2, "benefit": benefit2, "premium": premium2, "title": title2, "name": name2, "relationSurname": relationSurname2 },
    name3 && { "memberRelation": memberRelation3, "relationship": relationship3, "dateOfBirth": dateOfBirth3, "age": age3, "benefit": benefit3, "premium": premium3, "title": title3, "name": name3, "relationSurname": relationSurname3 },
    name4 && { "memberRelation": memberRelation4, "relationship": relationship4, "dateOfBirth": dateOfBirth4, "age": age4, "benefit": benefit4, "premium": premium4, "title": title4, "name": name4, "relationSurname": relationSurname4 },
    name5 && { "memberRelation": memberRelation5, "relationship": relationship5, "dateOfBirth": dateOfBirth5, "age": age5, "benefit": benefit5, "premium": premium5, "title": title5, "name": name5, "relationSurname": relationSurname5 },
    name6 && { "memberRelation": memberRelation6, "relationship": relationship6, "dateOfBirth": dateOfBirth6, "age": age6, "benefit": benefit6, "premium": premium6, "title": title6, "name": name6, "relationSurname": relationSurname6 },
    name7 && { "memberRelation": memberRelation7, "relationship": relationship7, "dateOfBirth": dateOfBirth7, "age": age7, "benefit": benefit7, "premium": premium7, "title": title7, "name": name7, "relationSurname": relationSurname7 },
    name8 && { "memberRelation": memberRelation8, "relationship": relationship8, "dateOfBirth": dateOfBirth8, "age": age8, "benefit": benefit8, "premium": premium8, "title": title8, "name": name8, "relationSurname": relationSurname8 },
  ];

  const childrenArray = [
    childFullNames && { "childFullNames": childFullNames, "childSurname": childSurname, "childID": childID, "childAge": childAge },
    childFullNames2 && { "childFullNames": childFullNames2, "childSurname": childSurname2, "childID": childID2, "childAge": childAge2 },
    childFullNames3 && { "childFullNames": childFullNames3, "childSurname": childSurname3, "childID": childID3, "childAge": childAge3 },
    childFullNames4 && { "childFullNames": childFullNames4, "childSurname": childSurname4, "childID": childID4, "childAge": childAge4 },
    childFullNames5 && { "childFullNames": childFullNames5, "childSurname": childSurname5, "childID": childID5, "childAge": childAge5 },
    childFullNames6 && { "childFullNames": childFullNames6, "childSurname": childSurname6, "childID": childID6, "childAge": childAge6 },
  ];

  // type here can either be download or post
  const pdfPayrollDeductionScript = async (type) => {

    let extendedFinalArray = extendedmemberArray.filter(function (e) { return e; });
    let finalChildArray = childrenArray.filter(function (e) { return e; });
    let oldChild = finalChildArray.filter(function (e) { return (e.childAge.toLowerCase().indexOf('month') === -1) && parseFloat(e.childAge) >= parseFloat(11) && parseFloat(e.childAge) <= parseFloat(21) })
    let middleChild = finalChildArray.filter(function (e) { return (e.childAge.toLowerCase().indexOf('month') === -1) && parseFloat(e.childAge) >= parseFloat(1) && parseFloat(e.childAge) <= parseFloat(10) })
    let newBorn = finalChildArray.filter(function (e) { return (e.childAge.toLowerCase().indexOf('month') !== -1) || parseFloat(e.childAge) < parseFloat(1) })

    var doc = new jsPdf({ compress: true })
    // *******************************************Payroll Deduction Member Application Form
    doc.setFont('Helvetica', 'bold')
    doc.setTextColor('#444')
    doc.setFontSize(16)
    let textY = 5;
    doc.addImage(ShieldLogo, "PNG", 15, textY + 13, 23, 23, "logo", "MEDIUM");
    doc.addImage(SAArmyLogo, "PNG", 100, textY, 20, 20, "saarmy", "MEDIUM");
    doc.addImage(maxiforce, "PNG", 170, textY + 15, 25, 20, "maxi", "MEDIUM");
    doc.text(agentCode, 145, textY + 15)
    doc.text('SA Army Foundation', 80, textY + 27)
    doc.text('Member Application', 81, textY + 35)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.setTextColor('red')
    doc.text('Please Note:', 15, textY + 40)
    doc.setTextColor('#444')
    doc.text('In terms of the Financial Intelligence Centre Amendment Act 1 of 2017 and to enhance the on-boarding and claims', 15, textY + 45)
    doc.text('processes, we will require a copy of the following documentation to accompany this application:', 15, textY + 50)
    doc.text('1. Identification of the person apply (ID book/card)', 15, textY + 55)
    doc.text('2. Identification of the nominated beneficiary , if available (ID book/card)', 15, textY + 60)
    doc.setFont('Helvetica', 'italic')
    doc.text('if applicable;', 15, textY + 65)
    doc.setFont('Helvetica', 'normal')
    doc.text('3. Identification of each spouse covered under this application (ID book/card)', 15, textY + 70)
    doc.text('4. Marriage Certificate or equivalent', 15, textY + 75)
    doc.text('5. Identification of each dependent child covered under this application (ID book/card or Birth Certificate)', 15, textY + 80)
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('1. Legislation information and requirements:', 15, textY + 90)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('This policy is governed by South African Law and any legal action in relation to this policy is subject to the', 15, textY + 95)
    doc.text('jurisdiction of the South African Courts.', 15, textY + 100)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textY + 105, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Personal Information', 85, textY + 108.5)
    doc.rect(15, textY + 110, 166, 53, 'S')
    doc.setFont('Helvetica', 'normal')
    doc.text('In South Africa, the Protection of Personal Information Act 4 of 2013 (PoPIA) has been enacted to regulate the', 17, textY + 115)
    doc.text('processing of Personal Information. When entering into this policy you provide us with information that may be', 17, textY + 120)
    doc.text('protected by legislation including but not limited to PoPIA.  We will take all reasonable steps to protect the', 17, textY + 125)
    doc.text('information you provide us securely and confidentially.', 17, textY + 130)
    doc.text('I hereby confirm that my information and all information supplied by me may be processed for the following reasons.', 17, textY + 135)
    doc.text('I hereby confirm that I have the legal right to supply the necessary information in this application and the legal', 17, textY + 140)
    doc.text('right to give consent to process the personal / sensitive  / special information for the following reasons:', 17, textY + 145)
    doc.text('* Army Foundation membership', 17, textY + 150)
    doc.text('* All Insurance cover being applied for', 17, textY + 155)
    doc.text('* Any processing required for the Insurance product being applied for', 17, textY + 160)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textY + 165, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Sharing of Insurance Information', 75, textY + 168.5)
    doc.rect(15, textY + 170, 166, 100, 'S')
    doc.setFont('Helvetica', 'normal')
    doc.text('The South African Regulatory bodies require insurers to share information with the regulatory body regarding', 17, textY + 175)
    doc.text('policies and claims. In addition, insurers share information with each other regarding policies and claims with the', 17, textY + 180)
    doc.text('view to prevent fraudulent claims and obtain material information regarding the assessment of risks proposed', 17, textY + 185)
    doc.text('for insurance. By reducing the incidents of fraud and assessing risks fairly, future premium increases may be limited.', 17, textY + 190)
    doc.text('This is done in the public interest and in the interest of all current and potential policyholders.', 17, textY + 195)
    doc.text('By the insurer accepting or renewing this insurance, you or any other person that is represented', 17, textY + 200)
    doc.text('herein, gives consent to the said information being disclosed to any other insurance company or its agent.', 17, textY + 205)
    doc.text('You also similarly give consent to the sharing of information in regards to past insurance policies and', 17, textY + 210)
    doc.text('claims that you have made. You also acknowledge that information provided by yourself or your', 17, textY + 215)
    doc.text('representative may be verified against any legally recognised sources or databases.', 17, textY + 220)
    doc.text('By insuring or renewing your insurance you hereby not only consent to such information sharing, but', 17, textY + 225)
    doc.text('also waive any rights of confidentiality with regards to underwriting or claims information that you', 17, textY + 230)
    doc.text('have provided or that has been provided by another person on your behalf.', 17, textY + 235)
    doc.text('In the event of a claim, the information you have supplied with your application, together with the', 17, textY + 240)
    doc.text('information you supply in relation to the claim, will be made available to other insurers participating', 17, textY + 245)
    doc.text('in the sharing of insurance information.', 17, textY + 250)
    doc.text('As a member of the SA Army Foundation, you and your dependants personal information may be shared with the', 17, textY + 255)
    doc.text('employer. This will be limited to information that is relevant to you and/or your dependants’ application or', 17, textY + 260)
    doc.text('information that is required for the ongoing servicing of your membership.', 17, textY + 265)

    doc.addPage()
    doc.setFillColor('#FFEFED')
    doc.rect(15, 15, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    // Member due diligence 1
    doc.text('Member Due Diligence', 75, 18.5)
    doc.rect(15, 20, 166, 60, 'S')
    doc.setFont('Helvetica', 'normal')
    doc.text('As part of our responsibilities as an Accountable Institution in terms of the Financial Intelligence Centre', 17, 25)
    doc.text('Amendment Act 1 of 2017, we are required to gather the following member information prior to concluding', 17, 30)
    doc.text('the transaction. The nature and purpose of this transaction is to secure Group Life and Funeral cover.', 17, 35)
    doc.text('What is the source of Funds?', 17, 40)
    doc.setFont('Helvetica', 'bold')
    doc.text(sourceIncome, 70, 40)
    if (sourceIncome === 'Other') { doc.text('Other: _____________', 140, 40) }
    doc.setFont('Helvetica', 'normal')
    doc.text('I do hereby declare that the source of the funds that I expect to use in concluding transactions with', 17, 45)
    doc.text('Shield Life® Ltd originates from the sources indicated above. I further confirm that these funds are', 17, 50)
    doc.text('derived from legitimate sources.', 17, 55)
    doc.addImage(signature, 'JPEG', 25, 55, 35, 35, 'final', 'MEDIUM')
    doc.text('____________________________', 20, 75)
    doc.text('Signature of Main Member', 25, 79)
    doc.text(`Date: ${timeStamp}`, 72, 77)
    doc.setFont('Helvetica', 'bold')

    doc.setFontSize(11)
    doc.text('2. Compulsory personal information:', 15, 87)

    let textline = 0
    // General Details
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 90, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('General Details', 85, textline + 93.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, textline + 95, 166, 5, 'S')
    doc.text('Force No:', 17.5, textline + 99)
    doc.text(forceNumber, 44, textline + 99)
    doc.rect(15, textline + 95, 24, 5, 'S')
    doc.text('Surname:', 93, textline + 99)
    doc.text(surname, 119, textline + 99)
    doc.rect(90, textline + 95, 24, 5, 'S')
    doc.rect(15, textline + 100, 166, 5, 'S')
    doc.text('Rank/Title:', 17.5, textline + 104)
    doc.text(rankTitle, 44, textline + 104)
    doc.rect(15, textline + 100, 24, 5, 'S')
    doc.text('ID Number:', 93, textline + 104)
    doc.text(IDNumber, 119, textline + 104)
    doc.rect(90, textline + 100, 24, 5, 'S')
    doc.rect(15, textline + 105, 166, 5, 'S')
    doc.text('Full Names:', 17.5, textline + 109)
    doc.text(fullNames, 44, textline + 109)
    doc.rect(15, textline + 105, 24, 5, 'S')
    doc.text('Marital Status:', 93, textline + 109)
    doc.text(maritalStatus, 119, textline + 109)
    doc.rect(90, textline + 105, 24, 5, 'S')
    // electronic Contact details
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 110, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Electronic Contact Details', 78, textline + 113.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, textline + 115, 166, 5, 'S')
    doc.text('Cell Number:', 17.5, textline + 119)
    doc.text(cellNumber, 44, textline + 119)
    doc.rect(15, textline + 115, 24, 5, 'S')
    doc.text('Home Email Address:', 93, textline + 119)
    doc.text(emailAddress, 129, textline + 119)
    doc.rect(90, textline + 115, 35, 5, 'S')
    doc.rect(15, textline + 120, 166, 5, 'S')
    doc.text('Landline Number:', 17.5, textline + 124)
    doc.text(landlineNumber, 55, textline + 124)
    doc.rect(15, textline + 120, 35, 5, 'S')
    // doc.text('Free Email Address:', 93, textline + 124)
    // doc.text(`${forceNumber}@myarmyfoundation.co.za`, 129, textline + 124)
    // doc.rect(90, textline + 120, 35, 5, 'S')
    // arms of service
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 125, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Arms of Service', 85, textline + 128.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, textline + 130, 166, 5, 'S')
    doc.text('Unit:', 17.5, textline + 134)
    doc.text(unitName, 44, textline + 134)
    doc.rect(15, textline + 130, 24, 5, 'S')
    doc.text('Unit Switchboard No:', 93, textline + 134)
    doc.text(unitSwitchBoardNumber, 129, textline + 134)
    doc.rect(90, textline + 130, 35, 5, 'S')
    // Physical Address
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 135, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Physical Address', 85, textline + 138.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, textline + 140, 166, 5, 'S')
    doc.text('Street and Numbers:', 17.5, textline + 144)
    doc.text(`${streetNumber} ${streetName}`, 55, textline + 144)
    doc.rect(15, textline + 140, 35, 5, 'S')
    doc.text('Suburb:', 93, textline + 144)
    doc.text(suburb, 120, textline + 144)
    doc.rect(90, textline + 140, 24, 5, 'S')
    doc.rect(15, textline + 145, 166, 5, 'S')
    doc.rect(90, textline + 145, 24, 5, 'S')
    doc.text('Complex:', 93, textline + 149)
    doc.text(complexName, 120, textline + 149)
    doc.rect(15, textline + 145, 35, 5, 'S')
    doc.text('Street Code:', 17.5, textline + 149)
    doc.text(postalCode, 55, textline + 149)
    doc.rect(15, textline + 150, 166, 5, 'S')
    doc.text('Is the postal address the same as the Residential address?  If no, please complete:', 17.5, textline + 154)
    doc.setFont('Helvetica', 'bold')
    doc.text('Yes', 150, textline + 154)
    doc.setFont('Helvetica', 'normal')
    // Postal Address
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 155, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Postal Address', 85, textline + 158.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, textline + 160, 166, 5, 'S')
    doc.text('Postal Number:', 17.5, textline + 164)
    doc.text(`${streetNumber} ${streetName}`, 55, textline + 164)
    doc.rect(15, textline + 160, 35, 5, 'S')
    doc.text('Suburb:', 93, textline + 164)
    doc.text(suburb, 129, textline + 164)
    doc.rect(90, textline + 160, 24, 5, 'S')
    doc.rect(15, textline + 165, 166, 5, 'S')
    doc.text(`Postal Code:`, 17.5, textline + 169)
    doc.text(`${postalCode}`, 55, textline + 169)
    doc.rect(15, textline + 165, 35, 5, 'S')

    // Compulsory Beneficiary Information
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('3. Compulsory Beneficiary Information', 15, textline + 177)
    // header block
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 180, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Details of Primary Beneficiary (adult over the age of 18  - person responsible for funeral)', 30, textline + 183.5)
    doc.setFont('Helvetica', 'normal')
    // blocks see through
    doc.rect(15, textline + 185, 166, 5, 'S')
    doc.rect(15, textline + 185, 35, 5, 'S')
    doc.rect(90, textline + 185, 24, 5, 'S')
    doc.rect(15, textline + 190, 166, 5, 'S')
    doc.rect(15, textline + 190, 35, 5, 'S')
    doc.rect(90, textline + 190, 24, 5, 'S')
    doc.rect(15, textline + 195, 166, 5, 'S')
    doc.rect(15, textline + 195, 35, 5, 'S')
    doc.rect(90, textline + 195, 24, 5, 'S')
    // text
    doc.text('ID Number:', 17.5, textline + 189)
    doc.text(pbID, 55, textline + 189)
    doc.text('Cell Number', 93, textline + 189)
    doc.text(pbCellNumber, 129, textline + 189)
    doc.text('Full Names:', 17.5, textline + 194)
    doc.text(pbFullNames, 55, textline + 194)
    doc.text('Landline No:', 93, textline + 194)
    doc.text(pbLandline, 129, textline + 194)
    doc.text('Surname:', 17.5, textline + 199)
    doc.text(pbSurname, 55, textline + 199)
    doc.text('Relationship', 93, textline + 199)
    doc.text(pbRelationship, 129, textline + 199)
    // Details of Next-in-line Beneficiary (adult over the age of 18) *
    // header block
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 200, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Details of Next-in-line Beneficiary (adult over the age of 18)', 50, textline + 203.5)
    doc.setFont('Helvetica', 'normal')
    // blocks see through
    doc.rect(15, textline + 205, 166, 5, 'S')
    doc.rect(15, textline + 205, 35, 5, 'S')
    doc.rect(90, textline + 205, 24, 5, 'S')
    doc.rect(15, textline + 210, 166, 5, 'S')
    doc.rect(15, textline + 210, 35, 5, 'S')
    doc.rect(90, textline + 210, 24, 5, 'S')
    doc.rect(15, textline + 215, 166, 5, 'S')
    doc.rect(15, textline + 215, 35, 5, 'S')
    doc.rect(90, textline + 215, 24, 5, 'S')
    // text
    doc.text('ID Number:', 17.5, textline + 209)
    doc.text(nlIDNumber, 55, textline + 209)
    doc.text('Cell Number', 93, textline + 209)
    doc.text(nlCellNumber, 129, textline + 209)
    doc.text('Full Names:', 17.5, textline + 214)
    doc.text(nlFullNames, 55, textline + 214)
    doc.text('Landline No:', 93, textline + 214)
    doc.text(nlLandline, 129, textline + 214)
    doc.text('Surname:', 17.5, textline + 219)
    doc.text(nlSurname, 55, textline + 219)
    doc.text('Relationship', 93, textline + 219)
    doc.text(nlRelationship, 129, textline + 219)
    doc.setFontSize(7)
    doc.setFont('Helvetica', 'italic')
    doc.text('* Please note:  The next-in-line beneficiary will only receive the benefit payment if the primary beneficiary is predeceased', 15, textline + 223)

    textline += -2
    doc.setFont('Helvetica', 'normal')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('4. Main member immediate family members information, if applicable:', 15, textline + 233)
    // Details of Spouse
    // header block
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 235, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Details of Spouse', 40, textline + 238.5)
    doc.text('Details of Additional Spouse', 110, textline + 238.5)
    doc.setFont('Helvetica', 'normal')
    // blocks see through
    doc.rect(15, textline + 240, 166, 5, 'S')
    doc.rect(15, textline + 240, 35, 5, 'S')
    doc.rect(90, textline + 240, 35, 5, 'S')
    doc.rect(15, textline + 245, 166, 5, 'S')
    doc.rect(15, textline + 245, 35, 5, 'S')
    doc.rect(90, textline + 245, 35, 5, 'S')
    doc.rect(15, textline + 250, 166, 5, 'S')
    doc.rect(15, textline + 250, 35, 5, 'S')
    doc.rect(90, textline + 250, 35, 5, 'S')
    // text
    doc.text('Name & Surname:', 17.5, textline + 244)
    doc.text('ID Number:', 17.5, textline + 249)
    doc.text('Contact Number', 17.5, textline + 254)
    doc.text(`${spouseFullNames} ${spouseSurname}`, 55, textline + 244)
    doc.text(spouseID, 55, textline + 249)
    doc.text(spouseCell, 55, textline + 254)
    doc.text('Name & Surname:', 93, textline + 244)
    doc.text('ID Number:', 93, textline + 249)
    doc.text('Contact Number', 93, textline + 254)

    // Details of Children (under the age of 21 years; 26 years if full time student)
    doc.addPage()
    // header block
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 15, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Details of Children (under the age of 21 years; 26 years if full time student)', 43, 18.5)
    doc.setFont('Helvetica', 'normal')
    // blocks see through
    doc.rect(15, 20, 166, 5, 'S')
    doc.rect(15, 25, 166, 5, 'S')
    doc.rect(15, 25, 35, 5, 'S')
    doc.rect(90, 25, 35, 5, 'S')
    doc.rect(15, 30, 166, 5, 'S')
    doc.rect(15, 30, 35, 5, 'S')
    doc.rect(90, 30, 35, 5, 'S')
    doc.rect(15, 35, 166, 5, 'S')
    doc.rect(15, 35, 35, 5, 'S')
    doc.rect(90, 35, 35, 5, 'S')
    doc.rect(15, 40, 166, 5, 'S')
    doc.rect(15, 40, 35, 5, 'S')
    doc.rect(90, 40, 35, 5, 'S')
    doc.rect(15, 45, 166, 5, 'S')
    doc.rect(15, 45, 35, 5, 'S')
    doc.rect(90, 45, 35, 5, 'S')
    doc.rect(15, 50, 166, 5, 'S')
    doc.rect(15, 50, 35, 5, 'S')
    doc.rect(90, 50, 35, 5, 'S')
    doc.rect(15, 55, 166, 5, 'S')
    doc.rect(15, 55, 35, 5, 'S')
    doc.rect(90, 55, 35, 5, 'S')
    doc.rect(15, 60, 166, 5, 'S')
    doc.rect(15, 60, 35, 5, 'S')
    doc.rect(90, 60, 35, 5, 'S')
    doc.rect(15, 65, 166, 5, 'S')
    doc.rect(15, 65, 35, 5, 'S')
    doc.rect(90, 65, 35, 5, 'S')
    doc.rect(15, 70, 166, 5, 'S')
    doc.rect(15, 70, 35, 5, 'S')
    doc.rect(90, 70, 35, 5, 'S')
    doc.rect(15, 75, 166, 5, 'S')
    doc.rect(15, 75, 35, 5, 'S')
    doc.rect(90, 75, 35, 5, 'S')
    doc.rect(15, 80, 166, 5, 'S')
    doc.rect(15, 80, 35, 5, 'S')
    doc.rect(90, 80, 35, 5, 'S')
    // text
    doc.setFont('Helvetica', 'italic')
    doc.text('Legitimate children of the member can be included as indicated / noted on the persol system.', 35, 23.5)
    doc.setFont('Helvetica', 'normal')
    // left col
    doc.text('Name & Surname:', 17.5, 29)
    doc.text('Date of Birth:', 17.5, 34)
    doc.text('Gender:', 17.5, 39)
    doc.text('Age:', 17.5, 44)
    doc.text('Name & Surname:', 17.5, 49)
    doc.text('Date of Birth:', 17.5, 54)
    doc.text('Gender:', 17.5, 59)
    doc.text('Age:', 17.5, 64)
    doc.text('Name & Surname:', 17.5, 69)
    doc.text('Date of Birth:', 17.5, 74)
    doc.text('Gender:', 17.5, 79)
    doc.text('Age:', 17.5, 84)
    // left col state
    doc.text(`${childFullNames} ${childSurname}`, 52, 29)
    doc.text(childID, 52, 34)
    doc.text(childTitle, 52, 39)
    doc.text(childAge, 52, 44)
    doc.text(`${childFullNames2} ${childSurname2}`, 52, 49)
    doc.text(childID2, 52, 54)
    doc.text(childTitle2, 52, 59)
    doc.text(childAge2, 52, 64)
    doc.text(`${childFullNames3} ${childSurname3}`, 52, 69)
    doc.text(childID3, 52, 74)
    doc.text(childTitle3, 52, 79)
    doc.text(childAge3, 52, 84)
    // right col
    doc.text('Name & Surname:', 93, 29)
    doc.text('Date of Birth:', 93, 34)
    doc.text('Gender:', 93, 39)
    doc.text('Age:', 93, 44)
    doc.text('Name & Surname:', 93, 49)
    doc.text('Date of Birth:', 93, 54)
    doc.text('Gender:', 93, 59)
    doc.text('Age:', 93, 64)
    doc.text('Name & Surname:', 93, 69)
    doc.text('Date of Birth:', 93, 74)
    doc.text('Gender:', 93, 79)
    doc.text('Age:', 93, 84)
    // right col state
    doc.text(`${childFullNames4} ${childSurname4}`, 129, 29)
    doc.text(childID4, 129, 34)
    doc.text(childTitle4, 129, 39)
    doc.text(childAge4, 129, 44)
    doc.text(`${childFullNames5} ${childSurname5}`, 129, 49)
    doc.text(childID5, 129, 54)
    doc.text(childTitle5, 129, 59)
    doc.text(childAge5, 129, 64)
    doc.text(`${childFullNames6} ${childSurname6}`, 129, 69)
    doc.text(childID6, 129, 74)
    doc.text(childTitle6, 129, 79)
    doc.text(childAge6, 129, 84)
    // 5. Compulsory membership details:
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('5. Compulsory membership details:', 15, 93)
    doc.setFontSize(9)
    // Membership Details
    doc.setFillColor('#FFEFED')
    doc.rect(15, 95, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Membership Details', 77, 99)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 100, 166, 15, 'S')
    // text
    doc.text('Membership type', 78, 105)
    doc.text(`${memberType} ${childrenAmount > 0 ? childrenAmount : ''}`, 78, 110)
    doc.text(`R${monthlyPremium}`, 78, 114)
    // 6. Deduction details:
    if (paymentMethod === 'Payroll') {
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('6. Deduction details:', 15, 123)
      doc.setFontSize(9)
      // Stop Order (Persol deduction) Mandate
      doc.setFillColor('#FFEFED')
      doc.rect(15, 125, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Stop Order (Persol deduction) Mandate', 60, 129)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 130, 83, 5, 'S')
      doc.rect(15, 135, 83, 5, 'S')
      doc.rect(15, 140, 83, 5, 'S')
      doc.rect(15, 145, 83, 5, 'S')
      doc.rect(98, 130, 83, 5, 'S')
      doc.rect(98, 135, 83, 5, 'S')
      doc.rect(98, 140, 83, 5, 'S')
      doc.rect(98, 145, 83, 5, 'S')
      // text
      doc.text('Full Names & Surname', 16.5, 134)
      doc.text('ID Number:', 16.5, 139)
      doc.text('Rank/Title', 16.5, 144)
      doc.text('Start date:', 16.5, 149)
      doc.text('Force No:', 100, 134)
      doc.text('Unit', 100, 139)
      doc.text('Payslip reference:', 100, 144)
      doc.text('End date:', 100, 149)
      doc.text(`- ${fullNames} ${surname}`, 50, 134)
      doc.text(`- ${IDNumber}`, 33, 139)
      doc.text(`- ${rankTitle}`, 33, 144)
      doc.setFontSize(9)
      if (day <= 18) doc.text(`1st ${month[monthNo]} ${yearNow}`, 33, 149);
      else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 33, 149);
      // doc.text('1st of May 2022', 33, 149)
      doc.setFontSize(9)
      doc.text(forceNumber, 116.5, 134)
      doc.text(unitName, 116.5, 139)
      doc.text('ARMY FND: FUNDS', 126.5, 144)
      doc.text('until I formally cancel this Policy', 116.5, 149)
      doc.rect(15, 150, 166, 23, 'S')
      doc.text('I authorise the Accountant of the Department of Defence to deduct the monthly payments from my salary as per', 16, 155)
      doc.text('the above authority provided and remit it to the SA Army Foundation of which I am a member,  each and every', 16, 160)
      doc.text('month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.', 16, 165)
      doc.text('I have read and accepted the terms and conditions as set out below.', 16, 170)
      // Terms & Conditions
      doc.rect(15, 175, 166, 65, 'S')
      doc.setFont('Helvetica', 'bold')
      doc.text('Terms & Conditions', 16.5, 180)
      doc.setFont('Helvetica', 'normal')
      doc.text('I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.', 16.5, 185)
      doc.text('I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any', 16.5, 190)
      doc.text('refund of amounts which have been withdrawn while this authority was in force, if such amounts were legally', 16.5, 195)
      doc.text('owing to you.Should the premium rate be adjusted by the SA Army Foundation as a result of a general', 16.5, 200)
      doc.text('decrease/increase in subscription or should I request the SA Army Foundation to decrease/increase the', 16.5, 205)
      doc.text('premium for certain reason, I confirm that the adjusted premium may be deducted.I acknowledge that', 16.5, 210)
      doc.text('this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, 215)
      // signature
      doc.addImage(signature, 'JPEG', 25, 210, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, 230)
      doc.text('Signature of Main Member', 25, 235)
      doc.text(`Date: ${timeStamp}`, 72, 235)
    } else {
      doc.setFontSize(11)
      doc.setFont('Helvetica', 'bold')
      doc.text('6. Deduction details:', 15, 123)
      doc.setFontSize(9)
      // Stop Order (Persol deduction) Mandate
      doc.setFillColor('#FFEFED')
      doc.rect(15, 125, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Debit Order Mandate', 77, 129)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 130, 83, 5, 'S')
      doc.rect(15, 135, 83, 5, 'S')
      doc.rect(15, 140, 83, 5, 'S')
      doc.rect(15, 145, 83, 5, 'S')
      doc.rect(15, 150, 83, 5, 'S')
      doc.rect(98, 130, 83, 5, 'S')
      doc.rect(98, 135, 83, 5, 'S')
      doc.rect(98, 140, 83, 5, 'S')
      doc.rect(98, 145, 83, 5, 'S')
      doc.rect(98, 150, 83, 5, 'S')
      // text
      doc.text('Bank:', 16.5, 134)
      doc.text('Account Type:', 16.5, 139)
      doc.text('Account No', 16.5, 144)
      doc.text('Branch Code:', 16.5, 149)
      doc.text('Debit Order date:', 16.5, 154)
      doc.text('Acount Holder Name:', 100, 134)
      doc.text('Start Date', 100, 139)
      doc.text('Bank Statement ref:', 100, 144)
      doc.text('End date:', 100, 149)
      doc.text(`${bankName}`, 45, 134)
      doc.text(`${accountType}`, 45, 139)
      doc.text(`${accountNo}`, 45, 144)
      doc.text(`${branchCode}`, 45, 149)
      doc.text(`${debitOrderDate}`, 45, 154)
      doc.setFontSize(9)
      doc.setFontSize(9)
      doc.text(`-${fullNames} ${surname}`, 132, 134)
      if (day <= 18) doc.text(`1st ${month[monthNo]} ${yearNow}`, 132, 139);
      else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 132, 139);
      doc.text('ARMYFUND', 132, 144)
      doc.text('Until I formally cancel this Policy', 132, 149)
      let pageline = 160;
      doc.setFont('Helvetica', 'bold')
      doc.text('Terms & Conditions', 16.5, pageline)
      doc.setFont('Helvetica', 'normal')
      pageline += 2;
      doc.rect(15, pageline, 166, 130, 'S')
      doc.text('I authorise the SA Army Foundation to deduct the monthly payments from this account as per the above authority', 16, pageline + 5)
      doc.text('provided.  I have read and accepted the debit order terms and conditions as set out below.', 16, pageline + 10)
      doc.text('I instruct and authorise the SA Army Foundation to draw against my nominated bank account (or any other bank ', 16, pageline + 15)
      doc.text('or branch to which I may transfer my account) the amount necessary for the payment of the monthly premium', 16, pageline + 20)
      pageline += -10
      doc.text('due in respect of my membership, on the day nominated by me each and every month and continuing until', 16.5, pageline + 35)
      doc.text('cancelled by me in writing or until I substitute it with a new authorisation. I accept that the authority', 16.5, pageline + 40)
      doc.text('may be cancelled by me by giving 31 (thirty one) days notice in writing.', 16.5, pageline + 45)
      doc.text('I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any', 16.5, pageline + 50)
      doc.text('refund of amounts which have been withdrawn while this authority was in force, if such amounts were legally', 16.5, pageline + 55)
      doc.text('owing to you. I understand that the withdrawals hereby authorised will be processed through a', 16.5, pageline + 60)
      doc.text('computerised system provided by the South African Banking Industry and that the details of each withdrawal', 16.5, pageline + 65)
      doc.text('will be printed on my bank statement. I agree that in the event that the payment date falls on a Saturday,', 16.5, pageline + 70)
      doc.text('Sunday or recognised South African public holiday, the payment day will automatically be the first preceding', 16.5, pageline + 75)
      doc.text('business day.I agree that should there be insufficient funds in my account to meet the obligation, the SA Army', 16.5, pageline + 80)
      doc.text('Foundation is entitled to track my account and re-present the instruction for payment as soon as sufficient', 16.5, pageline + 85)
      doc.text('funds are available in my account. Such will contain the SA Army Foundation as reference which shall enable me', 16.5, pageline + 90)
      doc.text('to match the withdrawals with this Membership agreement. I agree to pay any bank charges relating to this debit', 16.5, pageline + 95)
      doc.text('order instruction. Should the premium rate be adjusted by the SA Army Foundation as a result of a general', 16.5, pageline + 100)
      doc.text('decrease/increase in subscription or should I request the SA Army Foundation to decrease/increase the premium', 16.5, pageline + 105)
      doc.text('for certain reason, I confirm that the adjusted premium may be deducted from my bank account. I acknowledge that', 16.5, pageline + 110)
      doc.text('this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, pageline + 115)
      // signature
      doc.addImage(signature, 'JPEG', 25, pageline + 105, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, pageline + 130)
      doc.text('Signature of Main Member', 25, pageline + 135)
      doc.text(`Date: ${timeStamp}`, 72, pageline + 135)
    }
    doc.addPage()
    // 7. Declaration
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('7. Declaration', 15, 15)
    doc.setFontSize(8)
    doc.rect(15, 18, 166, 135, 'S')
    doc.text('By signing this declaration I, the undersigned, consent that my personal information may be transmitted to any third-party', 16, 22)
    doc.text('service provider (Premium Collection Agencies; Consumer Profile Bureau, etc.) that may be appointed to perform ', 16, 27)
    doc.text('functions relating to my policy on behalf of the SA Army Foundation and the Insurer.', 16, 32)
    doc.text('I hereby consent that my personal information may be used for marketing purposes *:', 16, 37)
    doc.text("* Marketing means the marketing of SA Army Foundation's new or enhance offerings promoted by the SA Army", 16, 42)
    doc.text("Foundation; Maxi Forces Financial Services (Pty) Ltd (the broker) and/or Shield Life® Ltd (the Insurer).", 16, 47)
    doc.text('I understand that without the above consent my policy will not be processed and the SA Army Foundation', 16, 52)
    doc.text('will not be liable for any claim that may arise. I understand that the cover will only start when my', 16, 57)
    doc.text('application has been accepted and I have paid the first premium.I have given all information correctly', 16, 62)
    doc.text('and understand that if any information is found to be untrue, my benefit may not be paid or my policy', 16, 67)
    doc.text('could be cancelled. I also agree to notify the Insurer in writing on the applicable form, of any changes', 16, 72)
    doc.text('to the information I have provided. I authorise the Insurer to communicate with me regarding my policy', 16, 77)
    doc.text('via Short Message System ("SMS"); WhatsApp; and/or email.', 16, 82)
    doc.text('It is your responsibility as owner of the policy to make sure that Shield Life® Ltd always has up-to-date', 16, 87)
    doc.text('contact information for you and anyone that can benefit on this contract.  Where Shield Life® Ltd becomes', 16, 92)
    doc.text('aware that there are benefits due to be paid out on the policy, we will always first try to contact you', 16, 97)
    doc.text('or your beneficiaries at the last address provided to us.  If we are not able to contact you at this address,', 16, 102)
    doc.text('we have to take other reasonable steps to try find the person that is entitled to the policy benefits.', 16, 107)
    doc.text('In order to do this, we may have to appoint external tracing agents.  By signing this application, you agree', 16, 112)
    doc.text('that Shield Life® Ltd can give the external tracing agents access to your personal information in order to be', 16, 117)
    doc.text('able to do any tracing.  It is also important to note that, if we have to appoint tracing agents,', 16, 122)
    doc.text('a tracing and management fee may be deducted by us form the benefit payable.', 16, 127)
    // signature
    doc.addImage(signature, 'JPEG', 25, 127, 35, 35, 'final', 'MEDIUM')
    doc.text('____________________________', 20, 145)
    doc.text('Signature of Main Member', 25, 150)
    doc.text(`Date: ${timeStamp}`, 72, 150)

    // Extended Family
    doc.addPage()
    var page4line = 15;
    // doc.addImage(SAArmyLogo, 'SVG', 90, page4line, 15, 15)
    // doc.addImage(ShieldLogo, 'SVG', 110, page4line, 20, 20)
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text(`8. Family member information that need to reflect on the Membership's My Family Product:`, 15, page4line)
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    page4line = page4line + 3;
    doc.rect(15, page4line, 173, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    page4line = page4line + 3.5;
    var table01 = page4line;
    doc.text('Type/Relation', 17, page4line)
    doc.text('Full Name & Surname', 44, page4line)
    doc.text('Date of Birth', 108, page4line)
    //doc.text('Realtionship', 110, page4line)
    doc.text('Benefit', 135, page4line)
    doc.text('Monthly Premium*', 158, page4line)
    page4line = page4line - 3.5;
    doc.rect(15, page4line, 140, 5, 'S')
    doc.rect(15, page4line, 25, 5, 'S')
    doc.rect(105, page4line, 25, 5, 'S')
    //doc.rect(105, page4line, 30, 5, 'S')
    page4line = page4line + 5;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    doc.rect(15, page4line, 140, 10, 'S')
    doc.rect(15, page4line, 25, 10, 'S')
    doc.rect(105, page4line, 25, 10, 'S')
    //doc.rect(105, page4line, 30, 10, 'S')
    doc.rect(155, page4line, 33, 10, 'S')
    page4line = page4line + 10;
    // doc.rect(90, 63, 57, 5, 'S')
    doc.rect(155, page4line, 33, 5, 'S')
    // doc.rect(90, 68, 57, 5, 'S')
    page4line = page4line + 5;
    doc.rect(155, page4line, 33, 5, 'S') //63

    doc.text(memberRelation, 16, table01 + 5)
    doc.text(memberRelation2, 16, table01 + 15)
    doc.text(memberRelation3, 16, table01 + 25)
    doc.text(memberRelation4, 16, table01 + 35)
    doc.text(memberRelation5, 16, table01 + 45)
    doc.text(memberRelation6, 16, table01 + 55)
    doc.text(memberRelation7, 16, table01 + 65)
    doc.text(memberRelation8, 16, table01 + 75)

    doc.text(`${name} ${relationSurname}`, 42, table01 + 5)
    doc.text(`${name2} ${relationSurname2}`, 42, table01 + 15)
    doc.text(`${name3} ${relationSurname3}`, 42, table01 + 25)
    doc.text(`${name4} ${relationSurname4}`, 42, table01 + 35)
    doc.text(`${name5} ${relationSurname5}`, 42, table01 + 45)
    doc.text(`${name6} ${relationSurname6}`, 42, table01 + 55)
    doc.text(`${name7} ${relationSurname7}`, 42, table01 + 65)
    doc.text(`${name8} ${relationSurname8}`, 42, table01 + 75)

    doc.text(dateOfBirth, 109, table01 + 5)
    doc.text(dateOfBirth2, 109, table01 + 15)
    doc.text(dateOfBirth3, 109, table01 + 25)
    doc.text(dateOfBirth4, 109, table01 + 35)
    doc.text(dateOfBirth5, 109, table01 + 45)
    doc.text(dateOfBirth6, 109, table01 + 55)
    doc.text(dateOfBirth7, 109, table01 + 65)
    doc.text(dateOfBirth8, 109, table01 + 75)

    doc.text(relationship, 16, table01 + 10)
    doc.text(relationship2, 16, table01 + 20)
    doc.text(relationship3, 16, table01 + 30)
    doc.text(relationship4, 16, table01 + 40)
    doc.text(relationship5, 16, table01 + 50)
    doc.text(relationship6, 16, table01 + 60)
    doc.text(relationship7, 16, table01 + 70)
    doc.text(relationship8, 16, table01 + 80)

    doc.text(benefit, 135, table01 + 5)
    doc.text(benefit2, 135, table01 + 15)
    doc.text(benefit3, 135, table01 + 25)
    doc.text(benefit4, 135, table01 + 35)
    doc.text(benefit5, 135, table01 + 45)
    doc.text(benefit6, 135, table01 + 55)
    doc.text(benefit7, 135, table01 + 65)
    doc.text(benefit8, 135, table01 + 75)

    doc.text(`R${premium}.00`, 170, table01 + 5)
    doc.text(`R${premium2}.00`, 170, table01 + 15)
    doc.text(`R${premium3}.00`, 170, table01 + 25)
    doc.text(`R${premium4}.00`, 170, table01 + 35)
    doc.text(`R${premium5}.00`, 170, table01 + 45)
    doc.text(`R${premium6}.00`, 170, table01 + 55)
    doc.text(`R${premium7}.00`, 170, table01 + 65)
    doc.text(`R${premium8}.00`, 170, table01 + 75) //62
    table01 = table01 + 40;
    doc.text("Monthly Policy Fee", 125, table01 + 45) //67
    doc.text("Total Monthly premium", 119, table01 + 50)

    doc.text("R10.00", 170, table01 + 45)
    if (extendedMonthlyPremium != 0) { doc.text(`R${extendedMonthlyPremium}.00`, 170, table01 + 50) }
    else { doc.text(`R0.00`, 170, table01 + 50) }

    var table02else = page4line;
    //9. Extended family deduction details:
    if (extendedPaymentMethod === 'Payroll') {
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      page4line = page4line + 15;
      doc.text('9. Deduction details:', 15, page4line) //92
      doc.setFontSize(9)
      // Stop Order (Persol deduction) Mandate
      doc.setFillColor('#FFEFED')
      page4line = page4line + 3;
      doc.rect(15, page4line, 173, 5, 'FD') //95
      doc.setFont('Helvetica', 'bold')
      page4line = page4line + 3.5;
      doc.text('Stop Order (Persol deduction) Mandate', 80, page4line)//98.5
      doc.setFont('Helvetica', 'normal')
      page4line = page4line + 1.5;
      var table02 = page4line;
      doc.rect(15, table02, 83, 5, 'S') //100
      doc.rect(15, table02 + 5, 83, 5, 'S')
      doc.rect(15, table02 + 10, 83, 5, 'S')
      doc.rect(15, table02 + 15, 83, 5, 'S')

      doc.rect(98, table02, 90, 5, 'S')
      doc.rect(98, table02 + 5, 90, 5, 'S')
      doc.rect(98, table02 + 10, 90, 5, 'S')
      doc.rect(98, table02 + 15, 90, 5, 'S')
      // text
      doc.text('Full Names & Surname:', 16.5, table02 + 4) //104
      doc.text('ID Number:', 16.5, table02 + 9)
      doc.text('Rank/Title', 16.5, table02 + 14)
      doc.text('Start date:', 16.5, table02 + 19)
      doc.text('Force No:', 100, table02 + 4)
      doc.text('Unit:', 100, table02 + 9)
      doc.text('Payslip reference:', 100, table02 + 14)
      doc.text('End date:', 100, table02 + 19)
      doc.text(`- ${fullNames} ${surname}`, 50, table02 + 4)
      doc.text(`- ${IDNumber}`, 33, table02 + 9)
      doc.text(`- ${rankTitle}`, 33, table02 + 14)
      doc.setFontSize(9)
      doc.setFont('Helvetica', 'bold')
      doc.text(`1st ${month[monthNo]} ${yearNow}`, 33, table02 + 19)
      doc.setFontSize(9)
      doc.text(forceNumber, 116.5, table02 + 4)
      doc.text(unitName, 116.5, table02 + 9)
      doc.text('SHIELD LIFE LTD(Deduction code 3294)', 126.5, table02 + 14)
      doc.text('until I formally cancel this Policy', 116.5, table02 + 19)

      doc.rect(15, table02 + 20, 173, 18, 'S')
      doc.setFont('Helvetica', 'normal')
      doc.text('I authorise the Accountant of the Department of Defence to deduct the monthly payments from my salary as per the above', 16, table02 + 25)
      doc.text('authority provided and remit it to Shield Life Limited, each and every month and continuing until cancelled by me in writing', 16, table02 + 30)
      doc.text('or until I substitute it with a new authorisation. I have read and accepted the terms and conditions as set out below.', 16, table02 + 35)
      // Terms & Conditions
      doc.rect(15, table02 + 40, 173, 70, 'S')
      doc.setFont('Helvetica', 'bold')
      doc.text('Terms & Conditions', 16.5, table02 + 45)
      doc.setFont('Helvetica', 'normal')
      doc.text('I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.', 16.5, table02 + 50)
      doc.text('I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts', 16.5, table02 + 55)
      doc.text('which have been withdrawn while this authority was in force, if such amounts were legally owing to you.', 16.5, table02 + 60)
      doc.text('Should the premium rate be adjusted by the Shield Life Limited as a result of a general decrease/increase in', 16.5, table02 + 65)
      doc.text('subscription or should I request the Shild Life Limited to decrease/increase the premium for certain reason, I confirm', 16.5, table02 + 70)
      doc.text('that the adjusted premium may be deducted.', 16.5, table02 + 75)
      // doc.text('I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, table02 + 80)
      // signature
      table02 += -5
      doc.text('I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, table02 + 85)
      doc.addImage(signature, 'JPEG', 25, table02 + 80, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, table02 + 100)
      doc.text('Signature of Premium Payer', 25, table02 + 105)
      doc.text(`Date: ${timeStamp}`, 72, table02 + 105)
    } else {
      table02else = table02else + 23;
      doc.setFontSize(11)
      doc.text('9. Deduction details:', 15, table02else)//92
      doc.setFontSize(9)
      // Stop Order (Persol deduction) Mandate
      doc.setFillColor('#FFEFED')
      doc.rect(15, table02else + 3, 173, 5, 'FD') //95
      doc.setFont('Helvetica', 'bold')
      doc.text('Debit order Mandate', 80, table02else + 7)//98.5
      doc.setFont('Helvetica', 'normal')
      var table02el = table02else + 8.1;
      doc.rect(15, table02el, 83, 5, 'S')//100
      doc.rect(15, table02el + 5, 83, 5, 'S')
      doc.rect(15, table02el + 10, 83, 5, 'S')
      doc.rect(15, table02el + 15, 83, 5, 'S')
      doc.rect(15, table02el + 20, 83, 5, 'S')
      doc.rect(15, table02el + 25, 83, 5, 'S')

      doc.rect(98, table02el, 90, 5, 'S')
      doc.rect(98, table02el + 5, 90, 5, 'S')
      doc.rect(98, table02el + 10, 90, 5, 'S')
      doc.rect(98, table02el + 15, 90, 5, 'S')
      doc.rect(98, table02el + 20, 90, 5, 'S')
      doc.rect(98, table02el + 25, 90, 5, 'S')
      // text
      doc.text('Acount Holder Name:', 16.5, table02el + 4)
      doc.text('Bank & branch code:', 16.5, table02el + 9)
      doc.text('Account Type:', 16.5, table02el + 14)
      doc.text('Debit order date:', 16.5, table02el + 19)
      doc.text('First instalment date:', 16.5, table02el + 24)
      doc.text('Beneficiary:', 16.5, table02el + 29)

      doc.text('Account No:', 100, table02el + 4)
      doc.text('Other:', 100, table02el + 9)
      doc.text('Other:', 100, table02el + 14)
      doc.text('Bank Statement ref:', 100, table02el + 19)
      doc.text('Repayment term:', 100, table02el + 24)
      doc.text('Payment streem:', 100, table02el + 29)

      doc.setFont('Helvetica', 'bold')

      doc.text(`${fullNames} ${surname}`, 47, table02el + 4)
      doc.text(`${bankNameWider} ${branchCodeWider}`, 47, table02el + 9)
      doc.text(`${accountTypeWider}`, 47, table02el + 14)
      if (debitOrderDates === 1) doc.text(`${debitOrderDates}st of each month`, 47, table02el + 19)
      else if (debitOrderDates === 2) doc.text(`${debitOrderDates}nd of each month`, 47, table02el + 19)
      else if (debitOrderDates === 3) doc.text(`${debitOrderDates}rd of each month`, 47, table02el + 19)
      else doc.text(`${debitOrderDates}th of each month`, 47, table02el + 19)
      doc.text(`1st ${month[monthNo]} ${yearNow}`, 47, table02el + 24)
      doc.text('ShieldLife', 47, table02el + 29)

      doc.text(`${accountNoWider}`, 132, table02el + 4)
      doc.text('-', 132, table02el + 9)
      doc.text('-', 132, table02el + 14)
      doc.text('SHIELDLIFE', 132, table02el + 19)
      doc.setFontSize(8)
      doc.text('above frequency until lapses/policy cancelled', 125, table02el + 24)
      doc.setFontSize(9)
      doc.text('Debicheck', 132, table02el + 29)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, table02el + 30, 173, 85, 'S')
      doc.text('I authorise Shield Life Limited to issue and deliver debit order payment instructions to your banker for collection against', 16, table02el + 35)
      doc.text('my Account and bank used by my employer to deposit my salaries/wages, which account may differ from the account', 16, table02el + 40)
      doc.text('specified above on condition that the sum of each payment instruction and frequency of payment requests will never ', 16, table02el + 45)
      doc.text('exceed the obligations as agreed and defined in the Agreement specified.', 16, table02el + 50)
      doc.text(`My bank's participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules`, 16, table02el + 55)
      doc.text('applicable to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil', 16, table02el + 60)
      doc.text('the obligations as defined in the agreement. My authorisation is subject thereto that the same payment instruction may', 16, table02el + 65)
      doc.text('never be presented simulataneously in more than one payment stream during the same repayment cycle or, if unpaid in a', 16, table02el + 70)
      doc.text('payment stream selected be represented in the same cycle in another payment stream. I can only service the obligations', 16, table02el + 75)
      doc.text('defined in here if the payment instructions are excuted as close as possible to when I receive my salary or wages which', 16, table02el + 80)
      doc.text('dates vary from month to month, especially during December of each year. To curb against (1) unpaid bank charges', 16, table02el + 85)
      doc.text('(2) losing the benefits described in the agreement quotes (3) incurring penalties die to non payment; I explicity authorise', 16, table02el + 90)
      doc.text('Shield Life Limited to utilise the functionality of Tracking supported on the debicheck Payment Streams especially after', 16, table02el + 95)
      doc.text('unsuccessful attempts on the Debicheck Payment Stream.', 16, table02el + 100)
      doc.text('Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above', 16, table02el + 105)
      doc.text('mentioned account will be interrogated for a defined period until this period has lapsed or until payment has been received.', 16, table02el + 110)

      // Terms & Conditions
      doc.addPage()
      var page42line = 25;
      doc.rect(15, page42line, 173, 80, 'S')
      //doc.rect(15, table02el+30, 173, 165, 'S')
      page42line = page42line + 5;
      doc.text('I hereby agree that subsequent payment instructions will continue to be delivered in terms of the authority until all', 16, page42line)
      doc.text('obligations have been paid. This authorisation will remain in force until cancelled by me in writing.  I hereby acknowledge', 16, page42line + 5)
      page42line = page42line + 10;
      doc.text('that my bank will charge fees to my account as agreed with them once they process this instruction.  I foresee that I may', 16, page42line)
      page42line = page42line + 5;
      doc.text('change my bank and bank account particulars reflected in here, in which instance I will notify the beneficiaries', 16, page42line)
      doc.text('specified, or any new beneficiary, should the agreement be ceded or assigned to any third party.  Should I however', 16, page42line + 5)
      doc.text('forget to notify the beneficiary or the assigned third party and if the beneficiary or the assigned third party obtain my new', 16, page42line + 10)
      doc.text('bank particulars, on own account, this mandate will not lapse.  This issued mandate will cover the obtained bank', 16, page42line + 15)
      doc.text('information and the beneficiary and the assigned third party may attach such new information, to this signed document', 16, page42line + 20)
      doc.text('as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date changes', 16, page42line + 25)
      doc.text('agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal', 16, page42line + 30)
      doc.text('Entity.', 16, table02el + 165)
      doc.text('I understand that the withdrawals hereby authorised will be processed through a computerised system provided by', 16, page42line + 35)
      doc.text('the South African Banks. I also understand that details of each withdrawal will be printed on my/our bank statement.', 16, page42line + 40)
      doc.text('Such must contain the agreement reference number as stipulated above which number shall enable me to match the', 16, page42line + 45)
      doc.text('withdrawals with this agreement. I acknowledge that this Authority may be ceded or assigned to a third party but only', 16, page42line + 50)
      doc.text('in the event that this agreement is also ceded or assigned to that third party.', 16, page42line + 55)

      page42line = page42line + 60;
      doc.rect(15, page42line + 15, 173, 120, 'S')
      doc.setFont('Helvetica', 'bold')
      doc.text('Terms & Conditions', 16.5, page42line + 18.5)
      doc.setFont('Helvetica', 'normal')
      doc.text('I instruct and authorise Shield Life Limited to draw against my nominated bank account (or any other bank or branch', 16.5, page42line + 25)
      doc.text('to which I may transfer my account) the amount necessary for the payment of the monthly premium due in respect of ', 16.5, page42line + 30)
      doc.text('my membership, on the day nominated by me each and every month and continuing until cancelled by me in writing', 16.5, page42line + 35)
      doc.text('or until I substitute it with a new authorisation.', 16.5, page42line + 40)
      doc.text(`I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing. I understand`, 16.5, page42line + 45)
      doc.text('that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which', 16.5, page42line + 50)
      doc.text('have been withdrawn while this authority was in force, if such amounts were legally owing to you.', 16.5, page42line + 55)
      doc.text('I agree that in the event that the payment date falls on a Saturday,Sunday or recognised South African public holiday,', 16.5, page42line + 60)
      doc.text('the payment day will automatically be the first preceding business day.', 16.5, page42line + 65)
      doc.text('I agree that should there be insufficient funds in my account to meet the obligation, Shield Life Limited are entitled', 16.5, page42line + 70)
      doc.text('to track my account and re-present the instruction for payment as soon as sufficient funds are available in my', 16.5, page42line + 75)
      doc.text('account. Such will contain Shield Life Limited as reference which shall enable me to match the withdrawals with', 16.5, page42line + 80)
      doc.text('this Membership agreement.', 16.5, page42line + 85)
      doc.text('I agree to pay any bank charges relating to this debit order instruction.', 16.5, page42line + 90)
      doc.text('Should the premium rate be adjusted by Shield Life Limited as a result of a general decrease/increase in subscription', 16.5, page42line + 95)
      doc.text('or should I request Shield Life Limited to decrease/increase the premium for certain reason, I confirm that the', 16.5, page42line + 100)
      doc.text('adjusted premium may be deducted from my bank account.', 16.5, page42line + 105)
      doc.text('I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, page42line + 110)
      // signature
      doc.addImage(signature, 'JPEG', 25, page42line + 105, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, page42line + 125)
      doc.text('Signature of Main Member', 25, page42line + 130)
      doc.text(`Date: ${timeStamp}`, 72, page42line + 130)
    }
    // DPIP
    doc.addPage()
    doc.setFont('Helvetica', 'bold')
    doc.text('DPIP refers to a Domestic Prominent Influential Person that is an individual who holds, including in an acting', 25, 15)
    doc.text('position for a period exceeding 6 months, has held a prominent public function in the Republic including that of-', 25, 20)
    let index = 30;
    // doc.text('the Republic the following positions:', 70, index)
    doc.setFont('Helvetica', 'normal')
    // left col
    doc.text('* President or Deputy President', 25, index)
    doc.text('* Government minister ', 25, index + 5)
    doc.text('  or Deputy minister', 25, index + 10)
    doc.text('* Member of a royal family or', 25, index + 15)
    doc.text('  senior traditional leader', 25, index + 20)
    doc.text('* Premier of a Province', 25, index + 25)
    doc.text('* Executive Council member', 25, index + 30)
    doc.text('  of a Province', 25, index + 35)
    doc.text('* Officer of the South African', 25, index + 40)
    doc.text('  National Defence Force above', 25, index + 45)
    doc.text('  the rank of major-general', 25, index + 50)
    // middle  col
    doc.text('* Leader of a political party', 70, index)
    doc.text('* Executive mayor of a municipality', 70, index + 5)
    doc.text('* Municipal manager or CFO of a', 70, index + 10)
    doc.text('  municipality', 70, index + 15)
    doc.text('* Chairperson of the controlling body,', 70, index + 20)
    doc.text('  CEO, CFO, CIO of a municipal entity', 70, index + 25)
    doc.text('* Head, accounting officer of CFO of', 70, index + 30)
    doc.text('  a national / provincial department', 70, index + 35)
    doc.text('  or component', 70, index + 40)
    doc.text('* Constitutional court judge or any', 70, index + 45)
    doc.text('  other judge', 70, index + 50)
    // right col
    doc.text('* Chairperson of the controlling body,', 125, index)
    doc.text('  CEO, CFO, CIO or a natural person who is', 125, index + 5)
    doc.text('  the accounting authority of a public entity', 125, index + 10)
    doc.text('* Ambassador, or high commissioner or other', 125, index + 15)
    doc.text('  senior representative of a foreign government', 125, index + 20)
    doc.text('  based in the Republic; or', 125, index + 25)
    doc.text('* holds, including in an acting position for a', 125, index + 30)
    doc.text('  period exceeding six month, or has held the', 125, index + 35)
    doc.text('  position of head, or other executive directly ', 125, index + 40)
    doc.text('  accountable to that head, of an international', 125, index + 45)
    doc.text('  organisation.', 125, index + 50)
    // FPPO
    index += -5
    doc.setFont('Helvetica', 'bold')
    doc.text('FPEP refers to a Foreign Politically Exposed Person, is an individual who holds, or has held, in a foreign country,', 25, index + 60)
    doc.text('a prominent public function including that of:', 75, index + 65)
    doc.setFont('Helvetica', 'normal')
    // left col
    index += -5
    doc.text('* Head of State / Head of a', 25, index + 75)
    doc.text('  Country or Government', 25, index + 80)
    doc.text('* Member of a foreign royal', 25, index + 85)
    doc.text('  family', 25, index + 90)
    // middle  col
    doc.text('* Government Minister or equivalent ', 70, index + 75)
    doc.text('  senior politician or leader of a', 70, index + 80)
    doc.text('  political party', 70, index + 85)
    doc.text('* Senior executive of a state-owned', 70, index + 90)
    doc.text('  corporation', 70, index + 95)
    // right col
    doc.text('* High ranking member of the military (General;', 125, index + 75)
    doc.text('  Lieutenant General; Admiral or Vice Admiral)', 125, index + 80)
    doc.text('* Senior judicial official', 125, index + 85)
    // PIP
    index += -5
    doc.setFont('Helvetica', 'bold')
    doc.text('PIP refers to a Prominent Influential Person is an individual who holds, or has held at any time in the preceding', 25, index + 105)
    doc.text('12 months, in a company that provides goods or services to an organ of state, the position of:', 35, index + 110)
    doc.setFont('Helvetica', 'normal')
    // left col
    index += -5
    doc.text('* Chairperson of the board of ', 25, index + 120)
    doc.text('  directors:', 25, index + 125)
    // middle  col
    doc.text('* Chairperson of the audit committee;', 70, index + 120)
    // right col
    doc.text('* Executive officer or Chief financial officer,', 125, index + 120)
    // CLOSE
    index += -5
    doc.setFont('Helvetica', 'bold')
    doc.text('Family members and known close associates, of a foreign or domestic politically exposed person or prominent', 25, index + 135)
    doc.text('influential person, as the case may be, including immediate family members;', 50, index + 140)
    doc.setFont('Helvetica', 'normal')
    index += -5
    // left col
    doc.text('* Spouse, civil partner or life', 25, index + 150)
    doc.text('  partner, Parents,', 25, index + 155)
    // middle  col
    doc.text('* Previous spouse, civil partner or life', 70, index + 150)
    doc.text('  partner, if applicable;', 70, index + 155)
    doc.text('* Sibling and step sibling and their', 70, index + 160)
    doc.text('  spouse civil partner or life partner. ', 70, index + 165)
    // right col
    doc.text('* Children and step children and their spouse,', 125, index + 150)
    doc.text('  civil partner or life partner;', 125, index + 155)
    // form fields to be populated
    doc.setFont('Helvetica', 'bold')
    index += -5
    doc.text('Are you a Domestic Politically Exposed Person (DPEP)?', 25, index + 175)
    doc.rect(115, index + 172, 15, 5, 'S')
    doc.text('If yes, please provide details:', 25, index + 180)
    doc.rect(25, index + 182, 150, 10, 'S')
    doc.setTextColor('red')
    doc.text(dpip, 119.5, index + 175.5)
    doc.text(dpipDetails, 28, index + 186)

    index += -5
    doc.setTextColor('#444')
    doc.text('Are you a Foreign Politically Exposed Person (FPEP)?', 25, index + 205)
    doc.rect(115, index + 202, 15, 5, 'S')
    doc.text('If yes, please provide details:', 25, index + 210)
    doc.rect(25, index + 212, 150, 10, 'S')
    index += -5
    doc.text('Are you a Prominent Influential Person(PIP)?', 25, index + 235)
    doc.rect(115, index + 232, 15, 5, 'S')
    doc.text('If yes, please provide details:', 25, index + 240)
    doc.rect(25, index + 242, 150, 10, 'S')

    doc.setTextColor('red')
    doc.text(fppo, 119.5, index + 210.5)
    doc.text(fppoDetails, 28, index + 221)
    doc.text(pip, 119.5, index + 235.5)
    doc.text(pipDetails, 28, index + 246)

    index += 260;
    doc.setTextColor('#444')
    doc.text('Is a close family member or any associates of yours a DPEP or FPEP or PIP?', 25, index)
    doc.rect(145, index - 3, 15, 5, 'S')
    doc.text('If yes, please provide details:', 25, index + 5)
    doc.rect(25, index + 7, 150, 10, 'S')
    doc.setTextColor('red')
    doc.text(closeDpipFppo, 150, index + 0.5)
    doc.text(closeDpipFppoDetails, 28, index + 11)
    doc.setTextColor('black')
    doc.addImage(signature, 33, index + 10, 35, 35)
    //  doc.text(`Date: ${timeStamp}`,72,155)
    doc.text('___________________________', 32, index + 40)
    doc.text('Signature of Main Member', 35, index + 45)

    doc.addPage()
    textline = -70;
    doc.setTextColor('#444')
    doc.setFontSize(13)
    doc.setFont('Helvetica', 'bold')
    doc.addImage(ShieldLogo, "PNG", 15, textline + 75, 23, 23, "logo", "MEDIUM");
    doc.text('PRELIMINARY CERTIFICATE OF COVER', 60, textline + 80)
    doc.setFontSize(10)
    textline += 2;
    doc.text('GROUP LIFE AND FUNERAL POLICY', 75, textline + 85)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, textline + 95, 173, 5, 'S')
    doc.setFont('Helvetica', 'bold')
    doc.text('ID Number:', 17.5, textline + 99)
    doc.setFont('Helvetica', 'normal')
    doc.text(IDNumber, 38, textline + 99)
    doc.rect(15, textline + 95, 22, 5, 'S')
    doc.setFont('Helvetica', 'bold')
    doc.text('Inception date:', 67, textline + 99)
    doc.setFont('Helvetica', 'normal')
    if (day <= 18) doc.text(`1st ${month[monthNo]} ${yearNow}`, 93, textline + 99);
    else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 93, textline + 99);
    doc.rect(65, textline + 95, 25, 5, 'S')
    doc.setFont('Helvetica', 'bold')
    doc.text('Endorsement date:', 121, textline + 99)
    doc.setFont('Helvetica', 'normal')
    doc.text(new Date().toJSON().slice(0, 10), 153, textline + 99)
    doc.rect(120, textline + 95, 30, 5, 'S')

    textline += 10
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 90, 173, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Intermediary details', 85, textline + 93.5)
    doc.setFont('Helvetica', 'bold')
    doc.rect(15, textline + 95, 173, 5, 'S')
    doc.text('Name:', 17.5, textline + 99)
    doc.setFont('Helvetica', 'normal')
    doc.text('SA ARMY FOUNDATION/MAXI FORCES', 38, textline + 99)
    doc.rect(15, textline + 95, 20, 5, 'S')
    doc.setFont('Helvetica', 'bold')
    doc.text('Product:', 103, textline + 99)
    doc.setFont('Helvetica', 'normal')
    doc.text('SA Army Foundation membership', 125, textline + 99)
    doc.rect(100, textline + 95, 21, 5, 'S')

    textline += -70
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('1.  Life Benefit Cover details:', 15, textline + 177)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 180, 173, 5, 'FD')
    doc.setFontSize(9)
    doc.text('Full Name & Surname', 17, textline + 183.5)
    doc.text('ID / DOB', 75, textline + 183.5)
    doc.text('Age', 100, textline + 183.5)
    doc.text('Relationship', 123, textline + 183.5)
    doc.text('Cover Amount', 160, textline + 183.5)
    doc.rect(15, textline + 180, 173, 5, 'S')
    doc.rect(15, textline + 180, 55, 5, 'S')
    doc.rect(95, textline + 180, 15, 5, 'S')
    doc.rect(110, textline + 180, 45, 5, 'S')
    doc.rect(15, textline + 185, 173, 5, 'S')
    doc.rect(15, textline + 185, 55, 5, 'S')
    doc.rect(95, textline + 185, 15, 5, 'S')
    doc.rect(110, textline + 185, 45, 5, 'S')
    doc.rect(15, textline + 190, 173, 5, 'S')
    doc.rect(15, textline + 190, 55, 5, 'S')
    doc.rect(95, textline + 190, 15, 5, 'S')
    doc.rect(110, textline + 190, 45, 5, 'S')
    doc.rect(15, textline + 195, 173, 5, 'S')
    doc.rect(15, textline + 195, 55, 5, 'S')
    doc.rect(95, textline + 195, 15, 5, 'S')
    doc.rect(110, textline + 195, 45, 5, 'S')

    doc.setFont('Helvetica', 'normal')
    doc.text(fullNames + ' ' + surname, 17, textline + 189)
    doc.text(IDNumber, 71, textline + 189)
    doc.text(`${mainAge}`, 101, textline + 189)
    doc.text('Main insured', 123, textline + 189)
    doc.text('R40 000', 165, textline + 189)
    doc.text(spouseFullNames ? spouseFullNames + ' ' + spouseSurname : '', 17, textline + 194)
    doc.text(spouseID ? spouseID : '', 71, textline + 194)
    doc.text(spouseAge ? `${spouseAge}` : '', 101, textline + 194)
    doc.text('Spouse', 125, textline + 194)
    doc.text(spouseID ? 'R40 000' : '-', spouseID ? 165 : 170, textline + 194)
    doc.text('', 17, textline + 199)
    doc.text('', 71, textline + 199)
    doc.text('', 101, textline + 199)
    doc.text('Additional Spouse', 120, textline + 199)
    doc.text('-', 170, textline + 199)

    textline += 30
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('2(A).  Accidental Death Benefit Cover details:', 15, textline + 177)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 180, 173, 5, 'FD')
    doc.setFontSize(9)
    doc.text('Full Name & Surname', 17, textline + 183.5)
    doc.text('ID / DOB', 75, textline + 183.5)
    doc.text('Age', 100, textline + 183.5)
    doc.text('Relationship', 123, textline + 183.5)
    doc.text('Cover Amount', 160, textline + 183.5)
    doc.rect(15, textline + 180, 173, 5, 'S')
    doc.rect(15, textline + 180, 55, 5, 'S')
    doc.rect(95, textline + 180, 15, 5, 'S')
    doc.rect(110, textline + 180, 45, 5, 'S')
    doc.rect(15, textline + 185, 173, 5, 'S')
    doc.rect(15, textline + 185, 55, 5, 'S')
    doc.rect(95, textline + 185, 15, 5, 'S')
    doc.rect(110, textline + 185, 45, 5, 'S')
    doc.setFont('Helvetica', 'normal')
    doc.text(fullNames + ' ' + surname, 17, textline + 189)
    doc.text(IDNumber, 71, textline + 189)
    doc.text(`${mainAge}`, 101, textline + 189)
    doc.text('Main insured', 123, textline + 189)
    doc.text('R40 000', 165, textline + 189)

    textline += 20
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('2(B).  Commuter Death Benefit Cover details:', 15, textline + 177)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 180, 173, 5, 'FD')
    doc.setFontSize(9)
    doc.text('Full Name & Surname', 17, textline + 183.5)
    doc.text('ID / DOB', 75, textline + 183.5)
    doc.text('Age', 100, textline + 183.5)
    doc.text('Relationship', 123, textline + 183.5)
    doc.text('Cover Amount', 160, textline + 183.5)
    doc.rect(15, textline + 180, 173, 5, 'S')
    doc.rect(15, textline + 180, 55, 5, 'S')
    doc.rect(95, textline + 180, 15, 5, 'S')
    doc.rect(110, textline + 180, 45, 5, 'S')
    doc.rect(15, textline + 185, 173, 5, 'S')
    doc.rect(15, textline + 185, 55, 5, 'S')
    doc.rect(95, textline + 185, 15, 5, 'S')
    doc.rect(110, textline + 185, 45, 5, 'S')
    doc.setFont('Helvetica', 'normal')
    doc.text(fullNames + ' ' + surname, 17, textline + 189)
    doc.text(IDNumber, 71, textline + 189)
    doc.text(`${mainAge}`, 101, textline + 189)
    doc.text('Main insured', 123, textline + 189)
    doc.text('R30 000', 165, textline + 189)

    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    textline += 20
    doc.text('3.  Funeral Benefit Cover details:', 15, textline + 177)
    doc.setFillColor('#FFEFED')
    doc.rect(15, textline + 180, 173, 5, 'FD')
    doc.setFontSize(9)
    doc.text('Full Name & Surname', 17, textline + 183.5)
    doc.text('ID / DOB', 75, textline + 183.5)
    doc.text('Age', 100, textline + 183.5)
    doc.text('Relationship', 123, textline + 183.5)
    doc.text('Cover Amount', 160, textline + 183.5)
    doc.rect(15, textline + 180, 173, 5, 'S')
    doc.rect(15, textline + 180, 55, 5, 'S')
    doc.rect(95, textline + 180, 15, 5, 'S')
    doc.rect(110, textline + 180, 45, 5, 'S')
    doc.rect(15, textline + 185, 173, 5, 'S')
    doc.rect(15, textline + 185, 55, 5, 'S')
    doc.rect(95, textline + 185, 15, 5, 'S')
    doc.rect(110, textline + 185, 45, 5, 'S')
    doc.rect(15, textline + 190, 173, 5, 'S')
    doc.rect(15, textline + 190, 55, 5, 'S')
    doc.rect(95, textline + 190, 15, 5, 'S')
    doc.rect(110, textline + 190, 45, 5, 'S')
    doc.rect(15, textline + 195, 173, 5, 'S')
    doc.rect(15, textline + 195, 55, 5, 'S')
    doc.rect(95, textline + 195, 15, 5, 'S')
    doc.rect(110, textline + 195, 45, 5, 'S')

    doc.setFont('Helvetica', 'normal')
    doc.text(fullNames + ' ' + surname, 17, textline + 189)
    doc.text(IDNumber, 71, textline + 189)
    doc.text(`${mainAge}`, 101, textline + 189)
    doc.text('Main insured', 123, textline + 189)
    doc.text('R30 000', 165, textline + 189)
    doc.text(spouseFullNames ? spouseFullNames + ' ' + spouseSurname : '', 17, textline + 194)
    doc.text(spouseID ? spouseID : '', 71, textline + 194)
    doc.text(spouseAge ? `${spouseAge}` : '', 101, textline + 194)
    doc.text('Spouse', 125, textline + 194)
    doc.text(spouseID ? 'R30 000' : '-', spouseID ? 165 : 170, textline + 194)
    doc.text('', 17, textline + 199)
    doc.text('', 71, textline + 199)
    doc.text('', 101, textline + 199)
    doc.text('Additional Spouse', 120, textline + 199)
    doc.text('-', 170, textline + 199)

    textline += 200;
    let rectPosition = textline - 5;
    let textPosition = textline - 1;

    if (oldChild.length > 0) {
      doc.rect(110, textline, 45, (oldChild.length * 5), 'S')
      doc.rect(155, textline, 33, (oldChild.length * 5), 'S')
      for (let s = 0; s < oldChild.length; s++) {
        rectPosition = rectPosition + 5;
        textPosition = textPosition + 5;

        doc.rect(15, rectPosition, 95, 5, 'S')
        doc.rect(15, rectPosition, 55, 5, 'S')
        doc.rect(95, rectPosition, 15, 5, 'S')

        doc.text(oldChild[s].childFullNames + ' ' + oldChild[s].childSurname, 17, textPosition)
        doc.text(`${oldChild[s].childID}`, 71, textPosition)
        doc.text(`${oldChild[s].childAge}`, 101, textPosition)

        if (s === oldChild.length - 1) {
          if (oldChild.length >= 2) {
            doc.text('Child 11 - 21', 123, textPosition - 3)
            doc.text('R20 000', 165, textPosition - 3)
          } else {
            doc.text('Child 11 - 21', 123, textPosition)
            doc.text('R20 000', 165, textPosition)
          }
        }
        textline = textPosition + 1;
      }
    } else {
      console.log("here")
      doc.rect(15, textline, 173, 5, 'S')
      doc.rect(15, textline, 55, 5, 'S')
      doc.rect(95, textline, 15, 5, 'S')
      doc.rect(110, textline, 45, 5, 'S')
      doc.text('Child 11 - 21', 123, textline + 4)
      doc.text('-', 170, textline + 4)
      textline += 5
    }

    rectPosition = textline - 5;
    textPosition = textline - 1;

    if (middleChild.length > 0) {
      doc.rect(110, textline, 45, (middleChild.length * 5), 'S')
      doc.rect(155, textline, 33, (middleChild.length * 5), 'S')

      for (let s = 0; s < middleChild.length; s++) {
        rectPosition = rectPosition + 5;
        textPosition = textPosition + 5;

        doc.rect(15, rectPosition, 95, 5, 'S')
        doc.rect(15, rectPosition, 55, 5, 'S')
        doc.rect(95, rectPosition, 15, 5, 'S')

        doc.text(middleChild[s].childFullNames + ' ' + middleChild[s].childSurname, 17, textPosition)
        doc.text(`${middleChild[s].childID}`, 71, textPosition)
        doc.text(`${middleChild[s].childAge}`, 101, textPosition)

        if (s === middleChild.length - 1) {
          if (middleChild.length >= 2) {
            doc.text('Child 1 - 10', 123, textPosition - 3)
            doc.text('R20 000', 165, textPosition - 3)
          } else {
            doc.text('Child 1 - 10', 123, textPosition)
            doc.text('R20 000', 165, textPosition)
          }
        }

        textline = textPosition + 1;
      }
    } else {
      doc.rect(15, textline, 173, 5, 'S')
      doc.rect(15, textline, 55, 5, 'S')
      doc.rect(95, textline, 15, 5, 'S')
      doc.rect(110, textline, 45, 5, 'S')
      doc.text('Child 1 - 10', 123, textline + 4)
      doc.text('-', 170, textline + 4)
      textline += 5
    }

    rectPosition = textline - 5;
    textPosition = textline - 1;

    if (newBorn.length > 0) {
      doc.rect(110, textline, 45, (newBorn.length * 5), 'S')
      doc.rect(155, textline, 33, (newBorn.length * 5), 'S')

      for (let s = 0; s < newBorn.length; s++) {
        rectPosition = rectPosition + 5;
        textPosition = textPosition + 5;

        doc.rect(15, rectPosition, 95, 5, 'S')
        doc.rect(15, rectPosition, 55, 5, 'S')
        doc.rect(95, rectPosition, 15, 5, 'S')

        doc.text(newBorn[s].childFullNames + ' ' + newBorn[s].childSurname, 17, textPosition)
        doc.text(`${newBorn[s].childID}`, 71, textPosition)
        doc.text(`${newBorn[s].childAge}`, 100, textPosition)

        if (s === newBorn.length - 1) {
          if (newBorn.length >= 2) {
            doc.text('Child Stillborn - 11months', 115, textPosition - 3)
            doc.text('R10 000', 165, textPosition - 3)
          } else {
            doc.text('Child Stillborn - 11months', 115, textPosition)
            doc.text('R10 000', 165, textPosition)
          }
        }

        textline = textPosition + 1;
      }
    } else {
      doc.rect(15, textline, 173, 5, 'S')
      doc.rect(15, textline, 55, 5, 'S')
      doc.rect(95, textline, 15, 5, 'S')
      doc.rect(110, textline, 45, 5, 'S')
      doc.text('Child Stillborn - 11months', 115, textline + 4)
      doc.text('-', 170, textline + 4)
      textline += 5
    }

    rectPosition = textline - 5;
    textPosition = textline - 1;

    if (extendedFinalArray.length > 0) {
      for (let s = 0; s < extendedFinalArray.length; s++) {

        rectPosition = rectPosition + 5;
        textPosition = textPosition + 5;

        doc.rect(15, rectPosition, 173, 5, 'S')
        doc.rect(15, rectPosition, 55, 5, 'S')
        doc.rect(95, rectPosition, 15, 5, 'S')
        doc.rect(110, rectPosition, 45, 5, 'S')

        doc.text(extendedFinalArray[s].name + ' ' + extendedFinalArray[s].relationSurname, 17, textPosition)
        doc.text(`${extendedFinalArray[s].dateOfBirth}`, 71, textPosition)
        doc.text(`${extendedFinalArray[s].age}`, 101, textPosition)
        doc.text(extendedFinalArray[s].relationship, 123, textPosition)
        doc.text(extendedFinalArray[s].benefit, 165, textPosition)
        textline = textPosition + 1;
      }
    }

    textline += -215;
    doc.rect(15, textline + 215, 140, 5, 'S')
    doc.rect(155, textline + 215, 33, 5, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('Total Deduction', 17, textline + 219)
    let totalextendedMonthlyPremium = extendedMonthlyPremium ? extendedMonthlyPremium : 0
    doc.text(`R${monthlyPremium + totalextendedMonthlyPremium}.00`, 165, textline + 219)

    textline += 2
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Waiting periods:', 15, textline + 225)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(8)
    doc.rect(15, textline + 228, 166, 37, 'S')

    textline += -2
    doc.text('1.  Waiting period of 3 months applicable to new cover or new additions to existing cover starting from endorsement date.', 16, textline + 235)
    doc.text('2.  No Waiting periods applicable.', 16, textline + 240)
    doc.text('3.  Waiting period of 6 months applicable to new cover or new additions to existing cover starting from endorsement date.  ', 16, textline + 245)
    doc.text('Self inflicted injury/Suicide: There is a waiting period of twelve (12) months in respect of Policy benefits payable on the happening', 16, textline + 250)
    doc.text('of a Death, resulting from self-inflicted injury or suicide.', 16, textline + 255)
    doc.text('Funeral waiting periods will be waived if you can provide proper evidence of pre-existing funeral cover where the waiting periods', 16, textline + 260)
    doc.text('have been completed.  T&C apply.', 16, textline + 265)

    // doc.addPage()
    textline += 50
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Own dependent children:', 15, textline + 225)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(8)
    doc.rect(15, textline + 228, 166, 12, 'S')
    textline += -2
    doc.text('Only legitimate children of the member under the age of 21 years or 26 years if full time student (proof must be provided), can be', 16, textline + 235)
    doc.text('covered on the funeral benefit portion of the membership as per the latest policy wording.', 16, textline + 240)

    doc.addPage()
    textline = 10
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Beneficiary details:', 15, textline)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(8)
    doc.rect(15, textline + 4, 166, 7, 'S')
    textline += -2
    doc.text('All benefits are paid to the nominated primary beneficiary and in case of predeceased to next-in-line beneficiary.', 16, textline + 10)

    textline += 20
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Commencement of Insurance:', 15, textline)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(8)
    doc.rect(15, textline + 4, 166, 12, 'S')
    textline += -2
    doc.text('Cover will be active from the first day of the month in which Shield Life® Ltd receive the first premium. Premiums are payable', 16, textline + 10)
    doc.text('monthly. A period of thirty (30) days grace is allowed for payment of each premium due and payable.', 16, textline + 15)

    textline += 25
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Expiry of Insurance cover:', 15, textline)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(8)
    doc.rect(15, textline + 4, 166, 7, 'S')
    textline += -2
    doc.text('All benefits and premiums will cease to be payable upon the events as described in the policy wording.', 16, textline + 10)

    textline += -5
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Benefits:', 15, textline + 25)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(8)
    doc.rect(15, textline + 28, 166, 52, 'S')
    textline += -2
    doc.text('The above life insureds will be covered in the event of Death, where applicable.', 16, textline + 35)
    doc.text('In return for payment of a monthly premium, your cover amount will be paid based on the following:', 16, textline + 40)
    doc.text('1. The insured event occurs within the period of cover.', 21, textline + 45)
    doc.text('2. The event giving rise to a claim is covered in terms of the exclusions and/or the terms and conditions of the', 21, textline + 50)
    doc.text(' policy wording.', 21, textline + 55)
    doc.text('3. The truth and accuracy of the information at the time of application.', 21, textline + 60)
    doc.text('4. You provide us with all the required documents as set on the claim form provided; and', 21, textline + 65)
    doc.text('5. The claim is reported within the prescribed periods stipulated in your policy wording.', 21, textline + 70)
    doc.text('The benefit payable will be based on the following:', 16, textline + 75)
    doc.text('1. In the event of Death, the benefit will be payable to the main insured or nominated beneficiary. ', 21, textline + 80)

    doc.addPage()
    doc.setFont('Helvetica', 'normal')
    // Maxi Forces Financial Service (Pty) Ltd  Record of Information
    doc.setFontSize(11)
    doc.setTextColor('red')
    doc.text('The following does not form part of the application form but are information that needs to be', 20, 15)
    doc.text('communicated to new members.', 77, 20)
    doc.setTextColor('#444')
    doc.setFontSize(16)
    doc.setFont('Helvetica', 'bold')
    doc.addImage(maxiforce, "PNG", 15, 20, 25, 20, "maxi", "MEDIUM");
    doc.text('Maxi Forces Financial Service (Pty) Ltd', 55, 30)
    doc.text('Record of Information', 77, 37)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('Only information, and not advice, is provided in respect of the SA Army Foundation Group Scheme policy.', 15, 45)
    doc.text('Should you require advice in any way, please contact the Insurer for assistance 0861 777 353.', 15, 50)
    doc.text('You have chosen to apply for the SA Army Foundation membership that includes the Group Scheme benefits', 15, 55)
    doc.text('underwritten by Shield Life® Ltd as stated in the script.', 15, 60)
    doc.setFontSize(11)
    doc.text('To be completed by main member:', 15, 70)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 75, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('I have selected this plan for the following reasons:', 60, 78.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 80, 166, 20, 'S')
    doc.setFontSize(8)
    doc.text(cgsb, 20, 85)
    doc.text(agsb, 20, 90)
    doc.text(rhcb, 20, 95)
    doc.text(asb, 80, 85)
    doc.text(other, 80, 90)
    doc.text(otherDetails, 90, 90)
    doc.text(agsbwide, 20, 95)
    /*doc.text(rhcbwide, 20, 110)
    doc.text(cgsbwide, 100, 105)
    doc.text(asbwide, 100, 110)
    doc.text(otherwide, 80, 113)
    doc.text(otherDetailswide, 97, 113)*/

    // pre-existing funeral cover
    doc.setFillColor('#FFEFED')
    doc.rect(15, 100, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Pre-existing funeral cover', 75, 103.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 100, 166, 40, 'S')
    doc.text('In order for the Insurer to determine whether a waiting period is applicable, you the potential member', 20, 115)
    doc.text('must confirm the following: Have you recently, more than 31 days before signature date, cancelled a', 20, 120)
    doc.text(`funeral policy with another Insurer?`, 20, 125)
    doc.text(prevCancelation, 20, 130)
    doc.text(prevWaiting, 20, 135)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 135, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    // additional information
    doc.text('Additional Information', 77, 138.5)
    doc.setFont('Helvetica', 'normal')
    doc.setTextColor('#444')
    doc.rect(15, 140, 166, 145, 'S')
    doc.text('Without in any way limiting and subject to the other provisions of the Services Agreement/Mandate,', 20, 145)
    doc.text('Maxi Forces Financial Services (Pty) Ltd accepts responsibility for the lawful actions of their', 20, 150)
    doc.text('representatives (as defined in the Financial Advisory and Intermediary Service Act) in rendering', 20, 155)
    doc.text('financial services within the course and scope of their employment.  All our agents are authorised', 20, 160)
    doc.text('representatives of Maxi Forces Financial Services (Pty) Ltd and some agents may be rendering services', 20, 165)
    doc.text('under supervision and will inform you accordingly.Benefits:The main insured and spouse will be covered', 20, 170)
    doc.text('in the event of Death and Disability, where applicable.', 20, 175)
    doc.text('Children will be covered in the event of Death, where applicable. In return for payment of a monthly or annual', 20, 180)
    doc.text('premium, your cover amount will be paid based on the following:', 20, 185)
    doc.text('#  The insured event occurs within the period of membership;', 20, 190)
    //
    doc.text('# The event giving rise to a claim is covered in terms of the exclusions and/or the terms and conditions', 20, 195)
    doc.text('of the policy wording;#  The truth and accuracy of the information at the time of application;', 20, 200)
    doc.text('# You provide us with all the required documents as set on the claim form provided; and ', 20, 205)
    doc.text('# The claim is reported within the prescribed periods stipulated in your policy wording.', 20, 210)
    //
    doc.text('The benefit payable will be based on the following:#  In the event of Death, due to accidental, violent,', 20, 215)
    doc.text('external and visible means, the benefit will be payable to the nominated beneficiary.', 20, 220)
    doc.text('#  In the event of Death, due to natural causes, after the expiry of the initial waiting period,', 20, 225)
    doc.text('the benefit will be payable to the main member or nominated beneficiary.', 20, 230)
    //
    doc.text('Surrender Value:The Membership and all benefits that is included in the membership has no surrender, loan', 20, 235)
    doc.text('or paid up values.Exclusions:Claims will not be paid if: Any loss or expense of whatsoever nature directly', 20, 240)
    doc.text('caused by, resulting from, or in connection with the willing participation by the Principal Member or and', 20, 245)
    doc.text('Dependant in any of the following against the lawful military or police forces of South Africa: Civil War;', 20, 250)
    doc.text('Revolution; Insurrection; Invasion; Overthrow of the legally constituted government; Military. or usurped', 20, 255)
    doc.text('power and Civil commotion assuming the proportions of, or amounting to, an uprising against an established', 20, 260)
    //
    doc.text('government. This Group Scheme benefits also excludes any loss or expense of whatsoever nature directly caused', 20, 265)
    doc.text('by, resulting from in connection with any of the following:1. Use of nuclear, biological or chemical weapons,', 20, 270)
    doc.text('or any radioactive contamination;2. Attacks on or sabotage of facilities;3. The member committing any act', 20, 275)
    doc.text('or deed in violation of criminal or military law; 4. Martial law or loot, sack or pillage therewith;', 20, 280)
    //
    doc.addPage()
    doc.rect(15, 10, 166, 35, 'S')
    doc.text('5. Confiscation or nationalisation or requisition or destruction of or damage to property by or under the', 20, 15)
    doc.text('order of any government or public or local authority. 6. Intentional self – inflicted injury, suicide or a', 20, 20)
    doc.text('suicide attempt (whether sane or insane) within 12 months from the Entry Date 7. Discontinuance of the', 20, 25)
    doc.text('payment of premiums or part thereof in respect of a Principal Member.', 20, 30)
    doc.text('Full Exclusions available in your Policy Document which will be sent to you.', 20, 35)
    doc.text('Waiting Periods', 20, 40)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 45, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    // additional information
    doc.text('Cover', 50, 48.5)
    doc.text('Waiting Period', 125, 48.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 50, 83, 5, 'S')
    doc.rect(98, 50, 83, 5, 'S')
    doc.rect(15, 55, 83, 10, 'S')
    doc.rect(98, 55, 83, 10, 'S')
    doc.rect(15, 65, 83, 5, 'S')
    doc.rect(98, 65, 83, 5, 'S')
    doc.rect(15, 70, 83, 5, 'S')
    doc.rect(98, 70, 83, 5, 'S')
    // text
    doc.text('Life and Disability Cover:', 20, 54)
    doc.text('Funeral Cover (if no previous', 20, 59)
    doc.text('waiting periods have been completed)', 20, 63)
    doc.text('Accidental Death:', 20, 69)
    doc.text('Commuter Cover: ', 20, 74)
    doc.setFontSize(7)
    doc.text('Natural Causes - 3 months from first successful premium collection', 100, 54)
    doc.text('Natural Causes - 6 months from first successful premium collection;', 100, 59)
    doc.text('Suicide - 12 months from first successful premium collection', 100, 63)
    doc.text('Immediately from first successful premium collection', 100, 69)
    doc.text('Immediately from first successful premium collection', 100, 74)
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 75, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Compliance with the requirements of the FAIS Code of Conduct', 50, 78.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 80, 166, 70, 'S')
    doc.text('I understand that only Individual Group Life and Funeral cover cover is provided.', 20, 85)
    doc.text('I know that the accuracy and completeness of the information provided remains my responsibility, and not', 20, 90)
    doc.text('disclosing the correct information could result in claims not being paid and loss of benefits and premiums.', 20, 95)
    doc.text('I have seen and read a copy of Maxi Forces Financial Services (Pty) Ltd Statutory disclosure.', 20, 100)
    doc.text('I confirm that only information, and not advice, has been provided to me.', 20, 105)
    doc.text('I understand that there is no refund of premiums in the event of cancellation, except when exercising', 20, 110)
    doc.text('the 31 days cooling off right. I have not been coerced or induced in any way in the exercising of any.', 20, 115)
    doc.text('of my rights. I confirm that the application form and/or any other documents (including this document)', 20, 120)
    doc.text('was fully completed before I signed it, and all information is to the best of my knowledge', 20, 125)
    doc.text('both true and correct. I confirm that I have received this record of transaction. I understand', 20, 130)
    doc.text('that if payment of my premium is unsuccessful, my policy benefits are at risk and it is my', 20, 135)
    doc.text("responsibility to notify the Insurer as to how to collect arrear premiums. If I don’t, my", 20, 140)
    doc.text('policy may lapse and I will lose my cover and all premiums. Less than 1% fees is received as remuneration.', 20, 145)
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 150, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Declaration', 87, 153.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 155, 166, 30, 'S')
    // text
    doc.text(`I, ${fullNames} ${surname} declare that the information in respect of the Group Scheme benefits has`, 20, 160)
    doc.text('provided to me, including the cover amounts, waiting periods, premiums and when claims will not be paid.', 20, 165)
    // signature
    doc.addImage(signature, 'JPEG', 25, 160, 35, 35, 'final', 'MEDIUM')
    doc.text('____________________________', 20, 175)
    doc.text('Signature of Main Member', 25, 180)
    doc.text(`Date: ${timeStamp}`, 72, 180)
    doc.addPage()
    //  // Statutory Notice
    // ******************************* doc.addImage(statNoticePage1, 'png',2,2,200,300)
    // ******************************* doc.addPage()
    // ******************************* doc.addImage(statNoticePage2, 'png',2,2,200,250)
    // ******************************* doc.addPage()
    doc.setFontSize(16)
    doc.setFont('Helvetica', 'bold')
    doc.text('Statutory Notice', 80, 15)
    doc.setFontSize(8)
    doc.text('This notice does not form part of the Insurance Contract nor any other document, but you have the right to the following information', 15, 25)
    doc.setFontSize(9)
    doc.text('Intermediary details:', 5, 35)
    doc.setFontSize(8)
    doc.text('Name:', 5, 40)
    doc.text('FSP Number:', 5, 45)
    doc.text('Registration Number:', 5, 50)
    doc.text('Tel:', 5, 55)
    doc.text('Address:', 5, 60)
    doc.text('Website:', 5, 65)

    // doc.setFont('Helvetica', 'normal')
    doc.text('SA Army Foundation', 55, 40)
    doc.text('6995', 55, 45)
    doc.text('056-121-NPO', 55, 50)
    doc.text('(012) 675 9400', 55, 55)
    doc.text('31 Superdrive Avenue, Clubview, 0157', 55, 60)
    doc.text('www.armyfoundation.co.za', 55, 65)

    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Details about other parties involved:', 5, 75)
    doc.text('The Intermediary', 55, 80)
    doc.text('The Insurer', 135, 80)
    doc.setFontSize(8)
    // doc.setFont('Helvetica', 'normal')
    doc.text('Name', 5, 85)
    doc.text('Maxi Forces Financial Services (Pty) Ltd', 55, 85)
    doc.text('Shield Life Ltd', 135, 85)
    doc.text('FSP Number', 5, 90)
    doc.text('1966', 55, 90)
    // doc.text('47477', 135, 90)
    doc.text('Registration Number', 5, 95)
    doc.text('1995/004244/07', 55, 95)
    doc.text('2016/249174/06', 135, 95)
    doc.text('Tel:', 5, 100)
    doc.text('0861 222 303', 55, 100)
    doc.text('0861 777 353', 135, 100)
    doc.text('Address:', 5, 105)
    doc.text('1293 South Street,', 55, 105)
    doc.text('Maxi Building,', 55, 110)
    doc.text('Centurion,', 55, 115)
    doc.text('0157', 55, 120)
    doc.text('1293 South Street,', 135, 105)
    doc.text('Maxi Building,', 135, 110)
    doc.text('Centurion,', 135, 115)
    doc.text('0157', 135, 120)

    let textLine = 125;
    doc.text('Email:', 5, textLine)
    doc.text('info@maxiforces.co.za', 55, textLine)
    doc.text('css@shieldlife.co.za', 135, textLine)
    doc.text('Website:', 5, textLine + 5)
    doc.text('www.creditsolutions.co.za', 55, textLine + 5)
    doc.text('www.shieldlife.co.za', 135, textLine + 5)
    doc.text('FSP Authorised Category I', 5, textLine + 10)
    doc.text('Products:', 5, textLine + 15)
    doc.text('Long-term Insurance: A,B1,B1-A,B2,B2-A', 55, textLine + 10)
    doc.text('Short-term Insurance: Personal Lines (PL), PL A1', 55, textLine + 15)
    doc.text('Long-term Insurance: A,B1,B1-A,B2,B2-A', 135, textLine + 10)
    doc.text('Short-term Insurance: Personal Lines (PL), PL A1', 135, textLine + 15)

    textLine = 145;
    doc.text('Compliance Officer:', 5, textLine)
    doc.text('Moonstone Compliance (Pty) Ltd', 55, textLine)
    doc.text('The Compliance Department can be contacted ', 135, textLine)
    doc.text('represented by Ms Géta Hancke', 55, textLine + 5)
    doc.text('through our website www.shieldlife.co.za or', 135, textLine + 5)
    doc.text('Tel: (021) 883 8000, Fax: (021) 880 0688', 55, textLine + 10)
    doc.text('e - mail compliance@shieldlife.co.za', 135, textLine + 10)
    doc.text('E-mail: ghancke@moonstonecompliance.co.za', 55, textLine + 15)
    doc.text('PO Box 1662, Die Board, Stellenbosch,7673', 55, textLine + 20)

    doc.text('Complaints:', 5, textLine + 25)
    doc.text('Must be in writing and will be attended to within 10', 55, textLine + 25)
    doc.text('business days. We may request additional information,', 55, textLine + 30)
    doc.text('if needed and will keep the complainant up to date of ', 55, textLine + 35)
    doc.text('the progress made. If the complaint is not resolved to', 55, textLine + 40)
    doc.text('the satisfaction of the complainant, they may approach ', 55, textLine + 45)
    doc.text('the FAIS Ombud after expiry of the 6 weeks period. ', 55, textLine + 50)
    doc.text('For further details, our Complaints Management Policy', 55, textLine + 55)
    doc.text('is available upon request: complaints@maxiforces.co.za', 55, textLine + 60)
    doc.text('The Complaints Policy can be contacted through', 135, textLine + 25)
    doc.text('our website www.shieldlife.co.za or e-mail', 135, textLine + 30)
    doc.text('complaints@shieldlife.co.za', 135, textLine + 35)
    doc.text('Legal & contractual relationship:', 5, textLine + 65)
    doc.text('Intermediary Service Agreement', 55, textLine + 65)
    doc.text('Professional Indemnity Insurance:', 5, textLine + 70)
    doc.text('Professional Indemnity Cover is in place.', 55, textLine + 70)
    doc.text('Professional Indemnity Cover & Fidelity', 135, textLine + 70)
    doc.text('Cover is in place.', 135, textLine + 75)
    doc.text('Conflict of Interest:', 5, textLine + 80)
    doc.text('The Conflict of Interest Management Policy can be ', 55, textLine + 80)
    doc.text('obtained upon written request:', 55, textLine + 85)
    doc.text('compliance@maxiforces.co.za', 55, textLine + 90)
    doc.text('The Conflict of Interest Policy can be ', 135, textLine + 80)
    doc.text('obtained upon written request from the', 135, textLine + 85)
    doc.text('compliance officer. ', 135, textLine + 90)
    doc.text('Life Insurer approved class and', 5, textLine + 95)
    doc.text('sub - classes of business:', 5, textLine + 100)
    doc.text('Risk: Individual and Group Death, ', 135, textLine + 95)
    doc.text('Individual and Group Disability-Lump sum', 135, textLine + 100)
    doc.text('Funeral: Individual and Group Credit Life ', 135, textLine + 105)
    doc.text('Claims Procedure:', 5, textLine + 110)
    doc.text('All claims enquiries should be address to:', 135, textLine + 110)
    doc.text('The Claims Department: Shield Life', 135, textLine + 115)
    doc.text('PO Box 15473, Lyttleton, 0140', 135, textLine + 120)
    doc.text('Tel: 0861 277 873', 135, textLine + 125)
    doc.text('Email: claims@shieldlife.co.za', 135, textLine + 130)

    doc.addPage()
    textLine = 15
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'bold')
    doc.text('Other important contact details:', 5, textLine)
    doc.setFontSize(8)
    doc.text('The FAIS Ombud', 5, textLine + 5)
    doc.text('National Financial Ombud Scheme', 100, textLine + 5)
    doc.text('The Authority', 5, textLine + 35)
    doc.text('The Information Regulator (South Africa)', 100, textLine + 35)

    doc.setFont('Helvetica', 'normal')
    doc.text('(For advice/policy related matters):', 5, textLine + 10)
    doc.text('The FAIS Ombud PO Box 74571, Lynnwood Ridge, 0040', 5, textLine + 15)
    doc.text('Tel: 086 066 3247 or Tel (012) 762 5000, Fax: (012) 348 3447', 5, textLine + 20)
    doc.text('E-mail: info@faisombud.co.za, www.falsombud.co.za', 5, textLine + 25)

    doc.text('(For Claims/service related matters):', 100, textLine + 10)
    doc.text('JHB: 110 Oxford Road, Houghton Estate, Illovo, Johannesburg, 2198', 100, textLine + 15)
    doc.text('CPT: Claremont Central Building, 6th Floor, 6 Vineyard Road, Claremont, 7708', 100, textLine + 20)
    doc.text('Tel: 0860 800 900, Email:  info@nfosa.co.za, www.nfosa.co.za ', 100, textLine + 25)

    doc.text('(For market conduct matters):', 5, textLine + 40)
    doc.text('For Complaints on the Insurer that are not resolved to your', 5, textLine + 45)
    doc.text('satisfaction by the product supplier, please contact:', 5, textLine + 50)
    doc.text('The Financial Sector Conduct Authority', 5, textLine + 55)
    doc.text('PO Box 35655, Menlo Park, 0102', 5, textLine + 60)
    doc.text('Tel:(012) 428 8000, Fax:(012) 347 0221, Email:info@fsca.co.za', 5, textLine + 65)

    doc.text('(For POPI matters):', 100, textLine + 40)
    doc.text('PO Box 31533, Braamfontein, Johannesburg, 2017', 100, textLine + 45)
    doc.text('Email: inforeg@justice.gov.za,', 100, textLine + 50)
    doc.text('www.justice.gov.za / inforeg / docs1 - gn.html#gn - paia', 100, textLine + 55)

    // doc.text('(For Claims/service related matters):', 65, 25)
    // doc.text('Ombudsman for Long-term Insurance', 65, 30)
    // doc.text('Claremont Central Building, 6th Floor,', 65, 35)
    // doc.text('6 Vineyard Road, Claremont, 7700', 65, 40)
    // doc.text('Tel: 0860 103 236 or (021) 657 5000', 65, 45)
    // doc.text('Fax: (021) 674 0951', 65, 50)
    // doc.text('E-mail: info@ombud.co.za', 65, 55)
    // doc.text('www.ombud.co.za', 65, 60)

    textLine = 15;
    // important information
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('Important information:', 5, textLine + 75)
    doc.setFontSize(8)
    doc.text('Premium obligations:', 5, textLine + 85)

    textLine = 20;
    doc.text('Premium payments:', 5, textLine + 90)
    doc.text('Commencment of insurance:', 5, textLine + 100)
    textLine = 30;
    doc.text('Expiry of insurance cover:', 5, textLine + 105)
    doc.text('Premium & Benefit increase:', 5, textLine + 110)
    doc.text('Incorrect information provided:', 5, textLine + 115)
    doc.text('Remuneration:', 5, textLine + 125)
    doc.text('Cooling off rights:', 5, textLine + 130)
    doc.text('Insurance Contract understanding:', 5, textLine + 145)
    textLine = 45;
    doc.text('General', 5, textLine + 155)

    doc.setFont('Helvetica', 'normal')
    textLine = 15;
    doc.text('The obligations that you assume as premium prayer is indicated in the insurance Contract.', 70, textLine + 85)
    doc.text('Ensure that you are fully aware of these obligations.', 70, textLine + 90)
    textLine = 20;
    doc.text('The due date of premiums and consequences of non-payment is set out in the conditions of the', 70, textLine + 90)
    doc.text('insurance Contract.', 70, textLine + 95)
    doc.text('Cover will be active from the first day of the month in which we receive the first premium.', 70, textLine + 100)
    doc.text('Premiums are payable monthly or annually.  A period of thirty (30) days grace is allowed for', 70, textLine + 105)
    doc.text('payment of each premium due and payable.', 70, textLine + 110)

    textLine = 30;
    doc.text('All benefits and premiums will cease to be payable upon the events as described in the policy wording.', 70, textLine + 105)
    doc.text('Premium and benefits are annually reviewable.', 70, textLine + 110)
    doc.text('Decisions made by Shield Life® Ltd as the insurer, respect of insurance or claim, may be influenced', 70, textLine + 115)
    doc.text('by the failure to provide correct or full information by the insured', 70, textLine + 120)
    doc.text('Commission will not exceed the statutory commission as defined by the Life Insurance Act.', 70, textLine + 125)
    doc.text('Cooling off rights may be exercised within 31 days after inception of the membership, where no', 70, textLine + 130)
    doc.text('benefit has been paid or claimed. A written cancellation form must be completed and send to', 70, textLine + 135)
    doc.text('Shield Life® Ltd.  Cooling off rights can not be exercised if other legislation prohibit it.', 70, textLine + 140)
    doc.text('Ensure you understand what benefits and exclusions are applicable to your Insurance Contract. ', 70, textLine + 145)
    doc.text('Make sure you are acquainted with:', 70, textLine + 150)
    doc.text('1. what is not covered,', 70, textLine + 155)
    doc.text('2. what is restricted and', 70, textLine + 160)
    doc.text('3. If there are any special clauses.', 70, textLine + 165)

    textLine = 45;
    doc.text('You will be informed of any material changes to the above mentioned information.This disclosure notice', 70, textLine + 155)
    doc.text('serves to provide you with the information in writing if any information was given to you orally.', 70, textLine + 160)
    doc.text('The reason for your claim to be rejected will always be communicated to you in writing. In case of the', 70, textLine + 165)
    doc.text('insurer cancelling your policy, this will be done in writing to your last known address or per SMS.', 70, textLine + 170)
    doc.text('You will always be entitled to a copy of your policy free of charge.', 70, textLine + 175)
    doc.text('For instructions to access,verify, or object to personal information processed by Shield Life,', 70, textLine + 180)
    doc.text('please view our privacy notice on www.shieldlife.co.za. Should any of my dependants or', 70, textLine + 185)
    doc.text('I have any concerns about the processing of our personal information, we may raise the matter', 70, textLine + 190)
    doc.text(`with Shield Life's Information Officer at privacy@shieldlife.co.za`, 70, textLine + 195)

    // doc.addPage()
    textLine = 30;
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(16)
    doc.text('Treating Custormers Fairly (TCF) Principles', 40, textLine + 220)
    doc.setFontSize(9)
    doc.text('Shield Life Ltd subscribe to the following 6 TCF principles during product creation to meet your requirements and expectation:', 5, textLine + 225)
    textLine = 30;
    doc.text('Culture', 5, textLine + 235)
    doc.text('Right Product', 5, textLine + 240)
    doc.text('Informed', 5, textLine + 245)
    doc.text('Suitable Advice', 5, textLine + 250)
    doc.text('Meet Expectations', 5, textLine + 255)
    doc.text('Excellent Service', 5, textLine + 260)
    doc.setFont('Helvetica', 'normal')
    doc.text('Consumers are confident that fair treatment is central to our Company culture', 40, textLine + 235)
    doc.text('Consumers have Products & Services that meet their needs', 40, textLine + 240)
    doc.text('Consumers received communication that is clear and in understandable language', 40, textLine + 245)
    doc.text('Consumers receive suitable advice aimed at their circumstances', 40, textLine + 250)
    doc.text('Consumers receive Products & Services that will meet their expectations', 40, textLine + 255)
    doc.text('Consumers expierance no post-sale barriers', 40, textLine + 260)

    doc.addPage()
    // Army Foundation Membership Information
    doc.setFontSize(16)
    doc.setFont('Helvetica', 'bold')
    doc.addImage(SAArmyLogo, "PNG", 15, 10, 15, 15, "saarmy", "MEDIUM");
    doc.text('Army Foundation Membership Information', 40, 15)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(7)
    doc.text('We would like to take this opportunity to give you an update on all the benefits that are currently available to you as a member.', 35, 20)
    doc.setFontSize(9)
    doc.setTextColor('red')
    doc.setFont('Helvetica', 'bold')
    doc.text('For each of the below Benefits and Products, Terms and Conditions are applicable', 35, 25)
    doc.setFontSize(9)
    doc.setTextColor('#444')
    doc.setFillColor('#FFEFED')
    doc.rect(15, 30, 166, 10, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Death, Disability, Accidental and Funeral cover', 20, 33.5)
    doc.text('underwritten by Shield Life® Ltd', 30, 38.5)
    doc.text('Rainbow Holiday Club', 123, 33.5)
    doc.text('administrated by Club Leisure', 115, 38.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 30, 83, 65)
    doc.rect(98, 30, 83, 65)
    // text left col
    doc.setFontSize(7)
    doc.text('You are covered for up to R110 000 for the main member and', 18, 45)
    doc.text('up to R70 000 for your spouse in the event of death.', 18, 50)
    doc.text('Main Member and Spouse cover includes: Death and disability cover of', 18, 55)
    doc.text('R40 000 and Funeral Cover of R30 000.', 18, 60)
    doc.text('(* Additional Commuter benefit of R30 000 for Main member).', 18, 65)
    doc.text('Accidental Death cover for the main Member of R40 000. Funeral', 18, 70)
    doc.text('Cover for Children R10 000 – R20 000 age dependent.*If a claim was', 18, 75)
    doc.text('received and approved for disability, the Life Cover will cease', 18, 80)
    doc.text('for the Claimant of the Disability.', 18, 85)
    doc.text('The above amounts is Membership type dependent', 18, 90)

    //text right col
    doc.text('The Club provides members with a unique monthly savings opportunity', 100, 45)
    doc.text('to ensure that members have enough funds available when they want', 100, 50)
    doc.text("to go on holiday. The member' monthly *savings accumulates in", 100, 55)
    doc.text('their own Vacation Fund until they are ready to go on holiday.', 100, 60)
    doc.text('The Club provides 3 options:Silver: R121.00 savings per month ', 100, 65)
    doc.text('Gold: R181.50 savings per month	Platinum: R302.51 savings per', 100, 70)
    doc.text('month As a member of Army Foundation, you can apply for membership', 100, 75)
    doc.text('at your nearest SA Army Foundation branch.', 100, 80)
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 95, 166, 10, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Funeral Service Benefit Plan', 35, 98.5)
    doc.text('in partnership with AVBOB Funeral Services', 20, 103.5)
    doc.text('SA Army Foundation Resorts', 120, 100.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 105, 83, 65)
    doc.rect(98, 105, 83, 65)
    // text left col
    doc.text('You have the following benefits:', 18, 110)
    doc.text('# Preferential prices for funeral products & services', 18, 115)
    doc.text('# Customised comprehensive AVBOB Funeral Service', 18, 120)
    doc.text('Offering.', 18, 125)
    doc.text('# Transport of deceased within SA borders', 18, 130)
    doc.text('# Assistance & support with funeral arrangements &', 18, 135)
    doc.text('funeral conducting', 18, 140)
    doc.text('# Free telephonic bereavement counselling', 18, 145)
    doc.text('# After hours service', 18, 150)
    doc.text('* T&C Apply', 18, 155)
    // text right col
    doc.text('You have all year access to discounted rates', 100, 110)
    doc.text('on holidays at the following Army Foundation.', 100, 115)
    doc.text('resorts', 100, 120)
    doc.text('# Kruger Park Lodge - Hazyview', 100, 125)
    doc.text('# Santana Resort – Margate (KZN)', 100, 130)
    doc.text('# Flamboyant – ST Lucia (KZN)', 100, 135)
    doc.text('# Rhemardo - Mokgopong', 100, 140)
    doc.text('* T&C Apply', 100, 145)
    // next row
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 160, 166, 10, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('iNkomo Funeral cover', 35, 164)
    doc.text('underwritten by Assupol Life', 30, 169)
    doc.text('Housing', 132, 166)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 170, 83, 25)
    doc.rect(98, 170, 83, 25)
    doc.text('This product provides a unique form of funeral', 18, 175)
    doc.text('cover for a member, spouse, children, and extended', 18, 180)
    doc.text('family which includes: Cover for iNkomo, Tombstone', 18, 185)
    doc.text('Vehicle access benefits, Assupol On-Call services ', 18, 190)

    doc.text('As a member of Army Foundation, you can apply', 100, 175)
    doc.text('for a rental property at a discounted price', 100, 180)
    doc.text('in Clubview, Centurion.	', 100, 185)
    doc.text('* T&C Apply', 100, 190)
    // next row
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 200, 166, 10, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('My Extended Family Benefit', 35, 203)
    doc.text('Funeral cover underwritten by Shield Life® Ltd', 20, 208)
    doc.text('Financial Assistance', 125, 206)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 210, 83, 40)
    doc.rect(98, 210, 83, 40)
    doc.text('Available to existing SA Army Foundation Members.', 18, 215)
    doc.text('You can add up to 8 Additional Family Members', 18, 220)
    doc.text('such as Uncles and Aunts on this secondary benefit.', 18, 225)
    doc.text('Now available on your current SA Army Foundation Plan.', 18, 230)
    doc.text('Up to R30 000 in cover available per Family Member', 18, 235)
    doc.text('No waiting periods for Accidental Death', 18, 240)

    doc.text('* T&C Apply as per mobile criteria', 18, 245)
    doc.text('You may qualify for payday loans up to', 105, 215)
    doc.text('R3 000 and personal loans up to R27 000.', 105, 220)
    // doc.text('You can also get access to your personal', 105, 225)
    // doc.text('credit report.', 105, 230)
    // doc.text('* T&C Apply as per loan criteria', 105, 235)
    // next row
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 241, 166, 10, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Gratuity at Retirement', 40, 248)
    doc.text('SA Army Foundation Memorial Plaques', 110, 248)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 251, 83, 20)
    doc.rect(98, 251, 83, 20)
    doc.text('Available to employees of the DOD and', 18, 255)
    doc.text('contributing member for at least 10 years.', 18, 260)
    doc.text('* T&C Apply ', 18, 265)
    doc.text('Available to the next of kin for those', 105, 255)
    doc.text('members who have passed during their term.', 105, 260)
    doc.text('of service.', 105, 265)
    doc.text('* T&C Apply', 105, 270)
    doc.addPage()
    doc.setFontSize(9)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 15, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Important notice', 87, 18.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 20, 166, 10)
    doc.text('When membership is cancelled, the Extended Family Benefit and/or Rainbow Savings is automatically', 20, 24)
    doc.text('cancelled due to it being rider benefits of the membership.', 60, 29)
    doc.setFillColor('#FFEFED')
    doc.rect(15, 30, 166, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(9)
    doc.text('Declaration', 90, 33.5)
    doc.setFont('Helvetica', 'normal')
    doc.rect(15, 35, 166, 35)
    doc.text(`I ${fullNames} ${surname}, the undersigned, have received and understand the information of the above products`, 17, 40)
    doc.text('that forms part of the SA Army Foundation Membership', 17, 45)
    // signature
    doc.addImage(signature, 'JPEG', 25, 40, 35, 35, 'final', 'MEDIUM')
    doc.text('____________________________', 20, 60)
    doc.text('Signature of Main Member', 25, 65)
    doc.text(`Date: ${timeStamp}`, 72, 65)

    doc.addPage()
    // YOUR POLICY
    textLine = 15;
    doc.setFontSize(16)
    doc.setFont('Helvetica', 'bold')
    doc.text('YOUR POLICY', 85, textLine)
    doc.setFontSize(11)
    doc.text('YOUR RESPONSIBILITIES', 5, textLine + 10)

    doc.setFontSize(9)
    doc.text('1.  Provide us with accurate requested information.', 5, textLine + 20)
    doc.setFont('Helvetica', 'normal')
    doc.text('If you do not provide accurate and comprehensive information, we may not be able to communicate with you or your claim may be', 10, textLine + 25)
    doc.text('negatively affected.', 10, textLine + 30)

    textLine = 10;
    doc.setFont('Helvetica', 'bold')
    doc.text('2.  Inform us of any changes to your contact details.', 5, textLine + 40)
    doc.setFont('Helvetica', 'normal')
    doc.text('If you change your telephone number, email, or contact address, you need to update your Policy records with the new contact', 10, textLine + 45)
    doc.text('details to ensure  that we can contact you to communicate important information.', 10, textLine + 50)

    textLine = 5;
    doc.setFont('Helvetica', 'bold')
    doc.text('3.  Inform us of any changes in your circumstances that may necessitate changes to your Policy.', 5, textLine + 60)
    doc.setFont('Helvetica', 'normal')
    doc.text(`Personal situations are rarely static, and obligations may come into or go out of your life. When this occurs, it's crucial to think about`, 10, textLine + 65)
    doc.text('how it will affect your cover. New people and responsibilities may enter your life and others may exit your life, when this happens,', 10, textLine + 70)
    doc.text('it is important to consider the impact on your Policy.', 10, textLine + 75)

    textLine = 0;
    doc.setFont('Helvetica', 'bold')
    doc.text('4.  Pay your premiums.', 5, textLine + 85)
    doc.setFont('Helvetica', 'normal')
    doc.text('Paying your premium, keeps your Policy active.', 10, textLine + 90)

    textLine = -5;
    doc.setFont('Helvetica', 'bold')
    doc.text('5.  Read your Policy.', 5, textLine + 100)
    doc.setFont('Helvetica', 'normal')
    doc.text('This Policy, in conjunction with your Application Form, Certificate of Cover and endorsements thereto, constitutes the entire agreement', 10, textLine + 105)
    doc.text('and any alteration thereto shall be in the form of an endorsement. Reading your Policy helps you verify that the Policy meets your', 10, textLine + 110)
    doc.text('needs and that you understand yours and our responsibilities.', 10, textLine + 115)

    textLine = 120;
    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('DEFINITIONS', 5, textLine)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    textLine = 120;
    doc.text('We are including definitions to assist you with the interpretation of your Policy contract.', 5, textLine + 10)
    doc.text('1.  The headings of the clauses in this policy are for the purpose of convenience and reference only and shall not be used in the', 5, textLine + 15)
    doc.text('interpretation of, nor to modify nor amplify, the terms of this policy nor any clause thereof. In this policy, unless the contrary', 10, textLine + 20)
    doc.text('intention clearly appears, word importing: ', 10, textLine + 25)
    doc.text('a) Any one gender, include the other gender.', 10, textLine + 30)
    doc.text('b) The singular, include the plural and vice versa.', 10, textLine + 35)
    doc.text('2.  Schedules, annexures, or appendices to this agreement shall be deemed to be incorporated in and form part of this agreement.', 5, textLine + 40)
    doc.text('3.  Unless inconsistent with the context, the words below shall bear the following meanings:', 5, textLine + 45)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 50, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('TERM', 13, textLine + 53.5)
    doc.text('DEFINITION', 73, textLine + 53.5)

    doc.rect(10, textLine + 55, 180, 5, 'S')
    doc.rect(10, textLine + 55, 60, 5, 'S')
    doc.rect(10, textLine + 55, 60, 5, 'S')
    doc.rect(10, textLine + 60, 180, 45, 'S')
    doc.rect(10, textLine + 60, 60, 45, 'S')
    doc.rect(10, textLine + 105, 180, 60, 'S')
    doc.rect(10, textLine + 105, 60, 60, 'S')

    doc.text('“Accelerated Disability Benefit”', 12, textLine + 59)
    doc.text('“Accelerated Lump Sum - Military', 12, textLine + 64)
    doc.text('Disability”', 12, textLine + 69)
    doc.text('“Accelerated Lump Sum - Total', 12, textLine + 109)
    doc.text('and Permanent Disability”', 12, textLine + 114)

    doc.setFont('Helvetica', 'normal')
    doc.text('shall be defined as the lump sum of the Life benefit payable before death.', 72, textLine + 59)
    doc.text('the continuous, total, and permanent inability of the Principal Life Insured, due to', 72, textLine + 64)
    doc.text('injury, disease, or illness to perform the material and substantial duties of any', 72, textLine + 69)
    doc.text('occupation in the South African National Defence Force for which he is qualified', 72, textLine + 74)
    doc.text('or suited or could reasonably be expected to become qualified or suited taking', 72, textLine + 79)
    doc.text('into account his knowledge, training, education, ability, age and experience. A', 72, textLine + 84)
    doc.text('Principal Life Insured will however only be Military Disabled if the disability results', 72, textLine + 89)
    doc.text('in the Insured experiencing a loss of income and is declared Medically Unfit by the', 72, textLine + 94)
    doc.text('South African National Defence Force’s Medical Examination / Medical Board, and', 72, textLine + 99)
    doc.text('the Insurer is satisfied that the disability will remain total and permanent.', 72, textLine + 104)

    doc.text('the continuous, total, and permanent inability of the Principal Life Insured and/or', 72, textLine + 109)
    doc.text('Spouse of the Principal Life Insured, due to injury, disease, or illness to perform', 72, textLine + 114)
    doc.text('the material and substantial duties of any occupation in the open labour market', 72, textLine + 119)
    doc.text('for which he is qualified or suited or could reasonably be expected to become', 72, textLine + 124)
    doc.text('qualified or suited taking into account his knowledge, training, education, ability,', 72, textLine + 129)
    doc.text('age and experience. “Totally and permanently disabled” shall have a corresponding', 72, textLine + 134)
    doc.text('meaning. A Spouse of the Principal Life Insured will however only be Totally', 72, textLine + 139)
    doc.text('and Permanently Disabled if the disability results in the Spouse of the Principal', 72, textLine + 144)
    doc.text('Life Insured experiencing a loss of income and the Insurer is satisfied that', 72, textLine + 149)
    doc.text('the disability will remain total and permanent. The total and permanent disability', 72, textLine + 154)
    doc.text('benefit is paid after a waiting period of 6 months – to give a reasonable time', 72, textLine + 159)
    doc.text('period to assess for total and permanent disability.', 72, textLine + 164)

    doc.addPage();
    textLine = 5;
    doc.rect(10, textLine, 180, 15, 'S')
    doc.rect(10, textLine, 60, 15, 'S')
    doc.rect(10, textLine, 60, 15, 'S')
    doc.rect(10, textLine + 15, 180, 25, 'S')
    doc.rect(10, textLine + 15, 60, 25, 'S')
    doc.rect(10, textLine + 35, 180, 5, 'S')
    doc.rect(10, textLine + 35, 60, 5, 'S')
    doc.rect(10, textLine + 40, 180, 5, 'S')
    doc.rect(10, textLine + 40, 60, 5, 'S')
    doc.rect(10, textLine + 45, 180, 15, 'S')
    doc.rect(10, textLine + 45, 60, 15, 'S')
    doc.rect(10, textLine + 60, 180, 30, 'S')
    doc.rect(10, textLine + 60, 60, 30, 'S')
    doc.rect(10, textLine + 90, 180, 10, 'S')
    doc.rect(10, textLine + 90, 60, 10, 'S')
    doc.rect(10, textLine + 100, 180, 5, 'S')
    doc.rect(10, textLine + 100, 60, 5, 'S')
    doc.rect(10, textLine + 105, 180, 10, 'S')
    doc.rect(10, textLine + 105, 60, 10, 'S')
    doc.rect(10, textLine + 115, 180, 10, 'S')
    doc.rect(10, textLine + 115, 60, 10, 'S')
    doc.rect(10, textLine + 125, 180, 45, 'S')
    doc.rect(10, textLine + 125, 60, 45, 'S')
    doc.rect(10, textLine + 170, 180, 10, 'S')
    doc.rect(10, textLine + 170, 60, 10, 'S')
    doc.rect(10, textLine + 180, 180, 40, 'S')
    doc.rect(10, textLine + 180, 60, 40, 'S')
    doc.rect(10, textLine + 200, 180, 10, 'S')
    doc.rect(10, textLine + 200, 60, 10, 'S')
    doc.rect(10, textLine + 210, 180, 10, 'S')
    doc.rect(10, textLine + 210, 60, 10, 'S')
    doc.rect(10, textLine + 220, 180, 25, 'S')
    doc.rect(10, textLine + 220, 60, 25, 'S')
    doc.rect(10, textLine + 245, 180, 20, 'S')
    doc.rect(10, textLine + 245, 60, 20, 'S')
    doc.rect(10, textLine + 265, 180, 10, 'S')
    doc.rect(10, textLine + 265, 60, 10, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('“Accident”', 12, textLine + 4)
    doc.text('“Administrative Payroll restrictions"', 12, textLine + 19)
    doc.text('“Adoptive Parent”', 12, textLine + 39)
    doc.text('“Age”', 12, textLine + 44)
    doc.text('“Agreement/Contract/Policy”', 12, textLine + 49)
    doc.text('“Beneficiary”', 12, textLine + 64)
    doc.text('“Benefit”', 12, textLine + 94)
    doc.text('“Business day”', 12, textLine + 104)
    doc.text('“Claim”', 12, textLine + 109)
    doc.text('“Claimant”', 12, textLine + 119)
    doc.text('“Commuter Cover”', 12, textLine + 129)
    doc.text('“Continuation of Cover”', 12, textLine + 174)
    doc.text('“Date of Disability”', 12, textLine + 184)
    doc.text('“Disability Benefit Cessation age', 12, textLine + 204)
    doc.text('“Disability Event”', 12, textLine + 214)
    doc.text('“Dishonesty”', 12, textLine + 224)
    doc.text('“Effective Date”', 12, textLine + 249)
    doc.text('“Entry Age”', 12, textLine + 269)

    doc.setFont('Helvetica', 'normal')
    doc.text('a sudden, uncertain, and unexpected event which is caused solely and directly', 72, textLine + 4)
    doc.text('by Violent, External, Physical and Visible means and independently of any other', 72, textLine + 9)
    doc.text('causes resulting in the Death of the Insured within 30 days of the accidental injury.', 72, textLine + 14)
    doc.text('any restrictions by the employer on the Policyholder’s deductions that prevents', 72, textLine + 19)
    doc.text('any annual increases to take effect, usually pay point code 0020 or any other', 72, textLine + 24)
    doc.text('limitations on maximum premium deductions allowed, resulting in a premium', 72, textLine + 29)
    doc.text('and cover rate lower than the current premium and cover.', 72, textLine + 34)
    doc.text('means any person who has legally adopted you or your Spouse.', 72, textLine + 39)
    doc.text('age next birthday when determining premium at Entry Date.', 72, textLine + 44)
    doc.text('means this Policy document and its annexures setting out the terms and', 72, textLine + 49)
    doc.text('conditions subject to which the Insurer is willing to provide Benefits in respect of', 72, textLine + 54)
    doc.text('the Insured Lives.', 72, textLine + 59)
    doc.text('(i) shall be defined as; a person nominated by the Policyholder as the person', 72, textLine + 64)
    doc.text('in respect of whom the Insurer should meet policy benefits.', 72, textLine + 69)
    doc.text('(ii) a legal entity that is designated by the Policyholder to receive a benefit.', 72, textLine + 74)
    doc.text('(iii) a trust fund or guardian fund, where Beneficiaries are under the age of 18', 72, textLine + 79)
    doc.text('or lack the mental capacity to manage the lump sum payable under this', 72, textLine + 84)
    doc.text('policy benefits.', 72, textLine + 89)
    doc.text('the Benefit payable in terms of this policy as specified in the Certificate of Cover', 72, textLine + 94)
    doc.text('may not exceed the maximum amounts as prescribed by the Regulator.', 72, textLine + 99)
    doc.text('means any day excluding a Saturday, Sunday, or public holiday.', 72, textLine + 104)
    doc.text('shall be defined as, a demand for Policy benefits by a person in respect of a', 72, textLine + 109)
    doc.text('Policy benefit, irrespective of whether the person’s demand is valid.', 72, textLine + 114)
    doc.text('shall be defined as, the person who files a claim for benefits under the', 72, textLine + 119)
    doc.text('provisions of this Policy.', 72, textLine + 124)
    doc.text('The funeral benefit payable should the policyholder die as a non-professional', 72, textLine + 129)
    doc.text('driver, passenger or pedestrian as result of a Road Traffic Accident, whilst on', 72, textLine + 134)
    doc.text('duty. Cover will only be provided whilst the policyholder travels the most direct', 72, textLine + 139)
    doc.text('routes to and from work to the policyholder residence as well as routes travelled', 72, textLine + 144)
    doc.text('in consequence of company sanctioned events such as Sports, Memorial', 72, textLine + 149)
    doc.text('Services and Funerals will be included. Cover will include all modes of transport.', 72, textLine + 154)
    doc.text('In addition to the funeral claims documentation required a SAPS Accident', 72, textLine + 159)
    doc.text('Report as well as a letter from the Policyholder’s Commanding Officer', 72, textLine + 164)
    doc.text('confirming the purpose of travel and residence to work route will be required.', 72, textLine + 169)
    doc.text('the option to continue cover under this Policy in the event of Death of the', 72, textLine + 174)
    doc.text('Principal Life Insured.', 72, textLine + 179)
    doc.text('as determined by the Insurer and shall mean the date on which the Principal Life', 72, textLine + 184)
    doc.text('Insured and/or the Spouse of the Principal Life Insured became disabled based', 72, textLine + 189)
    doc.text('on objective medical or professional evidence, consistent with the definition', 72, textLine + 194)
    doc.text('assigned to disability in this policy.', 72, textLine + 199)
    doc.text('shall be defined as, attainment of benefit cessation age (60) and / or normal', 72, textLine + 204)
    doc.text('retirement.', 72, textLine + 209)
    doc.text('means the event of the functional ability of the mind or body of a person', 72, textLine + 214)
    doc.text('becoming impaired.', 72, textLine + 219)
    textLine += -10
    doc.text('any Conduct on the part of the Policyholder or claimant which is deceitful or', 72, textLine + 234)
    doc.text('fraudulent and which has resulted in a monetary loss to the Insurer or could', 72, textLine + 239)
    doc.text('have resulted in such loss if not discovered prior to the commission of the', 72, textLine + 244)
    doc.text('conduct. Thus, an attempt to commit an act or deceitfulness or fraud will be', 72, textLine + 249)
    doc.text('constituted as dishonesty.', 72, textLine + 254)
    doc.text('(i) date when the Insurer first became obligated for any insurance risk via the', 72, textLine + 259)
    doc.text('issuance of policies,', 72, textLine + 264)
    doc.text('(ii) date on which the policy takes effect, and / or', 72, textLine + 269)
    doc.text('(iii) the first day (1st) of the month in which premium is due.', 72, textLine + 274)
    doc.text('the life Insured’s actual age on the signature date which is used to calculate the', 72, textLine + 279)
    doc.text('premium and related benefit.', 72, textLine + 284)

    doc.addPage();
    textLine = 5;
    doc.rect(10, textLine, 180, 10, 'S')
    doc.rect(10, textLine, 60, 10, 'S')
    doc.rect(10, textLine, 60, 10, 'S')
    doc.rect(10, textLine + 10, 180, 15, 'S')
    doc.rect(10, textLine + 10, 60, 15, 'S')
    doc.rect(10, textLine + 25, 180, 10, 'S')
    doc.rect(10, textLine + 25, 60, 10, 'S')
    doc.rect(10, textLine + 35, 180, 20, 'S')
    doc.rect(10, textLine + 35, 60, 20, 'S')
    doc.rect(10, textLine + 55, 180, 30, 'S')
    doc.rect(10, textLine + 55, 60, 30, 'S')
    doc.rect(10, textLine + 85, 180, 30, 'S')
    doc.rect(10, textLine + 85, 60, 30, 'S')
    doc.rect(10, textLine + 115, 180, 10, 'S')
    doc.rect(10, textLine + 115, 60, 10, 'S')
    doc.rect(10, textLine + 125, 180, 40, 'S')
    doc.rect(10, textLine + 125, 60, 40, 'S')
    doc.rect(10, textLine + 165, 180, 25, 'S')
    doc.rect(10, textLine + 165, 60, 25, 'S')
    doc.rect(10, textLine + 190, 180, 45, 'S')
    doc.rect(10, textLine + 190, 60, 45, 'S')
    doc.rect(10, textLine + 235, 180, 10, 'S')
    doc.rect(10, textLine + 235, 60, 10, 'S')
    doc.rect(10, textLine + 245, 180, 40, 'S')
    doc.rect(10, textLine + 245, 60, 40, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('“Entry Date”', 12, textLine + 4)
    doc.text('“Event”', 12, textLine + 14)
    doc.text('“Fraud and/or Alleged Fraud”', 12, textLine + 29)
    doc.text('“Financial Services"', 12, textLine + 39)
    doc.text('Provider/Intermediary”', 12, textLine + 44)
    doc.text('“Insurable Interest”', 12, textLine + 59)
    doc.text('“Insured”', 12, textLine + 89)
    doc.text('“Insurer/Us”', 12, textLine + 119)
    doc.text('“Misrepresentation”', 12, textLine + 129)
    doc.text('“Married/Spouse”', 12, textLine + 169)
    doc.text('“Nominated Beneficiary”', 12, textLine + 194)
    doc.text('“Persol”', 12, textLine + 239)
    doc.text('“Personal Information”', 12, textLine + 249)

    doc.setFont('Helvetica', 'normal')
    doc.text('the Policy Inception Date: or the date each life Insured becomes eligible if this', 72, textLine + 4)
    doc.text('date is after the Policy Inception Date.', 72, textLine + 9)
    doc.text('the event which gave rise to a claim in terms of this Policy. Should the Insured', 72, textLine + 14)
    doc.text('event occur before premium payment is affected, the Insurer will have no liability,', 72, textLine + 19)
    doc.text('unless the Insurer is satisfied that at the time, an insurable risk did exist.', 72, textLine + 24)
    doc.text('the unlawful and intentional misrepresentation which can lead to actual or', 72, textLine + 29)
    doc.text('potential disadvantage to another individual, group and/or company.', 72, textLine + 34)
    doc.text('an intermediary who acts on behalf of the Policyholder in effecting and servicing', 72, textLine + 39)
    doc.text('an insurance policy.', 72, textLine + 44)
    doc.text('(i) Maxi Forces Financial Services (Pty) Ltd FSP No.1966.', 72, textLine + 49)
    doc.text('(ii) The South African Army Foundation FSP No.6995.', 72, textLine + 54)
    doc.text('(i) immediate family', 72, textLine + 59)
    doc.text('(ii) Extended Family and/or', 72, textLine + 64)
    doc.text('(iii) instances where the Policyholder receives financial or other types of benefit', 72, textLine + 69)
    doc.text('from the continued existence of the life Insured. If the person Insured were', 72, textLine + 74)
    doc.text('to pass away, the Policyholder would experience a financial loss or other', 72, textLine + 79)
    doc.text('hardship. Insurable interest must exist on all lives covered.', 72, textLine + 84)
    doc.text('(i) shall mean a person who has successfully applied for this Policy, who is', 72, textLine + 89)
    doc.text('listed in the Certificate of Cover and for whom the full premium has been', 72, textLine + 94)
    doc.text('paid up to date.', 72, textLine + 99)
    doc.text('(ii) any individual upon whose life / health the payment of any benefit is', 72, textLine + 104)
    doc.text('contingent upon the happening of an event which gives rise to claim in', 72, textLine + 109)
    doc.text('terms of this Policy.', 72, textLine + 114)
    doc.text('means a licenced Life insurer, namely Shield Life® Limited with Company', 72, textLine + 119)
    doc.text('registration no: 2016/249174/06.', 72, textLine + 124)
    doc.text('(i) the act of providing a false statement, during an application for/on a policy', 72, textLine + 129)
    doc.text('made verbally, by conduct or sometimes by nondisclosure or concealment', 72, textLine + 134)
    doc.text('and often for the purpose of deceiving, defrauding, or causing another to', 72, textLine + 139)
    doc.text('rely on it detrimentally.', 72, textLine + 144)
    doc.text('(ii) when an individual provides false information intentionally, knowing it not to', 72, textLine + 149)
    doc.text('be true, it is known as fraudulent misrepresentation.', 72, textLine + 154)
    doc.text('(iii) false information that is believed to be true by the individual is known as', 72, textLine + 159)
    doc.text('innocent or negligent misrepresentation.', 72, textLine + 164)
    doc.text('(i) according to the laws of any country, state, or sovereign state.', 72, textLine + 169)
    doc.text('(ii) married according to customary or tribal law.', 72, textLine + 174)
    doc.text('(iii) living together and financially dependent on one another.', 72, textLine + 179)
    doc.text('(iv) married under any recognised religion in South Africa; or', 72, textLine + 184)
    doc.text('(v) a civil union, in terms of the Civil Union Act of 2006.', 72, textLine + 189)
    doc.text('Primary:', 72, textLine + 194)
    doc.text('(i) means the person, legal entity or fund nominated by the Policyholder on the', 72, textLine + 199)
    doc.text('application form to receive the Benefits in the event of the death of the', 72, textLine + 204)
    doc.text('Insured.', 72, textLine + 209)
    doc.text('(ii) the Beneficiary can be changed at any time by the Policyholder.', 72, textLine + 214)
    doc.text('(iii) must have a valid South African Bank Account.', 72, textLine + 219)
    doc.text('Next-in-line:', 72, textLine + 224)
    doc.text('(i) back up Beneficiary should the primary Beneficiary pre - decease the', 72, textLine + 229)
    doc.text('Principal Life Insured.', 72, textLine + 234)
    doc.text('the salary payroll deduction system used to deduct the monthly premiums from', 72, textLine + 239)
    doc.text('the salary of the Policyholder.', 72, textLine + 244)
    doc.text('information relating to an identifiable, natural or juristic person, including but not', 72, textLine + 249)
    doc.text('limited to, information relating to race, gender, sex, marital, status, nationality,', 72, textLine + 254)
    doc.text('ethnic or social origin, colour, sexual orientation, age, physical or mental health,', 72, textLine + 259)
    doc.text('religion, belief, disability, language, birth, education, identity number, telephone', 72, textLine + 264)
    doc.text('number, email, postal or street address, biometric information and financial,', 72, textLine + 269)
    doc.text('criminal or employment history as well as correspondence sent by the person', 72, textLine + 274)
    doc.text('that is implicitly or explicitly of a private or confidential nature or further', 72, textLine + 279)
    doc.text('correspondence that would reveal the contents of the original correspondence.', 72, textLine + 284)

    doc.addPage();
    textLine = 5;
    doc.rect(10, textLine, 180, 5, 'S')
    doc.rect(10, textLine, 60, 5, 'S')
    doc.rect(10, textLine, 60, 5, 'S')
    doc.rect(10, textLine + 5, 180, 5, 'S')
    doc.rect(10, textLine + 5, 60, 5, 'S')
    doc.rect(10, textLine + 10, 180, 10, 'S')
    doc.rect(10, textLine + 10, 60, 10, 'S')
    doc.rect(10, textLine + 20, 180, 15, 'S')
    doc.rect(10, textLine + 20, 60, 15, 'S')
    doc.rect(10, textLine + 35, 180, 30, 'S')
    doc.rect(10, textLine + 35, 60, 30, 'S')
    doc.rect(10, textLine + 65, 180, 10, 'S')
    doc.rect(10, textLine + 65, 60, 10, 'S')
    doc.rect(10, textLine + 75, 180, 20, 'S')
    doc.rect(10, textLine + 75, 60, 20, 'S')
    doc.rect(10, textLine + 95, 180, 45, 'S')
    doc.rect(10, textLine + 95, 60, 45, 'S')
    doc.rect(10, textLine + 140, 180, 5, 'S')
    doc.rect(10, textLine + 140, 60, 5, 'S')
    doc.rect(10, textLine + 145, 180, 20, 'S')
    doc.rect(10, textLine + 145, 60, 20, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('“Policy Inception Date”', 12, textLine + 4)
    doc.text('“Policyholder/You”', 12, textLine + 9)
    doc.text('“Premium”', 12, textLine + 14)
    doc.text('“Principal Life Insured”', 12, textLine + 24)
    doc.text('“Repudiate”', 12, textLine + 39)
    doc.text('“Review Date”', 12, textLine + 69)
    doc.text('“SADC Region/Territorial Limits”', 12, textLine + 79)
    doc.text('“Unnatural Cause”', 12, textLine + 99)
    doc.text('“Void”', 12, textLine + 144)
    doc.text('“Waiting Period”', 12, textLine + 149)

    doc.setFont('Helvetica', 'normal')
    doc.text('the date that this Policy becomes effective is specified in the Certificate of Cover.', 72, textLine + 4)
    doc.text('the principal Insured and/or premium payer of the Policy.', 72, textLine + 9)
    doc.text('the monthly amount payable as stated in the Certificate of Cover and any', 72, textLine + 14)
    doc.text('endorsement issued in terms of this Policy.', 72, textLine + 19)
    doc.text('shall mean the person who has successfully applied for this Policy and whose', 72, textLine + 24)
    doc.text('name is indicated in the records of the Insurer as the owner of the policy and', 72, textLine + 29)
    doc.text('who is listed in the Certificate of Cover as an Insured.', 72, textLine + 34)

    doc.text('in relation to a claim, any action by which the Insurer rejects or refuses to pay a', 72, textLine + 39)
    doc.text('claim or any part of a claim, for any reason, and includes instances where a', 72, textLine + 44)
    doc.text('claimant lodges a claim:.', 72, textLine + 49)
    doc.text('(i) in respect to a loss event or risk not covered by the Policy; and', 72, textLine + 54)
    doc.text('(ii) in respect of a loss event or risk covered by a Policy, but the premium or', 72, textLine + 59)
    doc.text('premiums payable in respect of this Policy is not paid.', 72, textLine + 64)
    doc.text('the date when premium rates are reviewed. This date is specified as such in the', 72, textLine + 69)
    doc.text('Certificate of Cover.', 72, textLine + 74)
    doc.text('the Southern African Development Community comprising of Angola, Botswana,', 72, textLine + 79)
    doc.text('Democratic Republic of Congo, Lesotho, Madagascar, Malawi, Mauritius,', 72, textLine + 84)
    doc.text('Mozambique, Namibia, Seychelles, the Republic of South Africa, Eswatini,', 72, textLine + 89)
    doc.text('Tanzania, Zambia, and Zimbabwe.', 72, textLine + 94)
    doc.text('(i) any Death due to physical or chemical influence, direct or indirect, or', 72, textLine + 99)
    doc.text('related complications.', 72, textLine + 104)
    doc.text('(ii) any Death including those Deaths which would normally be considered to', 72, textLine + 109)
    doc.text('be a Death due to natural causes, which may have been the result of an act', 72, textLine + 114)
    doc.text('of commission or omission which may be criminal in nature.', 72, textLine + 119)
    doc.text('(iii) any Death as contemplated in section 56 of the Health Professions Act,', 72, textLine + 124)
    doc.text('1974 (Act No. 56 of 1974); and', 72, textLine + 129)
    doc.text('(iv) any Death which is sudden and unexpected, or unexplained, or where the', 72, textLine + 134)
    doc.text('cause of Death is not apparent, this shall exclude suicide.', 72, textLine + 139)
    doc.text('instances when a Policy will be rendered of no legal force or effect.', 72, textLine + 144)
    doc.text('The waiting period is the period from the Date of Entry during which time a', 72, textLine + 149)
    doc.text('Principal Life Insured and any Dependants are only covered for death as a result', 72, textLine + 154)
    doc.text('of any Accidental causes. Only after this period will death as a result of natural', 72, textLine + 159)
    doc.text('causes be covered.', 72, textLine + 164)

    textLine += 170;
    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('WHAT IS COVERED?', 5, textLine)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('The value of the Benefit payable in terms of this Policy as specified in the Certificate of Cover may not exceed the maximum amounts', 5, textLine + 5)
    doc.text('as prescribed by the Regulator (Monetary and/or non-monetary)', 5, textLine + 10)

    textLine += -5;
    doc.setFont('Helvetica', 'bold')
    doc.text('LIFE COVER BENEFITS', 5, textLine + 20)
    doc.setFont('Helvetica', 'normal')
    doc.text('The Cover is whole life, pure risk and has no surrender value. (IF INDICATED IN THE CERTIFICATE OF COVER TO BE INCLUDED)', 5, textLine + 25)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 30, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('TYPE OF BENEFIT', 13, textLine + 33.5)
    doc.text('DESCRIPTION', 73, textLine + 33.5)

    doc.rect(10, textLine + 35, 180, 10, 'S')
    doc.rect(10, textLine + 35, 60, 10, 'S')
    doc.rect(10, textLine + 35, 60, 10, 'S')
    doc.rect(10, textLine + 45, 180, 25, 'S')
    doc.rect(10, textLine + 45, 60, 25, 'S')
    doc.rect(10, textLine + 70, 180, 55, 'S')
    doc.rect(10, textLine + 70, 60, 55, 'S')
    // doc.rect(10, textLine + 105, 180, 20, 'S')
    // doc.rect(10, textLine + 105, 60, 20, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('LUMP SUM - DEATH BENEFIT', 12, textLine + 39)
    doc.text('ACCELERATED MILITARY ', 12, textLine + 49)
    doc.text('DISABILITY - LUMP SUM BENEFIT', 12, textLine + 54)
    doc.text('ACCELERATED TOTAL AND', 12, textLine + 74)
    doc.text('PERMANENT DISABILITY -', 12, textLine + 79)
    doc.text('LUMP SUM BENEFIT', 12, textLine + 84)

    doc.setFont('Helvetica', 'normal')
    doc.text('We will pay to the Beneficiary in the event of the Insured’s Death, the lump sum', 72, textLine + 39)
    doc.text('amount specified in the Certificate of Cover.', 72, textLine + 44)
    doc.text('We will pay the Principal Life Insured, the lump sum amount of the Life Benefit ', 72, textLine + 49)
    doc.text('payable if the disability results in the Principal Insured experiencing a ', 72, textLine + 54)
    doc.text('loss of income and being declared Medically Unfit by the South African National', 72, textLine + 59)
    doc.text('Defence Force’s Medical Examination / Medical Board and we as Insurer are', 72, textLine + 64)
    doc.text('satisfied that the disability will remain total and permanent.', 72, textLine + 69)
    doc.text('We will pay the Principal Life Insured, the lump sum amount of the Life Benefit', 72, textLine + 74)
    doc.text('payable on the happening of the continuous, total, and permanent inability of the', 72, textLine + 79)
    doc.text('Principal Life Insured and/or Spouse of the Principal Life Insured, due to injury,', 72, textLine + 84)
    doc.text('disease, or illness to perform the material and substantial duties of any occupation', 72, textLine + 89)
    doc.text('in the open labour market for which he is qualified or suited or could reasonably', 72, textLine + 94)
    doc.text('be expected to become qualified or suited taking into account his knowledge,', 72, textLine + 99)
    doc.text('training, education, ability, age and experience after a waiting period of 6 months.', 72, textLine + 104)
    doc.text('We will, however, only pay the benefit when a Spouse of the Principal Life Insured ', 72, textLine + 109)
    doc.text('is totally and Permanently Disabled if the disability results in the spouse of', 72, textLine + 114)
    doc.text('the Principal Life Insured experiencing a loss of income and the Insurer is', 72, textLine + 119)
    doc.text('satisfied that the disability will remain total and permanent.', 72, textLine + 124)

    doc.addPage();
    textLine = 10;
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'bold')
    doc.text('FUNERAL BENEFITS', 5, textLine)
    doc.setFont('Helvetica', 'normal')
    doc.text('The purpose of this Cover is to provide insurance to assist in covering the costs of the funeral of the Insured/s, in the event of their Death.', 5, textLine + 5)
    doc.text('This is achieved through the payment of a lump sum amount and/or benefits as specified in the Policy Certificate of Cover (and amended', 5, textLine + 10)
    doc.text('from time to time.)', 5, textLine + 15)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 20, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('TYPE OF BENEFIT', 13, textLine + 23.5)
    doc.text('DESCRIPTION', 73, textLine + 23.5)

    doc.rect(10, textLine + 25, 180, 10, 'S')
    doc.rect(10, textLine + 25, 60, 10, 'S')
    doc.rect(10, textLine + 25, 60, 10, 'S')
    doc.rect(10, textLine + 35, 180, 10, 'S')
    doc.rect(10, textLine + 35, 60, 10, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('LUMP SUM BENEFIT', 12, textLine + 29)
    doc.text('COMMUTER BENEFIT', 12, textLine + 39)

    doc.setFont('Helvetica', 'normal')
    doc.text('We will pay to the Beneficiary in the event of the Insured’s Death, the lump sum', 72, textLine + 29)
    doc.text('amount specified in the Certificate of Cover.', 72, textLine + 34)
    doc.text('We will pay to the Beneficiary in the event of the Policyholder’s Death, the lump', 72, textLine + 39)
    doc.text('sum amount specified in the Certificate of Cover.', 72, textLine + 44)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('LIMITATIONS', 5, textLine + 55)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('The limitations shall be calculated with reference to the entry date, endorsements date or reinstatement of cover date in respect of an', 5, textLine + 60)
    doc.text('Insured as stipulated in the Certificate of Cover.', 5, textLine + 65)
    textLine += -5
    doc.setFontSize(10)
    doc.setFont('Helvetica', 'bold')
    doc.text('WAITING PERIODS', 5, textLine + 75)
    doc.setFontSize(9)
    doc.text('LIFE COVER BENEFITS', 5, textLine + 80)
    doc.setFont('Helvetica', 'normal')
    doc.text('A waiting period is the period from the Date of Entry during which time the Policyholder and any Dependants or Extended Family', 5, textLine + 85)
    doc.text('Members are not entitled to funeral benefits.', 5, textLine + 90)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 95, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('No insurance cover shall be granted during the waiting periods listed below', 13, textLine + 98.5)

    doc.rect(10, textLine + 100, 180, 10, 'S')
    doc.rect(10, textLine + 100, 60, 10, 'S')
    doc.rect(10, textLine + 100, 60, 10, 'S')
    doc.rect(10, textLine + 110, 180, 10, 'S')
    doc.rect(10, textLine + 110, 60, 10, 'S')
    doc.rect(10, textLine + 120, 180, 10, 'S')
    doc.rect(10, textLine + 120, 60, 10, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('Death as result of Natural Causes', 12, textLine + 104)
    doc.text('Total and permanent disability', 12, textLine + 114)
    doc.text('benefit', 12, textLine + 119)
    doc.text('Self-inflicted Injury or Suicide', 12, textLine + 124)

    doc.setFont('Helvetica', 'normal')
    doc.text('There is a waiting period of three (3) months in respect of Policy benefits payable', 72, textLine + 104)
    doc.text('on the happening of a Death, resulting from natural causes.', 72, textLine + 109)
    doc.text('The total and permanent disability benefit is paid after a waiting period of 6 months', 72, textLine + 114)
    doc.text('to give a reasonable time period to assess for total and permanent disability.', 72, textLine + 119)
    doc.text('There is a waiting period of twelve (12) months in respect of Policy benefits payable', 72, textLine + 124)
    doc.text('on the happening of a Death, resulting from self-inflicted injury or suicide.', 72, textLine + 129)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 130, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Waiting periods do not apply when Death occurs as a result of Accidental Death or Disability', 13, textLine + 133.5)

    textLine += 140
    doc.setFont('Helvetica', 'bold')
    doc.text('FUNERAL BENEFITS', 5, textLine)
    doc.setFont('Helvetica', 'normal')
    doc.text('A waiting period is the period from the Date of Entry during which time the Policyholder and any Dependants or Extended Family', 5, textLine + 5)
    doc.text('Members are not entitled to funeral benefits.', 5, textLine + 10)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 15, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('No insurance cover shall be granted during the waiting periods listed below', 13, textLine + 18.5)

    doc.rect(10, textLine + 20, 180, 10, 'S')
    doc.rect(10, textLine + 20, 60, 10, 'S')
    doc.rect(10, textLine + 20, 60, 10, 'S')
    doc.rect(10, textLine + 30, 180, 10, 'S')
    doc.rect(10, textLine + 30, 60, 10, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('Death as result of Natural Causes', 12, textLine + 24)
    doc.text('Self-inflicted Injury or Suicide', 12, textLine + 34)

    doc.setFont('Helvetica', 'normal')
    doc.text('There is a waiting period of six (6) months in respect of Policy benefits payable on', 72, textLine + 24)
    doc.text('the happening of a Death, resulting from natural causes.', 72, textLine + 29)
    doc.text('There is a waiting period of twelve (12) months in respect of Policy benefits payable', 72, textLine + 34)
    doc.text('on the happening of a Death, resulting from self - inflicted injury or suicide.', 72, textLine + 39)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 40, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('Waiting periods do not apply when Death occurs as a result of Accidental Death or Disability', 13, textLine + 43.5)

    doc.setFont('Helvetica', 'normal')
    doc.text('The Insurer will impose a waiting period equal to the unexpired part of the waiting period under a previous funeral Policy, if the waiting', 5, textLine + 50)
    doc.text('period under the previous Policy had not expired at the time that the Policyholder enters into the new Policy, subject to the previous', 5, textLine + 55)
    doc.text('Policy covering similar risks and relating to predominantly the same lives Insured as those covered under the new funeral Policy.', 5, textLine + 60)

    doc.setFontSize(10)
    doc.setFont('Helvetica', 'bold')
    doc.text('TERRITORIAL LIMITATIONS', 5, textLine + 70)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('An Insured shall continue to enjoy cover should he be temporarily absent from the Republic of South Africa or any of the SADC countries', 5, textLine + 75)
    doc.text('in the execution of his full-time employment only. During the period of temporary absence, the following provisions shall apply: ', 5, textLine + 80)
    doc.text('a) Payment of premiums shall be continued by the Policyholder and the Insured shall continue to be covered, at the same level that he was', 5, textLine + 85)
    doc.text('covered immediately before his absence commenced, for a period of twelve (12) months.', 10, textLine + 90)
    doc.text('b) Should the Policyholder desire cover to continue in his absence for a period more than twelve (12) months, he must advise the Insurer', 5, textLine + 95)
    doc.text('and the Insurer must be agree to the request in writing.', 10, textLine + 100)
    doc.text('c) After twenty-four (24) consecutive months` absence from the territories, the cover in respect of the Insured shall automatically terminate,', 5, textLine + 105)
    doc.text('irrespective of any arrangements that may have been agreed to between the Policyholder and the Insurer in terms of clause (b) above.', 10, textLine + 110)
    doc.text('d) Upon return to the Republic of South Africa or any of the SADC countries by the Insured after cessation of the Cover in terms of clause', 5, textLine + 115)
    doc.text('(b) or (c), the Cover shall recommence as if he was a new Policyholder.', 10, textLine + 120)
    doc.text('e) Absences from the territorial limits that are not separated by at least six (6) consecutive weeks shall be added together to determine', 5, textLine + 125)
    doc.text('whether or not the twelve (12) months in terms of clause (a), or the twenty-four (24) months in terms of clause (b).', 10, textLine + 130)
    doc.text('f) The Insurer may request, in writing, that it be specifically advised of such Insureds by way of monthly schedules detailing the Insureds who', 5, textLine + 135)
    doc.text('are absent from the territorial limits. If such schedules are not specifically requested, there is no obligation for the same to be provided.', 10, textLine + 140)
    doc.text('g) Should the Policyholder wish to extend the territorial limits, this request, in writing, shall be made to the Insurer. If the Insurer grants', 5, textLine + 145)
    doc.text('this request, then the Insurer will provide written confirmation of the request together with a change in the premium, if deemed necessary.', 10, textLine + 150)

    doc.addPage()
    textLine = 10
    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('WHAT IS NOT COVERED?', 5, textLine)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('The exclusions shall be calculated with reference to the entry date, endorsements date or reinstatement of cover date in respect of an', 5, textLine + 5)
    doc.text('Insured as stipulated in the Certificate of Cover.', 5, textLine + 10)

    doc.setFontSize(10)
    doc.setFont('Helvetica', 'bold')
    doc.text('EXCLUSIONS', 5, textLine + 20)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('1.  The willing participation by the Principal Life Insured or any Dependant in any of the following against the lawful military or police forces', 5, textLine + 25)
    doc.text('of South Africa, these exclusions will not be applicable to the Insured whilst on active duty:', 10, textLine + 30)
    doc.text('a) War, hostilities, or warlike operations, whether war be declared or not.', 10, textLine + 35)
    doc.text('b) Invasion.', 10, textLine + 40)
    doc.text('c) Act of an enemy foreign to the nationality of the Insured or the country in, or over, which the act occurs.', 10, textLine + 45)
    doc.text('d) Civil war.', 10, textLine + 50)
    doc.text('e) Insurrection.', 10, textLine + 55)
    doc.text('f) Revolution.', 10, textLine + 60)
    doc.text('g) Overthrow of the legally constituted government.', 10, textLine + 65)
    doc.text('h) Civil commotion/disobedience assuming the proportions of, or amounting to, an uprising against an established government.', 10, textLine + 70)
    doc.text('i) Military or usurped power.', 10, textLine + 75)
    doc.text('2.  Any Death, or Disability directly or indirectly caused by, resulting from, or in connection with any of the following:', 5, textLine + 80)
    doc.text('a) Use of nuclear, biological, or chemical weapons, or any radioactive contamination.', 10, textLine + 85)
    doc.text('b) Attacks on or sabotage of facilities (including but not limited to nuclear power plants, reprocessing plants, final repository sites and', 10, textLine + 90)
    doc.text('research reactors) and storage depots which lead to the release of radioactivity or nuclear, biological, or chemical warfare agents,', 10, textLine + 95)
    doc.text('irrespective of whether any of the aforesaid has been performed with the specific use of information technology.', 10, textLine + 100)
    doc.text('3.  Active participation in any act of terrorism by any person or group, whether acting alone or under instruction', 5, textLine + 105)
    doc.text('4.  Radioactivity and nuclear explosion', 5, textLine + 110)
    doc.text('5.  Active participation in Labour disturbances, riots, demonstrations, strikes or lockouts', 5, textLine + 115)
    doc.text('6.  If you (or any person acting on your instruction) were involved in any violation of any law.', 5, textLine + 120)
    doc.text('7.  Participation in:', 5, textLine + 125)
    doc.text('a) aviation other than a fare – paying passenger provided by a licensed airline or air charter company.', 10, textLine + 130)
    doc.text('b) speed tests or races.', 10, textLine + 135)
    doc.text('c) hang-gliding, bungee- jumping or parachute jumping.', 10, textLine + 140)
    doc.text('d) mountaineering (without ropes), potholing.', 10, textLine + 145)
    doc.text('e) underwater activities necessitating the use of artificial breathing apparatus.', 10, textLine + 150)
    doc.text('8.  In respect of Life Cover, Death, other than accidental Death during the 3 (three) months waiting period', 5, textLine + 155)
    doc.text('9.  In respect of Funeral Cover, Death, other than accidental Death during the 6 (six) months waiting period.', 5, textLine + 160)
    doc.text('10.  In respect of Commuter Funeral Cover, Death, travel other than defined under “Commuter Cover”.', 5, textLine + 165)
    doc.text('11.  Death, following self-inflicted injury / suicide during the first 12 months of entry.', 5, textLine + 170)
    doc.text('12.  Intentional use of drugs, narcotics or medication unless prescribed by a registered medical doctor and used as prescribed.', 5, textLine + 175)
    doc.text('13.  Excessive use of intoxicating liquor, wilful inhalation of gas or taking of poisons.', 5, textLine + 180)
    doc.text('14.  If you decline or neglect to be medically examined when required to do so.', 5, textLine + 185)
    doc.text('15. If you decline or neglect to undergo reasonable medical treatment when there is a reasonable likelihood that the medical treatment', 5, textLine + 190)
    doc.text('will improve your ability to perform the duties of the occupations referred to in the definitions of Total and Permanent Disability', 10, textLine + 195)
    doc.text('and/or Military Disability.', 10, textLine + 200)
    doc.text('16.  If you refuse any treatment recommended by a registered medical practitioner.', 5, textLine + 205)
    doc.text('17.  Where you, or any other person acting on your behalf has acted fraudulently, whether at the time of entering', 5, textLine + 210)
    doc.text('into agreement, lodging a claim or at any other time. The Insurer reserves the right to cancel the cover and declare all premiums paid', 10, textLine + 215)
    doc.text('as forfeited, should there be evidence of or an attempted submission of a fictional claim, fraud, or misrepresentation.', 10, textLine + 220)
    doc.text('18.  If any portion of an exclusions found to be invalid or unenforceable, the remainder shall remain in full force and effect.', 5, textLine + 225)

    textLine += 230
    doc.setFontSize(10)
    doc.setFont('Helvetica', 'bold')
    doc.text('PRE-EXISTING CONDITIONS', 5, textLine + 5)
    doc.setFontSize(9)
    textLine += -15
    doc.setFont('Helvetica', 'normal')
    doc.text('1.  No disability Benefit shall be paid as a result of the Insured’s disability if the Insured becomes disabled during the first twelve(12) ', 5, textLine + 25)
    doc.text('months of him becoming an Insured and the disablement, in the opinion of the Insurer, is directly or indirectly attributable to an', 10, textLine + 30)
    doc.text('injury or illness in respect of which he sought medical advice, or about which he knew or could reasonably be expected to have known,', 10, textLine + 35)
    doc.text('during the six (6) month period preceding his Entry Date.', 10, textLine + 40)
    doc.text('2.  The six (6) month period referred to above shall however be backdated to the date on which the Insured was covered under a policy', 5, textLine + 45)
    doc.text('issued by any Insurer of the Insured, which Insurer was insuring a similar or equivalent benefit in respect of the Insured immediately', 10, textLine + 50)
    doc.text('before the Policy Inception Date.', 10, textLine + 55)
    doc.text('3.  These pre-existing conditions may also be waived in other situations if the Insurer agrees to this in writing.', 5, textLine + 60)

    doc.addPage()
    textLine = 10
    doc.text('4.  Where this policy is altered and as a result the amount of disability Benefit to which an Insured shall be entitled is increased (including,', 5, textLine)
    doc.text('but not limited to the Waiting Period being reduced), the amount of the disability Benefit attributable to the alteration shall not be', 10, textLine + 5)
    doc.text('payable in respect of the Insured where:', 10, textLine + 10)
    doc.text('i) The disability, in the opinion of the Insurer, is directly or indirectly attributable to an injury or illness in respect of which he', 15, textLine + 15)
    doc.text('sought medical advice, or about which he knew or could reasonably be expected to have known, during the six (6) month period', 15, textLine + 20)
    doc.text('preceding the effective date of the alteration.', 15, textLine + 25)
    doc.text('5.  If any portion of this exclusion is found to be invalid or unenforceable, the remainder shall remain in full force and effect.', 5, textLine + 30)
    doc.text('6.  Not with standing to the provisions in this clause; the exclusions will not be applicable to the Insured whilst on active duty.', 5, textLine + 35)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('WHO CAN BE COVERED?', 5, textLine + 45)
    doc.setFontSize(10)
    doc.text('LIFE COVER BENEFITS', 5, textLine + 50)
    doc.text('Spouse', 5, textLine + 65)
    doc.text('FUNERAL BENEFITS', 5, textLine + 80)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('A Policyholder may add a Spouse and additional Spouses, at an additional premium, to participate in the Benefits under this policy under', 5, textLine + 55)
    doc.text('the following conditions:', 5, textLine + 60)
    doc.text('A Spouse may be added at any time. In instances of common-law or customary union, proof of such, to the satisfaction of the Insurer,', 5, textLine + 70)
    doc.text('must be provided by the Policyholder in order for a Spouse to be added.', 5, textLine + 75)
    doc.text('A Policyholder may add a Spouse, additional Dependants and Extended Family to participate in the Benefits under this policy under the', 5, textLine + 85)
    doc.text('following conditions.', 5, textLine + 90)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 95, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('DESCRIPTION', 13, textLine + 98.5)
    doc.text('CONDITION', 73, textLine + 98.5)

    textLine += 100
    doc.rect(10, textLine, 180, 10, 'S')
    doc.rect(10, textLine, 60, 10, 'S')
    doc.rect(10, textLine, 60, 10, 'S')
    doc.rect(10, textLine + 10, 180, 45, 'S')
    doc.rect(10, textLine + 10, 60, 45, 'S')
    doc.rect(10, textLine + 55, 180, 15, 'S')
    doc.rect(10, textLine + 55, 60, 15, 'S')
    doc.rect(10, textLine + 70, 180, 35, 'S')
    doc.rect(10, textLine + 70, 60, 35, 'S')
    doc.rect(10, textLine + 105, 180, 30, 'S')
    doc.rect(10, textLine + 105, 60, 30, 'S')
    doc.rect(10, textLine + 135, 180, 50, 'S')
    doc.rect(10, textLine + 135, 60, 50, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('Any person between the age of', 12, textLine + 4)
    doc.text('18 and 59 years', 12, textLine + 9)
    doc.text('A Principal Life Insured', 12, textLine + 14)
    doc.text('Dependants', 12, textLine + 59)
    doc.text('Eligible Spouse', 12, textLine + 74)
    doc.text('Additional Spouse/s', 12, textLine + 109)
    doc.text('Eligible Child', 12, textLine + 139)

    doc.setFont('Helvetica', 'normal')
    doc.text('who is a Member of the South African Army Foundation.', 72, textLine + 4)
    textLine += 5;
    doc.text('who is no longer in full-time service of the National Defence Force either by way', 72, textLine + 9)
    doc.text('of retirement or resignation, or for any other reason, and who has amended his', 72, textLine + 14)
    doc.text('deduction method within seven (7) months provided all arrears premiums is paid', 72, textLine + 19)
    doc.text('up to date, will remain covered as long as the monthly premium is paid.', 72, textLine + 24)
    doc.text('Should such Principal Life Insured no longer be a member of the Department of', 72, textLine + 29)
    doc.text('Defence, the monthly premium will be debited from his bank account.In this event,', 72, textLine + 34)
    doc.text('the Policyholder’s Premium and Cover will not increase until the cover discontinues.', 72, textLine + 39)
    doc.text('After the grace period of 7 months has lapsed, a previous Principal Life Insured can', 72, textLine + 44)
    doc.text('request to reapply, however, he will obtain an Individual Life product based on age.', 72, textLine + 49)
    doc.text('who are admitted as the Principal Life Insured’s Dependants and such Principal ', 72, textLine + 54)
    doc.text('Life Insured must be registered on the South African Army Foundation’s Member', 72, textLine + 59)
    doc.text('Administration system.', 72, textLine + 64)
    doc.text('a) the legal or common law husband/wife of a Policyholder or such person residing', 72, textLine + 69)
    doc.text('with the Policyholder, who is normally regarded by the community as the', 72, textLine + 74)
    doc.text('Policyholder’s husband / wife. This definition shall not be limited to one (1)', 72, textLine + 79)
    doc.text('Spouse per Policyholder but shall include more than one Spouse provided that', 72, textLine + 84)
    doc.text(' any additional Spouse can be validated and is legitimate.', 72, textLine + 89)
    doc.text('b) a primary Spouse can only be replaced after by a new Spouse after Death or', 72, textLine + 94)
    doc.text('Divorce. The 6 (six) months waiting period will be applied upon Entry.', 72, textLine + 99)
    doc.text('a) Eligible Dependants shall include any additional Spouse’s named and loaded on', 72, textLine + 104)
    doc.text('the South African Army Foundation’s Member Administration system. The maximum', 72, textLine + 109)
    doc.text('number of additional Spouses are limited to three (3).', 72, textLine + 114)
    doc.text('b) any additional Spouse will be charged an extra premium. Upon Entry, which can', 72, textLine + 119)
    doc.text('take place at any time, a waiting period for natural death of six(6) months will be', 72, textLine + 124)
    doc.text('applied for such person.', 72, textLine + 129)
    textLine += -5;
    doc.text('a) the Principal Insured’s legitimate, unmarried, financially dependent Children,', 72, textLine + 139)
    doc.text('who have not yet attained the age of 21 years and shall include natural Children,', 72, textLine + 144)
    doc.text('legally adopted Children, foster Children in terms of(i) below and stepchildren.', 72, textLine + 149)
    doc.text('b) this age per (a) above may be extended to 26 in respect of an unmarried Child', 72, textLine + 154)
    doc.text('who is a full-time student, provided this Child remains financially dependent on', 72, textLine + 159)
    doc.text('the Policyholder and if such Child is registered as a full - time student at a', 72, textLine + 164)
    doc.text('recognised tertiary institution.', 72, textLine + 169)
    doc.text('c) there will be no age restriction for Children who are either mentally or ', 72, textLine + 174)
    doc.text('physically incapacitated from maintaining themselves, provided that the Children', 72, textLine + 179)
    doc.text('are wholly dependent on the Policyholder for support and maintenance.', 72, textLine + 184)

    doc.addPage()
    textLine = 5;
    doc.rect(10, textLine, 180, 125, 'S')
    doc.rect(10, textLine, 60, 125, 'S')
    doc.rect(10, textLine, 60, 125, 'S')
    doc.rect(10, textLine + 125, 180, 135, 'S')
    doc.rect(10, textLine + 125, 60, 135, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('Extended Family Members', 12, textLine + 128.5)

    doc.setFont('Helvetica', 'normal')
    doc.text('d) once a Child has become independent from the Policyholder for maintenance', 72, textLine + 4)
    doc.text('and support, then that Child no longer falls under this definition but as an Extended', 72, textLine + 9)
    doc.text('Family Member. Cover for the independent Child can continue provided it is applied', 72, textLine + 14)
    doc.text('for. Any additional independent Children will be charged an additional premium.', 72, textLine + 19)
    doc.text('e) if the Child later resumes dependence on the Principal Life Insured, that child', 72, textLine + 24)
    doc.text('may again be covered under this Funeral Policy provided that such Child meets the', 72, textLine + 29)
    doc.text('criteria laid down above.', 72, textLine + 34)
    doc.text('f) a stillborn Child is included under this definition provided that there is at least', 72, textLine + 39)
    doc.text('twenty-six (26) weeks of intra - uterine existence, and that the foetus showed no life', 72, textLine + 44)
    doc.text('after complete birth.Stillborn shall exclude the intentional termination of the life of', 72, textLine + 49)
    doc.text('the Child.', 72, textLine + 54)
    doc.text('g) any Child that exceeds 21 years of age, either as an unmarried full - time student', 72, textLine + 59)
    doc.text('up to the age of 26 or as an incapacitated Child in terms of(c) above and is entitled', 72, textLine + 64)
    doc.text('to a benefit in terms of this Policy shall be entitled to the benefit applicable to', 72, textLine + 69)
    doc.text('a Child 21 years of age.', 72, textLine + 74)
    doc.text('h) independent Children above the age of 21, to the extent they are included by the', 72, textLine + 79)
    doc.text('Insured as Extended Family Members.', 72, textLine + 84)
    doc.text('i) A foster Child is a Child who has been placed in the Insured’ custody by a court', 72, textLine + 89)
    doc.text('because of being orphaned, abandoned and / or at risk of being abused. Any foster ', 72, textLine + 94)
    doc.text('Child that remains in the custody of the Insured’s care and must be under the age', 72, textLine + 99)
    doc.text('of 18 years old, or until the age of 21 years, provided this extension is legally', 72, textLine + 104)
    doc.text('approved. If this extension is not approved, the Child can be added as an Extended', 72, textLine + 109)
    doc.text('Family member.', 72, textLine + 114)
    doc.text('j) Grandchildren, to the extent they are included by the Insured as Extended Family', 72, textLine + 119)
    doc.text('Member', 72, textLine + 124)

    doc.text('up to eight (8) Extended Family members may be added to this Policy.Additional', 72, textLine + 129)
    doc.text('Extended Family Members will be charged an extra premium.Upon Entry, which', 72, textLine + 134)
    doc.text('can take place at any time, a waiting period for natural Death of six (6) months', 72, textLine + 139)
    doc.text('will be applied to such person and shall include the principal Insured’s and/or', 72, textLine + 144)
    doc.text('Spouse’s family as defined hereunder:', 72, textLine + 149)
    doc.text('a) Great-grandparent who is your parents’ grandparent', 72, textLine + 154)
    doc.text('b) Great-grandparent-in-law who is the grandparent of your Spouse’s parents.', 72, textLine + 159)
    doc.text('c) Grandparent who is your parents’ parent', 72, textLine + 164)
    doc.text('d) Grandparent-in-law who is the parent of your Spouse’s parents.', 72, textLine + 169)
    doc.text('e) Parent who is your natural Father or Mother or your adoptive Father or Mother.', 72, textLine + 174)
    doc.text('f) Parent -in -law who is the parent of your Spouse or who is your Spouse’s', 72, textLine + 179)
    doc.text('natural Father or Mother or your Spouse’s adoptive Father or Mother.', 72, textLine + 184)
    doc.text('g) Aunt who is the sister of your Father or Mother, or your uncle’s female Spouse.', 72, textLine + 189)
    doc.text('h) Uncle who is the brother of your Father or Mother, or your aunt’s male Spouse.', 72, textLine + 194)
    doc.text('i) Brother who is your parents’ male Child.', 72, textLine + 199)
    doc.text('j) Sister who is your parents’ female Child.', 72, textLine + 204)
    doc.text(`k) Brother-in-law who is the male person married to you or your spouse's siblings.`, 72, textLine + 209)
    doc.text(`l) Sister-in-law who is the female person married to you or your spouse's siblings.`, 72, textLine + 214)
    doc.text('m) First cousin who is the Child of your aunt or uncle as defined above.', 72, textLine + 219)
    doc.text('n) Nephew who is the male Child of your brother or sister.', 72, textLine + 224)
    doc.text('o) Niece who is the female Child of your brother or sister.', 72, textLine + 229)
    doc.text('p) First cousin’s Child who is the Child of your first cousin.', 72, textLine + 234)
    doc.text('q) Grandchild who is your Child’s Child.', 72, textLine + 239)
    doc.text('r) Great - grandchild who is your grandchild’s Child.', 72, textLine + 244)
    doc.text('s) Former / ex-Spouse, where the bonds of marriage have been dissolved.', 72, textLine + 249)
    doc.text('t) Father of your Child', 72, textLine + 254)
    doc.text('u) Mother of your Child', 72, textLine + 259)

    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(10)
    doc.text('COMMUTER FUNERAL BENEFIT', 12, textLine + 270)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('Only the Policyholder is entitled to this Benefit.', 12, textLine + 275)

    doc.addPage()
    textLine = 10;
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(11)
    doc.text('WHO IS COVERED?', 5, textLine)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('The Principal Life Insured, Spouse, Dependants and Extended Family registered as such on the Insurer’s Policy Administration system ', 5, textLine + 5)
    doc.text('and noted in the Certificate of Cover in whose life the principal Insured has an insurable interest and has paid the premium.', 5, textLine + 10)
    doc.text('Any additional Spouse added after the Entry Date whose Entry Date shall remain the date on which such Spouse was registered on the', 5, textLine + 15)
    doc.text('Policy Administration system. In such an instance, eligibility shall be at the discretion of the Insurer.', 5, textLine + 20)
    doc.text('Any changes to the Insured lives must be done in writing.', 5, textLine + 25)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('CHANGES TO WHO IS COVERED', 5, textLine + 35)
    doc.setFontSize(10)
    doc.text('Spouse', 5, textLine + 50)
    doc.text('Children', 5, textLine + 80)
    doc.text('Extended Family', 5, textLine + 100)
    doc.text('Continuance', 5, textLine + 125)
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(9)
    doc.text('A Policyholder may add a Spouse, Children, and Extended Family to participate in the Benefits under this Policy under the following', 5, textLine + 40)
    doc.text('conditions:', 5, textLine + 45)
    doc.text('A Spouse may be added at any time. In instances of common-law or customary union, proof of such, to the satisfaction of the Insurer,', 5, textLine + 55)
    doc.text('must be provided by the Policyholder.', 5, textLine + 60)
    doc.text('The addition of a Spouse must be registered on the Insurer’s Policy Administration system. This Entry Date shall remain the date on', 5, textLine + 65)
    doc.text('which such Spouse was registered on the Policy Administration system. In such an instance, eligibility shall be at the discretion of', 5, textLine + 70)
    doc.text('the Insurer.', 5, textLine + 75)
    doc.text('The addition of a Child and/or Children must be registered on the Insurer’s Policy Administration system. Any additional Children', 5, textLine + 85)
    doc.text('added after the Entry Date, and this Entry Date shall remain the date on which such Child was registered on the Policy Administration', 5, textLine + 90)
    doc.text('system.In such an instance, eligibility shall be at the discretion of the Insurer.', 5, textLine + 95)
    doc.text('The addition of Extended Family member(s) must be registered on the Insurer’s Policy Administration system. Any additional Extended', 5, textLine + 105)
    doc.text('Family members added after the Entry Date, and this Entry Date shall remain the date on which such Extended Family was registered on', 5, textLine + 110)
    doc.text('the Policy Administration system. In such an instance, eligibility shall be at the discretion of the Insurer.', 5, textLine + 115)
    doc.text('Extended Family members may only be covered once under this Policy and may never exceed the funeral cover amount of the main member.', 5, textLine + 120)
    doc.text('If the Policyholder dies, the Spouse or any other person covered on the Policy may continue to pay the premiums if he / she was covered', 5, textLine + 130)
    doc.text('and is over the age of 18. The original cover of all persons will remain the same. No benefits changes to the policy are allowed thereafter.', 5, textLine + 135)
    doc.text('This option must be exercised within three (3) months of the date of death provided all arrears premiums are paid.', 5, textLine + 140)
    doc.text('Where a life Insured is covered for a Funeral Benefit on more than one Policy, in the event of a claim the maximum cover amount of', 5, textLine + 150)
    doc.text('R100 000 will be paid. If the cover amount exceeds R100 000, a pro-rata premium will be refunded.', 5, textLine + 155)
    doc.text('Any changes to the Insured lives must be done in writing.', 5, textLine + 160)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('WHO IS NOT COVERED?', 5, textLine + 170)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('1.  Any person who is not a South African Citizen or has permanent South African residency.', 5, textLine + 175)
    doc.text('2.  Any eligible dependent not listed in the Certificate of Cover.', 5, textLine + 180)
    doc.text('3.  Any eligible Extended Family member not listed in the Certificate of Cover.', 5, textLine + 185)
    doc.text('4.  More than one eligible Spouse per Insured.', 5, textLine + 190)
    doc.text('5.  A Child over the age of 21 years, unless extended to 26 years, who is not a full-time student registered with a recognised tertiary', 5, textLine + 195)
    doc.text('institution and does not remain financially dependent on the Policyholder.', 10, textLine + 200)
    doc.text('6.  A Child over the age of 26 years, who is not a full-time student registered with a recognised tertiary institution and does not remain', 5, textLine + 205)
    doc.text('financially dependent on the Policyholder.', 10, textLine + 210)
    doc.text('7.  A foster Child, unless placed in the Insured’ custody by a court.', 5, textLine + 215)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('BENEFICIARIES', 5, textLine + 225)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('The Beneficiary of the Policy benefit/s shall be a person you nominate to receive the Policy benefit/s and could include a natural person,', 5, textLine + 230)
    doc.text('or legal entity designated to receive the benefit or a trust fund or guardian fund, where Beneficiaries are under the age of 18 or lack', 5, textLine + 235)
    doc.text('the mental capacity to manage the lump sum payable under this Policy benefits.', 5, textLine + 240)
    doc.text('For purposes of this Policy, the Beneficiary shall be either the nominated Beneficiary stated in the Certificate of Cover in the event', 5, textLine + 245)
    doc.text('of the Death of the principal Insured, or the principal Insured in the event of the Death of any of the additional Insureds.', 5, textLine + 250)
    doc.text('The Beneficiary nominated on the application form, whether a person or legal entity or fund nominated by the Insured on the application', 5, textLine + 255)
    doc.text('form to receive the Benefits in the event of the Death of the Insured can;', 5, textLine + 260)
    doc.text('a)  be changed at any time by the main life Insured.', 10, textLine + 265)
    doc.text('b)  must have a valid South African Bank Account.', 10, textLine + 270)

    doc.addPage()
    textLine = 10
    doc.text('If there is no Beneficiary nominated or the Beneficiary is deceased and is not captured on the Policy administration system, the following', 5, textLine)
    doc.text('will apply on the payment of Policy benefits:', 5, textLine + 5)
    doc.text('a)  Payment to the Spouse or first Spouse with consent of any additional Spouses as per the definition of a Spouse.', 10, textLine + 10)
    doc.text('b)  Proof that the bonds of a common-law or customary union still exists must be supplied to the Insurer.', 10, textLine + 15)
    doc.text('c)  If unmarried, legally divorced, or widowed, to Policyholders’ own Child above 18 years of age or the eldest Child who is above 18', 10, textLine + 20)
    doc.text('years of age.', 15, textLine + 25)
    doc.text('d)  If no Children or no Children above the age of 18, to the parents of the Policyholder.', 10, textLine + 30)
    doc.text('e)  If no parents, to a family nominated Beneficiary.', 10, textLine + 35)
    doc.text('f)  If the family is in dispute, the benefit shall be paid to the undertaker if directed by the claimant to do so or to the person who proves', 10, textLine + 40)
    doc.text('that he or she has paid for the funeral, the residual of the benefit will be paid to the Policyholders’ estate.', 15, textLine + 45)
    doc.text('g)  If the nominated Beneficiary is the Spouse of the Principal Life Insured, whose marriage is later dissolved and the Insured dies', 10, textLine + 50)
    doc.text('with in three (3) months after the marriage was dissolved, the benefit payable will be in accordance with in three (3) months after', 15, textLine + 55)
    doc.text('the marriage was dissolved, the benefit payable will be in accordance with clause(c) above unless it appears the Policyholder had', 15, textLine + 60)
    doc.text('intended to benefit the Spouse despite the dissolution of their marriage.', 15, textLine + 65)
    doc.text('Should the Beneficiary be charged with involvement in the Deceased’s demise, the Benefit will be paid as if no Beneficiary was nominated.', 5, textLine + 70)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('PAYMENT OF PREMIUM', 5, textLine + 80)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('Premiums as indicated in the Certificate of Cover, are payable monthly in advance on or before the first day of each month.', 5, textLine + 85)
    doc.text('If premiums are not paid, you will be notified within fifteen (15) days after the payment was due. The period of grace allowed for the', 5, textLine + 90)
    doc.text('payment of premiums shall be sixty (60) days commencing on the first day of the month in which the premium is due.', 5, textLine + 95)
    doc.text('If the premiums are not paid within the period of grace, the Policy will lapse. In other words, your policy will lapse when 2 (two)', 5, textLine + 100)
    doc.text('consecutive premiums are unpaid.', 5, textLine + 105)
    doc.text('If a claim arises during the period of grace for the payment of premiums, cover remains in force for a period of 1 (one) month after the', 5, textLine + 110)
    doc.text('premium due date.', 5, textLine + 115)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('PREMIUM ESCALATION', 5, textLine + 125)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('The amount of premiums payable to secure the Benefits under this policy shall be calculated by the Insurer in accordance with the scale', 5, textLine + 130)
    doc.text('of premium rates in force under this policy at the date of calculation and will be based on information given to the Insurer by the', 5, textLine + 135)
    doc.text('Policyholder.', 5, textLine + 140)
    textLine += 5
    doc.text('The scale of premium rates has been agreed for a period of twelve (12) months from the Policy Inception Date and will be reviewed', 5, textLine + 140)
    doc.text('annually on the Review Date. This Policy operates according to actuarial rules, specifications, and formulae, which are approved', 5, textLine + 145)
    doc.text('according to law, and which are known as the actuarial basis of the Policy. These ensure that the Policy is financially sound and is', 5, textLine + 150)
    doc.text('carried out properly and fairly. Premiums will be reviewed due to possible changes in Policy Conditions and Expense Risks. We may', 5, textLine + 155)
    doc.text('adjust the premium by giving the Policyholder 31 days’ written notice.', 5, textLine + 160)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('COOLING-OFF PERIOD', 5, textLine + 170)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('You may want to cancel your Policy if you have just bought it and have changed your mind. You can cancel the Policy within the first', 5, textLine + 175)
    doc.text('31 days of inception if no claim has been submitted prior to such cancellation. All Premium payments made in respect of the Policy, ', 5, textLine + 180)
    doc.text('if so, cancelled within the first 31 days of inception, will be refunded in full by Us provided there has been no claim submitted and paid', 5, textLine + 185)
    doc.text('before receipt of Your cancellation notice.', 5, textLine + 190)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('CANCELLATION / TERMINATION', 5, textLine + 200)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('Your Policy will terminate under the following conditions:', 5, textLine + 205)

    doc.setFillColor('#FFEFED')
    doc.rect(10, textLine + 210, 180, 5, 'FD')
    doc.setFont('Helvetica', 'bold')
    doc.text('TYPE', 13, textLine + 213.5)
    doc.text('DESCRIPTION', 73, textLine + 213.5)

    doc.rect(10, textLine + 215, 180, 15, 'S')
    doc.rect(10, textLine + 215, 60, 15, 'S')
    doc.rect(10, textLine + 215, 60, 15, 'S')
    doc.rect(10, textLine + 230, 180, 10, 'S')
    doc.rect(10, textLine + 230, 60, 10, 'S')
    doc.rect(10, textLine + 240, 180, 10, 'S')
    doc.rect(10, textLine + 240, 60, 10, 'S')
    doc.rect(10, textLine + 250, 180, 5, 'S')
    doc.rect(10, textLine + 250, 60, 5, 'S')
    doc.rect(10, textLine + 255, 180, 5, 'S')
    doc.rect(10, textLine + 255, 60, 5, 'S')
    doc.rect(10, textLine + 260, 180, 10, 'S')
    doc.rect(10, textLine + 260, 60, 10, 'S')
    doc.rect(10, textLine + 270, 180, 10, 'S')
    doc.rect(10, textLine + 270, 60, 10, 'S')

    doc.setFont('Helvetica', 'bold')
    doc.text('Termination of Employment', 12, textLine + 219)
    doc.text('Cancellation of Membership of SA', 12, textLine + 234)
    doc.text('Army Foundation', 12, textLine + 239)
    doc.text('Written notice of termination by', 12, textLine + 244)
    doc.text('Policyholder', 12, textLine + 249)
    doc.text('Cancellation of Debit Order', 12, textLine + 254)
    doc.text('Principal Life Insured', 12, textLine + 259)
    doc.text('Policyholder Benefit Admittance', 12, textLine + 264)
    doc.text('Written notice of termination by', 12, textLine + 274)
    doc.text('the Insurer', 12, textLine + 279)

    doc.setFont('Helvetica', 'normal')
    doc.text('In an instance where the Policyholder terminates their employment with the SANDF', 72, textLine + 219)
    doc.text('and has not instructed the Insurer to deduct premiums via debit order within', 72, textLine + 224)
    doc.text('seven (7) months', 72, textLine + 229)
    doc.text('The cancellation of membership of the Principal Member from the South African', 72, textLine + 234)
    doc.text('Army Foundation.', 72, textLine + 239)
    doc.text('The Policy will be terminated in compliance with your request. This would include', 72, textLine + 244)
    doc.text('a request to terminate with immediate effect.', 72, textLine + 249)
    doc.text('If you fail to pay your premium by cancelling or stopping your debit order.', 72, textLine + 254)
    doc.text('The Death of the Principal Life Insured(subject to election of Continuance Option)', 72, textLine + 259)
    doc.text('A claim for any Benefit under the policy in respect of the Policyholder being', 72, textLine + 264)
    doc.text('admitted by the Insurer.', 72, textLine + 269)
    doc.text('The Insurer shall have the right to cancel the Policy by providing you with 31 days', 72, textLine + 274)
    doc.text('written notice of termination.', 72, textLine + 279)

    doc.addPage()
    textLine = 10;
    doc.rect(10, textLine, 180, 10, 'S')
    doc.rect(10, textLine, 60, 10, 'S')
    doc.rect(10, textLine, 60, 10, 'S')
    doc.setFont('Helvetica', 'bold')
    doc.text('Unpaid Premium', 12, textLine + 4)
    doc.setFont('Helvetica', 'normal')
    doc.text('If your monthly premiums are not paid for two months in a row(on the payment date', 72, textLine + 4)
    doc.text('or the resubmission date).', 72, textLine + 9)

    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('CLAIMS', 5, textLine + 20)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    textLine += -5
    doc.text('1.  The Insurer must be advised, in writing, of claims within thirty-six (36) months from the date of the Insured’s death or disability, failing', 5, textLine + 30)
    doc.text('which the claim will not be admitted, unless there are extenuating circumstances for the late advice thereof to the Insurer.', 10, textLine + 35)
    doc.text('2.  The Insurer must accept, suspend, or decline a claim for a Life Cover Benefit under a policy within five (5) business days after', 5, textLine + 40)
    doc.text('receipt of a claim. Any such decision must be communicated to the claimant in writing within ten(10) days of taking the decision as per', 10, textLine + 45)
    doc.text('the Claims Management Framework of the Insurer.', 10, textLine + 50)
    doc.text('3.  The Insurer will accept, suspend, or decline a claim for a Funeral Benefit under a Policy within two (2) business days after receipt of a', 5, textLine + 55)
    doc.text('claim. Any such decision will be communicated to the claimant in writing within ten(10) days of taking the decision as per the Claims', 10, textLine + 60)
    doc.text('Management Framework of the Insurer.', 10, textLine + 65)
    doc.text('4.  The following documentation shall be supplied to the Insurer by the Claimant for a Death Benefit:', 5, textLine + 70)
    doc.text('a)  Completed Death claim form signed by the Claimant.', 10, textLine + 75)
    doc.text('b)  Original or certified copy of a valid Death certificate.', 10, textLine + 80)
    doc.text('c)  Original or certified copy of the Insured’s identity document.', 10, textLine + 85)
    doc.text('d)  Such other documentation as the Insurer may reasonably require.', 10, textLine + 90)
    doc.text('5.  The following documentation shall be supplied to the Insurer by the Policyholder for a Disability Benefit:', 5, textLine + 95)
    doc.text('a)  Completed Employer’s Statement.', 10, textLine + 100)
    doc.text('b)  Completed Employee’s Statement.', 10, textLine + 105)
    doc.text('c)  Copies of the Insured’s identity document.', 10, textLine + 110)
    doc.text('d)  Supporting medical reports relevant to the disabling condition.', 10, textLine + 115)
    doc.text('e)  Military Disability a DD50 Form.', 10, textLine + 120)
    doc.text('f)  Such other documentation as the Insurer may reasonably require.', 10, textLine + 125)
    doc.text('6.  On the Total and Permanent Disability or Military Disability of an Insured:', 5, textLine + 130)
    doc.text('a)  A medical practitioner registered by the Health Professions Council shall medically examine the Insured.', 10, textLine + 135)
    doc.text('b)  Any such initial medical examination shall be at the Insured’s expense and subsequent medical examinations and clinical tests in', 10, textLine + 140)
    doc.text('support of the claim shall be at the expense of the Insurer.', 15, textLine + 145)
    doc.text('c)  Shield Life may appoint its own medical practitioner if required.', 10, textLine + 150)
    doc.text('d)  The Insurer may defer the assessment of the claim pending the production of such medical evidence.', 10, textLine + 155)
    doc.text('e)  Following admission of the claim by the Insurer, the Insurer shall pay to the Policyholder the Total and Permanent Disability Benefit', 10, textLine + 160)
    doc.text('as set out in the Schedule in respect of the Insured at the Date of Disability', 15, textLine + 165)
    doc.text('f)  This payment shall be made at the conclusion of the Waiting Period.', 10, textLine + 170)
    doc.text('g)  The Total and Permanent Disability Benefit is subject to any maximum that may be stated in the Schedule.', 10, textLine + 175)
    doc.text('h)  Payment of the Benefit will be dependent on the continued payment of premiums during the Waiting Period.', 10, textLine + 180)
    doc.text('support of the claim shall be at the expense of the Insurer.', 15, textLine + 185)
    doc.text('i)  The Policyholder may request the Insurer to waive the Waiting Period under exceptional circumstances. The waiving of the Waiting ', 10, textLine + 190)
    doc.text('Period is strictly at the discretion of the Insurer.', 15, textLine + 195)
    doc.text('j)  The Policyholder shall be entitled, if he is dissatisfied with the Insurer`s determination of the outcome of a medical assessment,', 10, textLine + 200)
    doc.text('to submit evidence and argument to support its contention that the determination should be reconsidered and revised.', 15, textLine + 205)
    doc.text('a)  If the Policyholder intends to proceed with a request to review the Insurer’s determination, evidence in support of their contention', 10, textLine + 210)
    doc.text('must be submitted within six (6) months of the Insurer’s advice of its decision to reject the disability Benefit.', 15, textLine + 215)
    doc.text('b)  The Policyholder shall meet the costs incurred by the Policyholder in bringing the determination under review.', 10, textLine + 220)
    doc.text('c)  The Insurer shall have the final discretion in deciding whether an Insured is Totally and Permanently Disabled and / or Military', 10, textLine + 225)
    doc.text('Disabled and shall also have final discretion in arriving at the Date of Disablement.The Insurer shall convey its determinations in', 15, textLine + 230)
    doc.text('this regard to the Policyholder in writing.', 15, textLine + 235)
    doc.text('7.  Any outstanding/arrears premiums on an Active Policy in a twelve (12) month period will be deducted from any benefit payable', 5, textLine + 240)
    doc.text('before payment is made.', 10, textLine + 245)
    doc.text('8.  The amount of a Benefit to be paid upon the admittance of the claim by the Insurer is stated in the Certificate of Cover.', 5, textLine + 250)
    doc.text('9.  The Death Benefit entitlement shall be reduced by any Benefit admitted and paid in terms of the Accelerated Total, Permanent', 5, textLine + 255)
    doc.text('Disability and Military Disability Benefit clause. This reduction shall apply for as long as the Insured remains eligible in terms of this', 10, textLine + 260)
    doc.text('policy’s terms and conditions applicable to the death Benefit.', 10, textLine + 265)
    doc.text('10.  Benefit payments will be made to the Policyholder or the Policyholder’s Nominated Beneficiary', 5, textLine + 270)

    doc.addPage()
    textLine = 10;
    doc.setFontSize(11)
    doc.setFont('Helvetica', 'bold')
    doc.text('GENERAL', 5, textLine)
    doc.setFontSize(9)
    doc.setFont('Helvetica', 'normal')
    doc.text('Amounts payable in terms of this policy, either to or by the Insurer, are payable in the lawful currency of the Republic of South Africa.', 5, textLine + 5)
    doc.text('Payment by the Insurer to the Principal Life Insured in respect of a Benefit shall be a full and final discharge of the Insurer`s obligations', 5, textLine + 10)
    doc.text('in terms of this policy for that Benefit. The Insurer`s liability in this regard will not exceed the Benefit for which the Policyholder has paid', 5, textLine + 15)
    doc.text('premiums to the Insurer.', 5, textLine + 20)
    doc.text('Any question of law arising under this policy shall be decided according to the laws of the Republic of South Africa.', 5, textLine + 25)
    doc.text('This Policy has no surrender, loan, or paid-up values. There is no cumulative effect of premiums paid and each monthly premium is used', 5, textLine + 30)
    doc.text('to cover the risk for that specific month. Each month a premium is to be paid to renew the cover.', 5, textLine + 35)
    doc.text('A Policyholder may not cede, pledge, or otherwise alienate the benefits or the rights to benefits in terms of this Policy and such Benefits', 5, textLine + 40)
    doc.text('shall not be subject to any form of execution or judgment and shall not, on insolvency, form part of the estate of any Insured.', 5, textLine + 45)

    // to download pdf
    if (type === 'download') {
      doc.save('Membership_Application.pdf')
      // view pdf
    } else if (type === 'view') {
      window.open(doc.output('bloburl'))
    } else if (type === 'save') {
      var output = doc.output('datauristring')
      setBase64Data(output)
    }
  }

  const postToApi = async (data1) => {
    var config = {
      method: 'post',
      url: 'https://apiv2.msgl.ink/',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        agentCode,
        forceNumber,
        armsOfService,
        rankTitle,
        fullNames,
        surname,
        IDNumber,
        mainID,
        mainAge,
        cellNumber,
        altCellNumber,
        landlineNumber,
        emailAddress,
        streetNumber,
        streetName,
        complexName,
        unitNumber,
        suburb,
        town,
        postalCode,
        unitName,
        unitSwitchBoardNumber,
        maritalStatus,
        monthlyPremium,
        spouseTitle,
        spouseFullNames,
        spouseSurname,
        spouseID,
        spouseIdFile,
        childrenAmount,
        childTitle,
        childFullNames,
        childSurname,
        childID,
        childTitle2,
        childFullNames2,
        childSurname2,
        childID2,
        childTitle3,
        childFullNames3,
        childSurname3,
        childID3,
        childTitle4,
        childFullNames4,
        childSurname4,
        childID4,
        childTitle5,
        childFullNames5,
        childSurname5,
        childID5,
        childTitle6,
        childFullNames6,
        childSurname6,
        childID6,
        childrenIds,
        pbTitle,
        pbFullNames,
        pbSurname,
        pbID,
        pbCellNumber,
        pbIdData,
        setPbIdData,
        nlTitle,
        nlFullNames,
        nlSurname,
        nlIDNumber,
        nlCellNumber,
        nlIdData,
        setNlIdData,
        paymentMethod,
        base64Data,
        bankingDetailsDO,
        debitOrderDate,
        idParams,
        pbLandline,
        pbRelationship,
        nlLandline,
        nlRelationship,
        sourceIncome,
        sourceIncomeDetails,
        spouseAge,
        childAge,
        childAge2,
        childAge3,
        childAge4,
        childAge5,
        childAge6,
        pbAge,
        nlAge,
        spouseCell,
        marketingConsent,
        agsb,
        agsbwide,
        rhcb,
        cgsb,
        asb,
        other,
        otherDetails,
        memberRelation,
        relationship,
        name,
        relationSurname,
        title,
        dateOfBirth,
        age,
        benefit,
        premium,
        memberRelation2,
        relationship2,
        name2,
        relationSurname2,
        title2,
        dateOfBirth2,
        age2,
        benefit2,
        premium2,
        memberRelation3,
        relationship3,
        name3,
        relationSurname3,
        title3,
        dateOfBirth3,
        age3,
        benefit3,
        premium3,
        memberRelation4,
        relationship4,
        name4,
        relationSurname4,
        title4,
        dateOfBirth4,
        age4,
        benefit4,
        premium4,
        memberRelation5,
        relationship5,
        name5,
        relationSurname5,
        title5,
        dateOfBirth5,
        age5,
        benefit5,
        premium5,
        memberRelation6,
        relationship6,
        name6,
        relationSurname6,
        title6,
        dateOfBirth6,
        age6,
        benefit6,
        premium6,
        memberRelation7,
        relationship7,
        name7,
        relationSurname7,
        title7,
        dateOfBirth7,
        age7,
        benefit7,
        premium7,
        memberRelation8,
        relationship8,
        name8,
        relationSurname8,
        title8,
        dateOfBirth8,
        age8,
        benefit8,
        premium8,
        extendedMonthlyPremium,
        extendedPaymentMethod,
        otherExtendedText,
        extendedBankingDetailsDO,
        dpip,
        dpipDetails,
        fppo,
        fppoDetails,
        pip,
        pipDetails,
        closeDpipFppo,
        closeDpipFppoDetails,
        rhcbwide,
        cgsbwide,
        asbwide,
        otherwide,
        otherDetailswide,
        confirmsignYes,
        SourceSite: "shieldapp.co.za"
      }
    };

    await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // if approved send state to backend
  if (approved) {
    postToApi(data)
  }

  useEffect(() => {
    if (maritalStatus == 'Married') {
      setMemberType('Married')
      if (childrenAmount > 0) {
        setMemberType('Married + Children')
      }
    }
    else if (childrenAmount > 0) {
      setMemberType('Single + Children')
    }
    if (base64Data == '') {
      pdfPayrollDeductionScript('save')
    }
    if (base64Data != '') {
      if (data.length == 0) {
        setData({
          agentCode,
          forceNumber,
          armsOfService,
          rankTitle,
          fullNames,
          surname,
          IDNumber,
          mainID,
          mainAge,
          cellNumber,
          altCellNumber,
          landlineNumber,
          emailAddress,
          streetNumber,
          streetName,
          complexName,
          unitNumber,
          suburb,
          town,
          postalCode,
          unitName,
          unitSwitchBoardNumber,
          maritalStatus,
          monthlyPremium,
          spouseTitle,
          spouseFullNames,
          spouseSurname,
          spouseID,
          spouseIdFile,
          childrenAmount,
          childTitle,
          childFullNames,
          childSurname,
          childID,
          childTitle2,
          childFullNames2,
          childSurname2,
          childID2,
          childTitle3,
          childFullNames3,
          childSurname3,
          childID3,
          childTitle4,
          childFullNames4,
          childSurname4,
          childID4,
          childTitle5,
          childFullNames5,
          childSurname5,
          childID5,
          childTitle6,
          childFullNames6,
          childSurname6,
          childID6,
          childrenIds,
          pbTitle,
          pbFullNames,
          pbSurname,
          pbID,
          pbCellNumber,
          pbIdData,
          setPbIdData,
          nlTitle,
          nlFullNames,
          nlSurname,
          nlIDNumber,
          nlCellNumber,
          nlIdData,
          setNlIdData,
          paymentMethod,
          base64Data,
          bankingDetailsDO,
          debitOrderDate,
          idParams,
          pbLandline,
          pbRelationship,
          nlLandline,
          nlRelationship,
          sourceIncome,
          sourceIncomeDetails,
          spouseAge,
          childAge,
          childAge2,
          childAge3,
          childAge4,
          childAge5,
          childAge6,
          pbAge,
          nlAge,
          spouseCell,
          marketingConsent,
          agsb,
          agsbwide,
          rhcb,
          cgsb,
          asb,
          other,
          otherDetails,
          memberRelation,
          relationship,
          name,
          relationSurname,
          title,
          dateOfBirth,
          age,
          benefit,
          premium,
          memberRelation2,
          relationship2,
          name2,
          relationSurname2,
          title2,
          dateOfBirth2,
          age2,
          benefit2,
          premium2,
          memberRelation3,
          relationship3,
          name3,
          relationSurname3,
          title3,
          dateOfBirth3,
          age3,
          benefit3,
          premium3,
          memberRelation4,
          relationship4,
          name4,
          relationSurname4,
          title4,
          dateOfBirth4,
          age4,
          benefit4,
          premium4,
          memberRelation5,
          relationship5,
          name5,
          relationSurname5,
          title5,
          dateOfBirth5,
          age5,
          benefit5,
          premium5,
          memberRelation6,
          relationship6,
          name6,
          relationSurname6,
          title6,
          dateOfBirth6,
          age6,
          benefit6,
          premium6,
          memberRelation7,
          relationship7,
          name7,
          relationSurname7,
          title7,
          dateOfBirth7,
          age7,
          benefit7,
          premium7,
          memberRelation8,
          relationship8,
          name8,
          relationSurname8,
          title8,
          dateOfBirth8,
          age8,
          benefit8,
          premium8,
          extendedMonthlyPremium,
          extendedPaymentMethod,
          otherExtendedText,
          extendedBankingDetailsDO,
          dpip,
          dpipDetails,
          fppo,
          fppoDetails,
          pip,
          pipDetails,
          closeDpipFppo,
          closeDpipFppoDetails,
          rhcbwide,
          cgsbwide,
          asbwide,
          otherwide,
          otherDetailswide,
          confirmsignYes,
          SourceSite: "shieldapp.co.za"
        })
      }
    }
  }, [memberType,
    setMemberType,
    maritalStatus,
    base64Data,
    data,
    setData,
  ])
  return (
    <div className="page">
      {approved && <div className='page'>
        <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>Thank you very much. We will be in contact shortly</h6>

        <div style={{ textAlign: 'center' }}>
          <p>While you wait you are welcome to download the agreements</p>
          <Button variant='danger w-50' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none' }} onClick={(e) => { pdfPayrollDeductionScript('download') }}>
            Download Agreement
          </Button>
        </div>
      </div>}
      {!approved && <div style={{ overflow: 'scroll', height: 600 }}>
        <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>You can download your application now by clicking the button below</h6>
        <p>Please note that your application is not completed until you have clicked the "I Accept the Terms of this Agreement" button</p>
        {/* <Button variant='danger w-50' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none' }} onClick={(e) => pdfPayrollDeductionScript('view')}>View Pdf and Terms and Conditions</Button> */}
        <Button variant='danger w-75' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', marginTop: "5vh" }} onClick={(e) => setApproved(true)}>I Accept the Terms and Download of this Agreement</Button>
      </div>}
    </div>
  )
}
export default Document1
